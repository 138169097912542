export const debounce = <T extends (...args: any[]) => any>(
  func: T,
  wait: number
): ((...funcArgs: Parameters<T>) => void) => {
  //@ts-ignore
  let timeout: NodeJS.Timeout | null;

  return function (...args: Parameters<T>) {
    const fnCall = () => {
      //@ts-ignore
      func.apply(this, args);
    };

    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(fnCall, wait);
  };
};
