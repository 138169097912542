<template>
  <div class="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
    <h1 class="text-3xl font-medium text-black">{{ t('vacancies') }}</h1>
    <div class="flex flex-col items-end justify-end mt-8">
      <Button size="md" variant="primary">
        <RouterLink :to="{ name: 'add-new-vacancy' }">
          {{ t('global.addVacancy') }}
        </RouterLink>
      </Button>
    </div>
    <div class="h-32 flex justify-center items-center bg-white rounded-2xl mt-2" v-if="loading">
      <Spinner />
    </div>
    <div v-else-if="vacancies && vacancies.length" class="grid grid-cols-1 gap-2 mt-2 pb-8">
      <div
        v-for="vacancy in vacancies"
        :key="vacancy.id"
        class="flex items-center justify-between gap-16 p-8 border border-grey-50 bg-white rounded-lg"
      >
        <div>
          <p class="text-lg font-medium text-[#202124]">
            {{ vacancy[`position${localePostfix}`] }}
          </p>

          <p v-if="vacancy.address" class="text-sm mt-2 font-normal text-grey-200 flex gap-2 items-center">
            <SvgIcon name="location" /> {{ vacancy.address }}
          </p>
        </div>
        <p class="text-grey-200 mt-2">
          {{ readableDate(vacancy.createdAt, locale) + ' - ' + readableDate(vacancy.durationDate, locale) }}
        </p>
        <p class="font-normal" :class="vacancy.active === true ? 'text-green' : 'text-red'">
          {{ vacancy.active === true ? t('globalTs.active') : t('globalTs.closed') }}
        </p>
        <div class="flex gap-2">
          <RouterLink :to="{ name: 'vacancy-detail', params: { id: vacancy.id } }">
            <button class="p-2 text-xl rounded-lg bg-[#1967D2] bg-opacity-10">
              <SvgIcon name="eye" class="text-[#1967D2]" />
            </button>
          </RouterLink>
          <RouterLink :to="{ name: 'vacancy-edit', params: { id: vacancy.id } }">
            <button class="p-[10px] text-xl rounded-lg bg-[#1967D2] bg-opacity-10">
              <SvgIcon name="pen" class="text-[#1967D2]" />
            </button>
          </RouterLink>
          <button class="p-2 rounded-lg bg-[#1967D2] bg-opacity-10" @click="removeVacancyHandler(vacancy.id)">
            <SvgIcon name="blue-trash" class="text-[#1967D2]" />
          </button>
        </div>
      </div>
    </div>
    <div
      v-else
      class="text-grey-200 mt-2 flex items-center justify-center gap-16 p-8 border border-[#ECEDF2] bg-white rounded-lg"
    >
      {{ t('noData') }}
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, computed } from 'vue';
  import SvgIcon from '~/components/ui/SvgIcon.vue';
  import { getVacanciesRequest, removeVacancyRequest } from '~/services/vacancy';
  import { useCoreStore } from '~/stores/core';
  import { type IVacancy } from '~/types/vacancy';
  import { toast } from 'vue3-toastify';
  import { readableDate } from '@/utils/filters';
  import Spinner from '@/components/ui/Spinner.vue';
  import { useI18n } from 'vue-i18n';
  import Button from '@/components/ui/Button.vue';
  const { t, locale } = useI18n();
  const coreStore = useCoreStore();

  const localePostfix = computed(() => {
    return locale.value === 'ru' ? 'Ru' : locale.value === 'en' ? 'En' : 'Tj';
  });

  const loading = ref(true);
  const vacancies = ref<IVacancy[]>([]);

  setTimeout(() => {
    getVacancies();
  }, 500);

  async function getVacancies() {
    loading.value = true;
    const res = await getVacanciesRequest(coreStore.profile?.profileId as string);
    vacancies.value = res.payload.items;
    loading.value = false;
  }

  async function removeVacancyHandler(vacancyId: string) {
    loading.value = true;
    toast.success('Успешно удалено');
    await removeVacancyRequest(vacancyId);
    getVacancies();
  }
</script>
