<template>
  <div>
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="lg:flex justify-between">
        <div class="my-10 md:my-[120px]">
          <div class="flex space-x-3 whitespace-nowrap">
            <h1 class="text-2xl lg:text-[50px] font-medium whitespace-nowrap">
              {{ t('startHome.header') }}
            </h1>
            <h1 class="text-2xl lg:text-[50px] font-medium text-primary-500">KOR.TJ</h1>
          </div>
          <p class="text-grey-500 md:text-xl mt-2 max-w-xl">
            {{ t('startHome.subHeader') }}
          </p>
          <div>
            <div
              class="rounded-full bg-white h-[60px] md:h-[80px] mb-4 pl-4 md:pl-7 pr-3 py-3 flex items-center mt-[25px] md:mt-[45px]"
            >
              <SvgIcon class="text-grey-200" name="search" />
              <input
                :placeholder="t('startHome.buttonFindPlaceholder')"
                class="border-none focus:outline-none h-full w-full ml-2"
                v-model="searchText"
              />
              <router-link :to="{ name: 'search-vacancies', query: { searchText: searchText } }">
                <button class="h-10 md:h-[60px] rounded-full bg-primary-500 text-white w-[100px] md:w-[200px]">
                  {{ t('startHome.buttonFind') }}
                </button>
              </router-link>
            </div>
          </div>
        </div>
        <div class="relative hidden items-end lg:flex">
          <img class="max-h-[440px]" src="@/assets/images/home-block-images/girl-background.png" alt="" />
          <img
            class="z-10 absolute bottom-0 max-h-[500px] right-20"
            src="@/assets/images/home-block-images/girl.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="bg-white">
      <div class="mx-auto max-w-7xl py-10 px-4 sm:px-6 lg:px-8">
        <h2 class="text-3xl text-center mt-[100px]">{{ t('startHome.newOffers') }}</h2>
        <RecommendedVacanciesSection isStartPage />
      </div>
    </div>
    <div>
      <LagestEmployersSection isStartPage />
    </div>
    <div>
      <DownloadAppSection />
    </div>
    <TypicalTemplates isStartPage />
  </div>
</template>
<script setup lang="ts">
  import SvgIcon from '@/components/ui/SvgIcon.vue';
  import { ref } from 'vue';
  import RecommendedVacanciesSection from '@/components/home/RecommendedVacanciesSection.vue';
  import LagestEmployersSection from '@/components/home/LagestEmployersSection.vue';
  import DownloadAppSection from '@/components/home/DownloadAppSection.vue';
  import Input from '@/components/ui/Input.vue';
  import Button from '@/components/ui/Button.vue';
  import { useI18n } from 'vue-i18n';
  import TypicalTemplates from '@/components/home-company/TypicalTemplates.vue';

  const searchText = ref('');

  const { t } = useI18n();
</script>
