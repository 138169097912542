import { apiClient } from '@/services/apiClient';

export const changePassword = (id: string, params: any) => apiClient.post(`auth/users/${id}/change-password`, params);
export const loginApplicant = (params: any) => apiClient.post('auth/applicants/login', params);
export const loginEmployer = (params: any) => apiClient.post('auth/employers/login', params);
export const getUserInfo = () => apiClient.get('auth/me');
export const getCompanyInfo = () => apiClient.get('auth/employers');
export const registrationApplicant = (params: any) => apiClient.post('auth/applicants/regis', params);
export const verifyApplicant = (params: any) => apiClient.post('auth/applicants/verify', params);

export const registrationEmployer = (params: any) => apiClient.post('auth/employers/regis', params);
export const verifyEmployer = (params: any) => apiClient.post('auth/employers/verify', params);
export const createApplicantUser = (id: string, params: any) => apiClient.post(`profiles/users/${id}`, params);
export const updateApplicantUser = (id: string, params: any) => apiClient.put(`profiles/user-profiles/${id}`, params);
export const createEmployerUser = (id: string, params: any) => apiClient.post(`companies/users/${id}`, params);

export const forgotPassword = (params: any) => apiClient.post('auth/forgot-password', params);
export const employerForgotPassword = (params: any) => apiClient.post('auth/company-forgot-password', params);

export const sendOtp = (params: any) => apiClient.post('auth/send-otp', params);
export const sendEmailOtp = (params: any) => apiClient.post('auth/send-email-otp', params);
export const getApps = () => apiClient.get('apps');
