<template>
  <section class="bg-white px-4 md:px-8 py-10 mt-8 rounded">
    <p>{{ t('socialLinks') }}</p>
    <div class="flex flex-col md:flex-row justify-between gap-8 mt-8">
      <Input class="w-full" v-model="user.telegramm" placeholder="@chiki" label="Telegram" />
      <Input class="w-full" v-model="user.whatsAPP" label="whatsApp" placeholder="whatsApp" />
    </div>
    <div class="flex flex-col md:flex-row justify-between gap-8 mt-8">
      <Input class="w-full" v-model="user.linkedin" label="linkedin" placeholder="linkedin" />
      <Input class="w-full" v-model="user.facebook" label="facebook" placeholder="facebook" />
    </div>
    <div class="mt-8 flex justify-start">
      <Button :loading="loading" :disabled="loading" @click="saveData" variant="primary">{{ t('save') }}</Button>
    </div>
  </section>
</template>
<script setup lang="ts">
  import { onMounted, reactive, ref } from 'vue';
  import Input from '@/components/ui/Input.vue';
  import Button from '@/components/ui/Button.vue';
  import { createCompanySocialNetworks, updateCompanySocialNetworks } from '@/services/company';
  import { toast } from 'vue3-toastify';
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n();

  const props = defineProps<{ companyId: string; data: object | null }>();

  const isSubmitted = ref(false);
  const loading = ref(false);
  const user = reactive({
    companyId: '',
    telegramm: '',
    whatsAPP: '',
    linkedin: '',
    facebook: '',
  });

  onMounted(() => {
    const dataCopy = JSON.parse(JSON.stringify(props.data));
    user.telegramm = dataCopy?.telegramm || '';
    user.whatsAPP = dataCopy?.whatsAPP || '';
    user.linkedin = dataCopy?.linkedin || '';
    user.facebook = dataCopy?.facebook || '';
  });

  const saveData = () => {
    isSubmitted.value = true;

    loading.value = true;
    //@ts-ignore
    if (props.data) {
      updateCompanySocialNetworks(props.companyId, user)
        .then(() => {
          toast.success(t('dataSuccessfullyUpdated'));
        })
        .finally(() => {
          isSubmitted.value = false;
          loading.value = false;
        });
    } else {
      createCompanySocialNetworks(props.companyId, user)
        .then(() => {
          toast.success(t('dataSuccessfullySaved'));
        })
        .finally(() => {
          isSubmitted.value = false;
          loading.value = false;
        });
    }
  };
</script>

<style scoped></style>
