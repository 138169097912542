import { apiClient } from '@/services/apiClient';

export const createApplicantProfile = (id: string, params: any) =>
  apiClient.post(`applicants/users-profile/${id}`, params);
export const updateApplicantProfile = (id: string, params: any) => apiClient.put(`applicants/${id}`, params);

export const getApplicantByProfileId = (id: string) => apiClient.get(`/applicants/users-profile/${id}`);
export const getApplicantById = (id: any) => apiClient.get(`client/applicants/${id}`);

export const createApplicantSocialNetworks = (id: string, params: any) =>
  apiClient.post(`applicant-social-networks/applicants/${id}`, params);

export const updateApplicantSocialNetworks = (id: string, params: any) =>
  apiClient.put(`applicant-social-networks/${id}`, params);

export const createApplicantContactInformation = (id: string, params: any) =>
  apiClient.post(`applicant-contact-informations/${id}`, params);
export const updateApplicantContactInformation = (id: string, params: any) =>
  apiClient.put(`applicant-contact-informations/${id}`, params);

export const createEducationRequest = async (id: string, params: any) => {
  const { data } = await apiClient.post(`applicant-educations/applicants/${id}`, params);
  return data;
};

export const editEducationRequest = async (id: string, params: any) => {
  const { data } = await apiClient.put(`applicant-educations/${id}`, params);
  return data;
};

export const deleteEducationRequest = async (id: string) => {
  const { data } = await apiClient.delete(`applicant-educations/${id}`);
  return data;
};

export const createWorkExperienceRequest = async (id: string, params: any) => {
  const { data } = await apiClient.post(`applicant-work-experiences/applicants/${id}`, params);
  return data;
};

export const deleteWorkExperienceRequest = async (id: string) => {
  const { data } = await apiClient.delete(`applicant-work-experiences/${id}`);
  return data;
};

export const editWorkExperienceRequest = async (id: string, params: any) => {
  const { data } = await apiClient.put(`applicant-work-experiences/${id}`, params);
  return data;
};

export const createPortfolioRequest = async (id: string, params: any) => {
  const { data } = await apiClient.post(`applicant-portfolios/applicants/${id}`, params);
  return data;
};

export const editPortfolioRequest = async (id: string, params: any) => {
  const { data } = await apiClient.put(`applicant-portfolios/${id}`, params);
  return data;
};

export const deletePortfolioRequest = async (id: string) => {
  const { data } = await apiClient.delete(`applicant-portfolios/${id}`);
  return data;
};

export const createAwardRequest = async (id: string, params: any) => {
  const { data } = await apiClient.post(`applicant-awards/applicants/${id}`, params);
  return data;
};
export const editAwardRequest = async (id: string, params: any) => {
  const { data } = await apiClient.put(`applicant-awards/${id}`, params);
  return data;
};

export const deleteAwardRequest = async (id: string) => {
  const { data } = await apiClient.delete(`applicant-awards/${id}`);
  return data;
};

export const createCVManagerRequest = async (id: string, params: any) => {
  const { data } = await apiClient.post(`cv-managers/applicants/${id}`, params);
  return data;
};

export const getCVManagersRequest = async (id: string) => {
  const { data } = await apiClient.get(`cv-managers/applicants/${id}`);
  return data;
};

export const deleteCVManagerRequest = async (id: string) => {
  const { data } = await apiClient.delete(`cv-managers/${id}`);
  return data;
};

export const getSkillsRequest = async () => {
  const { data } = await apiClient.get(`professional-skills`);
  return data;
};

export const createSkillRequest = async (id: string, params: any) => {
  const { data } = await apiClient.post(`applicant-skills/applicants/${id}`, params);
  return data;
};

export const deleteSkillRequest = async (id: string, skillId: string) => {
  const { data } = await apiClient.delete(`applicant-skills/${skillId}/applicants/${id}`);
  return data;
};

export const getAppliedVacanciesRequest = async (id: string) => {
  const { data } = await apiClient.get(`applied-vacancies/applicants/${id}`);
  return data;
};

export const deleteAppliedVacanciesRequest = async (id: string) => {
  const { data } = await apiClient.delete(`applied-vacancies/${id}`);
  return data;
};

export const getApplicantStatistics = async (id: string) => await apiClient.get(`/statistics/applicants/${id}`);
export const approveApplicationRequest = (vacancyId: string, applicantId: string) =>
  apiClient.post(`/approve/applied-vacancies/${vacancyId}/${applicantId}`);
export const rejectApplicationRequest = (vacancyId: string, applicantId: string) =>
  apiClient.post(`/reject/applied-vacancies/${vacancyId}/${applicantId}`);
export const deleteApplicationRequest = (vacancyId: string, applicantId: string) =>
  apiClient.delete(`/applied-vacancies/${vacancyId}/${applicantId}`);
export const getApplicants = async (params: { query: string; Page: number; Size: number; Region: string }) =>
  await apiClient.get(`/client/applicants`, { params });

export const exportResumeRequest = async (id: string) => {
  const { data } = await apiClient.get(`pdf/applicant-resume/${id}`, {
    responseType: 'blob',
  });
  return data;
};
