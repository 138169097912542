import { apiClient } from '@/services/apiClient';
import type { TPartner, TResponsePayload, TStatistics } from '@/types/global';

type TCallParams = {
  companyName: string;
  phone: string;
  email: string;
};

export const getHomeStatistics = () => apiClient.get<TStatistics>('desktop/statistics');
export const getPartnersRequest = () =>
  apiClient.get<TResponsePayload<TPartner>>('partners', { params: { size: 100 } });
export const toBookCall = (params: TCallParams) => {
  return apiClient.post('company-call-request', params);
};

export const setSearchData = (params: { searchText: string; link: string; result: string }) => {
  return apiClient.post('search-history', params);
};
