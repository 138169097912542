<template>
  <div class="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
    <h1 class="text-3xl font-medium text-black">{{ t('vacancyPage.editVacancy') }}</h1>
    <div class="p-10 mx-auto shadow-sm rounded-lg max-w-[1440px] bg-white mt-16 mb-6">
      <div v-if="loading" class="flex justify-center w-full">
        <Spinner />
      </div>
      <div v-else>
        <div class="mt-8 flex flex-col md:flex-row">
          <Button
            class="rounded-b-none md:rounded-lg md:rounded-r-none border border-primary-600"
            variant="primaryOutline"
            :isActive="currentLocale === 'Ru'"
            @click="currentLocale = 'Ru'"
          >
            {{ t('russian') }}
          </Button>
          <Button
            class="!rounded-none border border-primary-600"
            variant="primaryOutline"
            :isActive="currentLocale === 'Tj'"
            @click="currentLocale = 'Tj'"
          >
            {{ t('tajik') }}
          </Button>
          <Button
            class="rounded-t-none md:rounded-lg md:rounded-l-none border border-primary-600"
            variant="primaryOutline"
            :isActive="currentLocale === 'En'"
            @click="currentLocale = 'En'"
          >
            {{ t('english') }}
          </Button>
        </div>
        <p class="text-xs font-normal text-[#7B7B7B] mt-2.5">{{ t('vacancyPage.fillOnOtherLanguages') }}</p>
        <div class="mt-10">
          <Input
            v-model="vacancy[`position${currentLocale}`]"
            :label="t('position')"
            :placeholder="t('vacancyPage.enterOnOtherLanguages')"
          />
        </div>
        <div class="mt-10">
          <Textarea
            v-model="vacancy[`description${currentLocale}`]"
            :label="t('vacanciesPage.vacancyDescription')"
            :placeholder="t('vacancyPage.enterVacancyDescription')"
          />
        </div>
        <div class="mt-10">
          <Textarea
            v-model="vacancy[`requirements${currentLocale}`]"
            :label="t('vacancyPage.requirements')"
            :placeholder="t('vacancyPage.enterVacancyRequirements')"
          />
        </div>
        <div class="mt-10">
          <Textarea
            v-model="vacancy[`commitment${currentLocale}`]"
            :label="t('vacancyPage.commitment')"
            :placeholder="t('vacancyPage.enterTheCommitmentToVacancy')"
          />
        </div>
        <div class="flex flex-wrap md:flex-nowrap mt-6 gap-6">
          <div class="w-full">
            <div class="flex items-end flex-wrap md:flex-nowrap gap-7 w-full">
              <Input
                v-model="vacancy.ageFrom"
                :label="t('age')"
                :placeholder="`${t('age')} ${t('from')}`"
                class="w-full"
              />
              <Input v-model="vacancy.ageTo" :placeholder="`${t('age')} ${t('before')}`" class="w-full" />
            </div>
            <Select
              v-model="specializationCategory"
              :items="specializationCategories"
              :label="t('vacancyPage.categoriesOfSpecializations')"
              class="w-full mt-7"
              :placeholder="t('choose')"
            />
            <div class="mt-7 flex flex-wrap md:flex-nowrap gap-6 items-end">
              <Input v-model="vacancy.salaryCurrency" :label="t('vacancyPage.offeredSalary')" />
              <Checkbox v-model="vacancy.contractual" :label="t('vacancyPage.byContract')" class="mb-6" />
            </div>
            <Select
              v-model="vacancy.workExperience"
              :items="[
                { title: `${t('withoutExperience')}`, value: '0' },
                { title: '1-3', value: '1-3' },
                { title: '3-6', value: '3-6' },
                { title: '6+', value: '6+' },
              ]"
              :label="t('workExperience')"
              class="w-full mt-7"
              :placeholder="t('vacancyPage.selectTheRequiredExperience')"
              :isError="isSubmit && v$.workExperience?.$invalid"
              :errorText="t('requiredField')"
            />
            <Select
              :error-text="t('requiredField')"
              :is-error="isSubmit && v$.regionId?.$invalid"
              :items="regions"
              class="w-full mt-9"
              v-model="vacancy.regionId"
              :label="t('regionCity')"
              :placeholder="t('regionCityPlaceholder')"
              @update:model-value="getCitiesHandler"
            />
            <div class="mt-8">
              <label class="block text-[15px] text-black font-normal mb-1.5"> Дата от </label>
              <DatePicker
                auto-apply
                :placeholder="t('vacancyPage.closingDate')"
                @change="
                  () => {
                    //@ts-ignore
                    filters.from = filters.from.toISOString();
                  }
                "
                :select-text="t('select')"
                :modelValue="vacancy.durationDate"
                @update:modelValue="($event: any) => (vacancy.durationDate = $event?.toISOString())"
                :label="t('vacancyPage.closingDate')"
              />
            </div>
            <div class="mt-11">
              <Checkbox v-model="vacancy.workForDisabled" :label="t('vacancyPage.employmentOfDisabledPersons')" />
              <Checkbox v-model="vacancy.active" :label="t('active')" />
            </div>
            <div class="mt-10">
              <Input
                v-model="vacancy.address"
                :label="t('applicantPage.fullAddress')"
                placeholder="329 Queensberry Street, North Melbourne VIC 3051, Australia."
              />
            </div>
          </div>
          <div class="w-full">
            <div class="flex items-end gap-7 w-full">
              <Select
                v-model="vacancy.gender"
                :items="[
                  { title: `${t('male')}`, value: 1 },
                  { title: `${t('female')}`, value: 2 },
                ]"
                class="w-full"
                :isError="isSubmit && v$.gender?.$invalid"
                :label="t('gender')"
                :errorText="t('requiredField')"
                :placeholder="t('choose')"
              />
            </div>
            <Multiselect
              v-model="vacancy.specializationIds"
              :items="specializations"
              class="w-full mt-7"
              :isError="isSubmit && v$.specializationIds?.$invalid"
              :label="t('vacanciesPage.specializations')"
              :errorText="t('requiredField')"
            />
            <Multiselect
              v-model="vacancy.educationIds"
              :items="educations"
              class="w-full mt-7"
              :isError="isSubmit && v$.educationIds.$invalid"
              :label="t('education')"
              :errorText="t('requiredField')"
            />
            <Select
              v-model="vacancy.EmploymentTypeId"
              :items="employmentTypes"
              class="w-full mt-[33px]"
              :isError="isSubmit && v$.EmploymentTypeId?.$invalid"
              :label="t('vacanciesPage.employmentType')"
              :errorText="t('requiredField')"
              :placeholder="t('choose')"
            />
            <Select
              :disabled="!vacancy.regionId"
              class="w-full mt-9"
              :error-text="t('requiredField')"
              :is-error="isSubmit && v$.cityId?.$invalid"
              :items="cities"
              v-model="vacancy.cityId"
              :label="t('regionCity')"
              :placeholder="t('regionCityPlaceholder')"
            />
            <Multiselect
              v-model="vacancy.workScheduleIds"
              :items="workSchedules"
              :label="t('vacanciesPage.workSchedule')"
              :placeholder="t('vacancyPage.selectWorkSchedule')"
              class="w-full mt-9"
              :isError="isSubmit && v$.workScheduleIds?.$invalid"
              :errorText="t('requiredField')"
            />
            <div class="mt-7">
              <Input
                v-model="vacancy.vacancyCount"
                :label="t('vacancyPage.vacancyCount')"
                :placeholder="t('vacancyPage.enterVacancyCount')"
              />
            </div>
          </div>
        </div>
        <div>
          <div v-for="(language, index) in languagesIds" :key="index">
            <div
              v-if="languagesIds.length > 0 && language?.languageId && language?.languageLevelId"
              class="flex flex-col md:flex-row justify-start items-center gap-8 mt-8"
            >
              <p>{{ allLanguages.find(item => item.value === language?.languageId)?.title }}</p>
              <p>{{ apps.find(item => item.value === language?.languageLevelId)?.title }}</p>

              <div class="flex gap-4">
                <SvgIcon
                  @click.stop="removeLanguage(index, language?.languageId)"
                  class="text-red cursor-pointer"
                  :class="{ '!text-grey-500': index !== languagesIds.length - 1 }"
                  name="trash"
                />
                <SvgIcon
                  v-if="index === languagesIds.length - 1 && languagesIds.length < allLanguages.length"
                  @click.stop="addLanguage(language.languageId)"
                  class="text-primary-500 cursor-pointer"
                  name="plus"
                />
              </div>
            </div>
            <div v-else class="flex flex-col md:flex-row justify-between items-center gap-8 mt-8">
              <Select
                :placeholder="t('choose')"
                :items="languages"
                class="w-full"
                v-model="language.languageId"
                :label="t('languages')"
              />
              <Select
                :items="apps"
                :class="[index === languagesIds.length - 1 ? 'w-[82%]' : 'w-[90%]']"
                v-model="language.languageLevelId"
                :label="t('languageLevels')"
                :placeholder="t('choose')"
              />
              <div class="mt-5 flex gap-4">
                <SvgIcon
                  @click.stop="removeLanguage(index, null)"
                  class="text-red cursor-pointer"
                  :class="{ '!text-grey-500': index === 0 }"
                  name="trash"
                />
                <SvgIcon
                  v-if="index === languagesIds.length - 1 && language.languageId && language.languageLevelId"
                  @click.stop="addLanguage(language.languageId)"
                  class="text-primary-500 cursor-pointer"
                  name="plus"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="mt-20 flex gap-2 justify-end">
          <Button @click="createNewVacancyHandler" v-if="vacancy?.asTemplate" :loading="createLoading">
            {{ t('vacancyPage.saveAsNewVacancy') }}
          </Button>
          <Button :loading="saveLoading" @click="saveHandler">
            {{ vacancy?.asTemplate ? t('vacancyPage.editTemplate') : t('vacancyPage.editVacancy') }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { computed, ref, watch } from 'vue';
  import useVuelidate from '@vuelidate/core';

  import Button from '@/components/ui/Button.vue';
  import Input from '@/components/ui/Input.vue';
  import Textarea from '~/components/ui/Textarea.vue';
  import Select from '~/components/ui/Select.vue';
  import Checkbox from '~/components/ui/Checkbox.vue';
  import Multiselect from '~/components/ui/Multiselect.vue';
  import { createVacancyRequest, getVacancyByIdRequest, updateVacancyRequest } from '~/services/vacancy';
  import { getWorkSchedulesRequest } from '~/services/workSchedules';
  import { getSpecializationCategoriesRequest } from '~/services/specializationCategory';
  import { getSpecializationsRequest } from '~/services/specialization';
  import { getEducationsRequest } from '~/services/educations';
  import { type TItem } from '~/types/global';
  import { useRoute } from 'vue-router';
  import { toast } from 'vue3-toastify';
  import Spinner from '@/components/ui/Spinner.vue';
  import { useI18n } from 'vue-i18n';
  import { getEmploymentTypesRequest } from '@/services/employmentTypes';
  import { getRegions } from '@/services/regions';
  import { getLanguages } from '@/services/languages';
  import { getApps } from '@/services/auth';
  import SvgIcon from '@/components/ui/SvgIcon.vue';
  import { useCoreStore } from '@/stores/core';
  const { t, locale } = useI18n();
  const route = useRoute();
  const isSubmit = ref(false);
  const loading = ref(false);
  const saveLoading = ref(false);
  const createLoading = ref(false);
  const workSchedules = ref<TItem[]>([]);
  const educations = ref<TItem[]>([]);
  const specializations = ref<TItem[]>([]);
  const specializationCategories = ref<TItem[]>([]);
  const specializationCategory = ref('');
  const employmentTypes = ref<TItem[]>([]);
  const regions = ref<any[]>([]);
  const allRegions = ref<any[]>([]);
  const cities = ref<any[]>([]);
  const currentLocale = ref('Ru');
  const apps = ref<any[]>([]);
  const coreStore = useCoreStore();
  const languagesIds = ref<{ languageId: string; languageLevelId: number | null }[]>([
    {
      languageId: '',
      languageLevelId: null,
    },
  ]);

  const languages = ref<any[]>([]);
  const allLanguages = ref<any[]>([]);
  const vacancy = ref<any>({
    'positionTj': '',
    'positionEn': '',
    'positionRu': '',
    'ageFrom': 0,
    'ageTo': 0,
    'gender': 0,
    'vacancyCount': 0,
    'address': '',
    'salaryCurrency': 0,
    'contractual': true,
    'requirementsTj': '',
    'requirementsRu': '',
    'requirementsEn': '',
    'commitmentTj': '',
    'commitmentRu': '',
    'commitmentEn': '',
    'descriptionEn': '',
    'descriptionTj': '',
    'descriptionRu': '',
    'durationDate': '',
    'active': true,
    'workForDisabled': true,
    'AsTemplate': false,
    'specializationIds': [],
    'workExperience': '',
    'educationIds': [],
    'workScheduleIds': [],
    'EmploymentTypeId': '',
    'regionId': '',
    'cityId': '',
    Languages: [],
  });
  const rules = computed(() => ({}));

  const localePostfix = computed(() => {
    return locale.value === 'ru' ? 'Ru' : locale.value === 'en' ? 'En' : 'Tj';
  });

  const v$ = useVuelidate(rules, vacancy);

  getDatas();

  async function saveHandler() {
    isSubmit.value = true;
    v$.value.$touch();
    if (v$.value.$invalid) {
      return;
    }
    saveLoading.value = true;
    //@ts-ignore
    vacancy.value.Languages = languagesIds.value;
    //@ts-ignore
    await updateVacancyRequest(route.params.id?.toString() as string, vacancy.value)
      .then(() => {
        toast.success(`${t('dataSuccessfullyUpdated')}`);
      })
      .finally(() => {
        saveLoading.value = false;
      });
  }
  async function createNewVacancyHandler() {
    isSubmit.value = true;
    v$.value.$touch();
    if (v$.value.$invalid) {
      return;
    }
    createLoading.value = true;
    //@ts-ignore
    vacancy.value.Languages = languagesIds.value;
    vacancy.value.AsTemplate = false;
    //@ts-ignore
    await createVacancyRequest(coreStore.profile?.profileId as string, vacancy.value)
      .then(() => {
        toast.success(t('successfullySaved'));
      })
      .finally(() => {
        createLoading.value = false;
      });
  }

  async function getVacancyById() {
    const res = await getVacancyByIdRequest(route.params.id?.toString());
    //@ts-ignore
    vacancy.value = res.payload;
    //@ts-ignore
    vacancy.value.workScheduleIds = res.payload.workSchedule?.map(i => i.id);
    //@ts-ignore
    vacancy.value.specializationIds = res.payload.vacancySpecializations?.map(i => i.id);
    //@ts-ignore
    specializationCategory.value = res.payload.vacancySpecializations[0]?.categoryId || '';
    //@ts-ignore
    vacancy.value.educationIds = res.payload.vacancyEducation?.map(i => i.id);
    //@ts-ignore
    vacancy.value.EmploymentTypeId = res.payload.employmentType?.id;
    //@ts-ignore
    vacancy.value.cityId = res.payload.city?.id;
    //@ts-ignore
    vacancy.value.regionId = res.payload.region?.id;
    //@ts-ignore
    vacancy.value.AsTemplate = res.payload.asTemplate;
    //@ts-ignore
    vacancy.value.Languages = res.payload.languages;
    if (vacancy.value?.Languages?.length) {
      languagesIds.value = [];
      vacancy.value?.Languages?.forEach((item: any) => {
        languagesIds.value.push({
          languageId: item.id,
          languageLevelId: item.languageLevelId,
        });
      });
      vacancy.value?.Languages?.forEach((item: any) => {
        languages.value = languages.value.filter((lang: any) => {
          return lang.value !== item.id;
        });
      });
    }
  }

  const getRegionsHandler = () => {
    getRegions().then((response: any) => {
      allRegions.value = response.data.payload.items || [];
      getCitiesHandler();
      response.data.payload.items?.forEach((item: any) => {
        if (!item.parentId) {
          regions.value.push({
            value: item.id,
            title: item[`name${localePostfix.value}`],
            viewTitle: item[`name${localePostfix.value}`],
          });
        }
      });
    });
  };

  const getCitiesHandler = () => {
    if (!vacancy.value.regionId) return;
    cities.value = [];
    allRegions.value.forEach(region => {
      if (region.parentId === vacancy.value.regionId) {
        cities.value.push({
          value: region.id,
          title: region[`name${localePostfix.value}`],
          viewTitle: region[`name${localePostfix.value}`],
        });
      }
    });
  };

  watch(
    () => vacancy.value.cityId,
    () => {
      getCitiesHandler();
    }
  );
  const getSpecializationsHandler = () => {
    specializations.value = [];
    getSpecializationsRequest({ Category: specializationCategory.value }).then((response: any) => {
      response &&
        response.payload.items.forEach((item: any) => {
          specializations.value.push({ value: item?.id, title: item[`name${localePostfix.value}`] });
        });
    });
  };

  watch(
    () => specializationCategory.value,
    () => {
      getSpecializationsHandler();
    },
    { immediate: true }
  );
  function getDatas() {
    loading.value = true;
    Promise.all([
      getWorkSchedulesRequest(),
      getEducationsRequest(),
      getSpecializationsRequest({ Category: specializationCategory.value }),
      getSpecializationCategoriesRequest(),
      getEmploymentTypesRequest(),
    ])
      .then(res => {
        //@ts-ignore
        workSchedules.value = res[0].payload.items?.map(i => ({ title: i[`name${localePostfix.value}`], value: i.id }));
        //@ts-ignore
        educations.value = res[1].payload?.map(i => ({ title: i[`name${localePostfix.value}`], value: i.id }));

        specializations.value = res[2].payload.items?.map(i => ({
          //@ts-ignore
          title: i[`name${localePostfix.value}`],
          value: i.id,
        }));

        specializationCategories.value = res[3].payload.items?.map(i => ({
          //@ts-ignore
          title: i[`name${localePostfix.value}`],
          value: i.id,
        }));

        employmentTypes.value = res[4].payload.items.map(i => ({
          //@ts-ignore
          title: i[`name${localePostfix.value}`],
          value: i.id,
        }));
        getVacancyById();
      })
      .catch(err => {
        console.log('error on getting data: ', err);
      })
      .finally(() => (loading.value = false));
  }

  const getLanguagesHandler = () => {
    getLanguages().then((response: any) => {
      response.data &&
        response.data.payload.items.forEach((item: any) => {
          languages.value.push({
            value: item?.id,
            title: item[`name${localePostfix.value}`],
          });
          allLanguages.value.push({
            value: item?.id,
            title: item[`name${localePostfix.value}`],
          });
        });
    });
  };
  const getAppsHandler = () => {
    getApps().then((response: any) => {
      response.data &&
        response.data.languageLevels.forEach((item: any) => {
          apps.value.push({
            value: item?.id,
            title: item[`name${localePostfix.value}`],
          });
        });
    });
  };

  const addLanguage = (id: string) => {
    languages.value = languages.value.filter((language: any) => {
      return language.value !== id;
    });
    languagesIds.value.push({ languageId: '', languageLevelId: null });
  };
  const removeLanguage = (index: number, id: string | null) => {
    if (index !== languagesIds.value.length - 1) return;
    if (id) {
      allLanguages.value.forEach((item: any) => {
        if (item.value === id && !languages.value.find(item => item.value === id)) {
          languages.value.push(item);
        }
      });
    }
    languagesIds.value.splice(index, 1);
    if (!languagesIds.value.length) {
      languagesIds.value.push({
        languageId: '',
        languageLevelId: null,
      });
    }
  };

  getRegionsHandler();
  getLanguagesHandler();
  getAppsHandler();
</script>
