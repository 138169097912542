<template>
  <div class="flex items-start" :class="{ 'gap-2.5': label }">
    <div class="flex h-6 items-center gap-3">
      <input
        :id="checkboxId"
        :aria-describedby="`${checkboxId}-description`"
        :name="checkboxId"
        :value="value"
        type="checkbox"
        class="focus:ring-0 focus:ring-offset-0 rounded border-2 border-grey-200 disabled:bg-grey-200 disabled:border-grey-200 cursor-pointer transition-all duration-200"
        :class="[{ 'border-red text-red': error && !disabled }, [variants[variant]], sizes[size]]"
        :checked="isChecked"
        @change="changeHandler"
      />
    </div>
    <div class="text-sm leading-6">
      <label :for="checkboxId" class="text-gray-900 cursor-pointer">
        <slot name="label">{{ label }}</slot>
      </label>
      <p v-if="description" :id="`${checkboxId}-description`" class="text-gray-500">
        {{ description }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { generatorId } from '@/utils/generators';
  import { computed } from 'vue';

  const sizes = {
    sm: 'w-4 h-4',
    md: 'w-5 h-5',
  };

  type TProps = {
    id?: string;
    modelValue: boolean | (string | number)[];
    label?: string;
    description?: string;
    value?: string | number | boolean;
    variant?: 'primary' | 'green';
    disabled?: boolean;
    error?: boolean;
    size?: keyof typeof sizes;
  };

  const variants = {
    primary: 'text-primary-500 checked:border-primary-500',
    green: 'text-green-500 checked:border-green-500',
  };

  const emit = defineEmits<{
    (e: 'update:modelValue', selectedItems: (string | number)[] | boolean): void;
  }>();

  const props = withDefaults(defineProps<TProps>(), {
    variant: 'primary',
    size: 'sm',
  });

  const checkboxId = props.id || generatorId('checkbox-');

  const isChecked = computed<boolean>(() => {
    if (Array.isArray(props.modelValue)) {
      return props.modelValue.toString().includes(props.value?.toString() || '');
    }
    if (typeof props.modelValue === 'boolean') {
      return props.modelValue;
    }
    //@ts-ignore
    return props.modelValue?.toString() === props.value?.toString();
  });

  function changeHandler() {
    let result = props.modelValue;
    if (Array.isArray(result) && Array.isArray(props.modelValue)) {
      if (props.modelValue.toString().includes(props.value?.toString() || '')) {
        result = props.modelValue.filter((item: number | string) => item?.toString() !== props.value?.toString());
      } else {
        result.push(props.value?.toString() || '');
      }
    } else {
      result = !props.modelValue;
    }
    emit('update:modelValue', result);
  }
</script>
