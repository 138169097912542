<template>
  <Modal
    @submit="saveData"
    @cancel="onCancel"
    v-model="modalValue"
    :title="t('education')"
    :cancel-button-text="t('globalTs.cancel')"
    :submit-button-text="t('add')"
  >
    <div class="flex justify-center mt-4" v-if="getLoading">
      <Spinner />
    </div>
    <div v-else>
      <Input
        class="w-full mb-4"
        :error-text="t('education')"
        :is-error="isSubmitted && v$.universityName.$invalid"
        v-model="data.universityName"
        :label="t('institution') + '*'"
        placeholder="МГУ"
      />
      <div class="flex flex-col md:flex-row items-end justify-between gap-8 mt-4">
        <div class="w-full">
          <label class="block text-[15px] text-black font-normal mb-1.5">{{ t('yearFrom') + '*' }}</label>
          <VueDatePicker
            auto-apply
            :start-date="new Date('01.01.1970')"
            year-picker
            v-model="data.startDate"
            format="yyyy"
            name="2013"
          />
          <p class="text-red" v-if="isSubmitted && v$.startDate.$invalid">{{ t('validationError') }}</p>
        </div>
        <div class="w-full">
          <label class="block text-[15px] text-black font-normal mb-1.5">{{ t('yearTo') }}</label>
          <VueDatePicker
            auto-apply
            :start-date="new Date('01.01.1970')"
            year-picker
            v-model="data.endDate"
            format="yyyy"
            :name="t('yearTo')"
          />
          <p class="text-red" v-if="isSubmitted && data.endDate && +data.endDate < +data.startDate">Ошибка валидации</p>
        </div>
        <Checkbox v-model="data.rightNow" class="w-full mb-4" :label="t('applicantPage.stillStudy')" />
      </div>
      <Select
        :error-text="t('requiredField')"
        :is-error="isSubmitted && v$.educationId.$invalid"
        :items="educations"
        class="w-full mt-4"
        v-model="data.educationId"
        :label="t('educationLevel') + '*'"
        :placeholder="t('choose')"
      />
      <div class="mt-4">
        <Textarea
          :placeholder="`${t('description')}...`"
          :label="t('description')"
          class="w-full"
          v-model="data.description"
        />
      </div>
    </div>
  </Modal>
</template>
<script setup lang="ts">
  import Modal from '@/components/ui/Modal.vue';
  import { watch, ref, reactive, computed, onMounted } from 'vue';
  import { required } from '@vuelidate/validators';
  import { useVuelidate } from '@vuelidate/core';
  import Input from '@/components/ui/Input.vue';
  import VueDatePicker from '@vuepic/vue-datepicker';
  import Select from '@/components/ui/Select.vue';
  import Spinner from '@/components/ui/Spinner.vue';
  import { getEducationsRequest } from '@/services/educations';
  import Textarea from '@/components/ui/Textarea.vue';
  import { createEducationRequest } from '@/services/applicant';
  import { maxMinDateValidation } from '@/utils/dateValidation';
  import Checkbox from '@/components/ui/Checkbox.vue';
  import { useI18n } from 'vue-i18n';

  const { t, locale } = useI18n();

  const props = defineProps<{ modal: boolean; applicantId: string }>();
  const emit = defineEmits(['updateModalValue', 'created']);
  const modalValue = ref(false);
  const educations = ref<any>([]);
  const getLoading = ref(false);
  const isSubmitted = ref(false);
  const loading = ref(false);
  let data = reactive({
    universityName: '',
    educationId: '',
    description: '',
    startDate: '',
    rightNow: false,
    endDate: '',
  });

  const rules = computed(() => ({
    universityName: { required },
    educationId: { required },
    startDate: { required, maxMinDateValidation },
  }));

  watch(
    () => props.modal,
    () => {
      modalValue.value = props.modal;
    }
  );

  function onCancel(value: any) {
    data = {
      universityName: '',
      educationId: '',
      description: '',
      startDate: '',
      endDate: '',
      rightNow: false,
    };
    emit('updateModalValue', value);
  }

  function saveData() {
    isSubmitted.value = true;
    if (v$.value.$invalid) {
      v$.value.$touch();
      return;
    }

    if (data.endDate && +data.endDate < +data.startDate) {
      return;
    }

    data.endDate = data.endDate ? data.endDate : new Date().getFullYear().toString();
    data.endDate = data.endDate.toString();
    data.startDate = data.startDate.toString();
    loading.value = true;
    createEducationRequest(props.applicantId, data)
      .then(() => {
        emit('created');
        onCancel(false);
      })
      .finally(() => {
        isSubmitted.value = false;
        loading.value = false;
      });
  }

  const getEducationsHandler = () => {
    getLoading.value = true;
    getEducationsRequest()
      .then(response => {
        response &&
          response.payload.forEach(item => {
            educations.value.push({
              value: item?.id,
              title: locale.value === 'ru' ? item?.nameRu : locale.value === 'en' ? item?.nameEn : item?.nameTj,
            });
          });
      })
      .finally(() => (getLoading.value = false));
  };

  onMounted(() => {
    getEducationsHandler();
  });

  const v$ = useVuelidate(rules, data);
</script>

<style scoped></style>
