<script setup lang="ts">
  import { useCookies } from 'vue3-cookies';

  const { cookies } = useCookies();
  import { RouterView } from 'vue-router';
  import { onMounted } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { HttpTransportType, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
  import { useCoreStore } from '@/stores/core';
  const coreStore = useCoreStore();
  const socketConnection = (token: string) => {
    const connection = new HubConnectionBuilder()
      .configureLogging(LogLevel.Information)
      .withUrl('wss://api.volunteers.tj/onlineHub', {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
        accessTokenFactory: () => token,
      })
      .withAutomaticReconnect()
      .build();
    connection.on('UpdateNotificationCount', data => {
      coreStore.setNotificationsCount(data);
      console.log('coreStore: ', coreStore.notificationsCount);
    });
    connection.start().catch(e => {
      console.error('error: ' + e);
    });
  };

  const { locale } = useI18n({ useScope: 'global' });
  onMounted(() => {
    const authToken = cookies?.get('token');
    if (authToken) {
      socketConnection(authToken);
    }
    const activeLocale = localStorage.getItem('locale');
    locale.value = activeLocale || 'tj';
  });
</script>

<template>
  <RouterView />
</template>
