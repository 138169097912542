<template>
  <div class="flex justify-between mt-4">
    <div>
      <div v-if="totalCount > 10" class="w-full flex space-x-4 items-center">
        <p>{{ t('startHome.showPerPage') }}:</p>
        <div>
          <Select
            size="md"
            variant="primary"
            placeholder="10"
            @update:model-value="changePageSize"
            :model-value="pageSize"
            :items="
              [
                { title: 10, value: 10 },
                { title: 20, value: 20 },
                { title: 30, value: 30 },
                { title: 40, value: 40 },
                { title: 50, value: 50 },
              ].map(i => ({
                title: i.title.toString(),
                value: i.value.toString(),
              }))
            "
          >
          </Select>
        </div>
      </div>
    </div>
    <div v-if="totalPage > 1" class="flex items-center">
      <nav class="relative z-0 inline-flex rounded-md space-x-2 w-full" aria-label="Pagination">
        <button class="inline-flex items-center px-1.5 h-[36px] mr-2 bg-primary-300 rounded" @click="prevPage">
          <SvgIcon name="chevron-left" class="w-6 h-6 text-grey-100" />
        </button>
        <button :class="setPageItemClasses(modelValue === 1)" @click="paginate(1)">1</button>
        <span
          v-if="modelValue >= 5"
          class="relative inline-flex items-center justify-center w-full min-w-[36px] h-[36px]"
        >
          ...
        </span>
        <button
          v-for="page in paginationPages"
          :key="page"
          :class="setPageItemClasses(modelValue === page)"
          @click="paginate(page)"
        >
          {{ page }}
        </button>
        <span
          v-if="modelValue < totalPage - 3"
          class="relative inline-flex items-center justify-center w-full min-w-[36px] h-[36px]"
        >
          ...
        </span>
        <button :class="['rounded', setPageItemClasses(modelValue === totalPage)]" @click="paginate(totalPage)">
          {{ totalPage }}
        </button>
        <button
          class="shadow-sm inline-flex items-center ml-2 px-1.5 h-[36px] bg-primary-300 rounded"
          @click="nextPage"
        >
          <SvgIcon name="chevron-right" class="w-6 h-6" />
        </button>
      </nav>
    </div>
  </div>
</template>

<script setup lang="ts">
  import SvgIcon from '@/components/ui/SvgIcon.vue';
  import { computed } from 'vue';
  import Select from '@/components/ui/Select.vue';
  import { useI18n } from 'vue-i18n';

  export type TProps = {
    modelValue: number;
    totalPage: number;
    totalCount: number;
    pageSize: number;
  };
  const { t } = useI18n();
  const props = defineProps<TProps>();

  const emit = defineEmits<{
    (e: 'update:modelValue', value: number): void;
    (e: 'update:pageSize', value: number): void;
  }>();

  const paginationPages = computed(() => {
    if (props.totalPage < 7) {
      return generateArrayOfNumbers(2, props.totalPage);
    }
    if (props.modelValue < 5) {
      return generateArrayOfNumbers(2, 6);
    }
    if (props.modelValue > 4 && props.modelValue < props.totalPage - 3) {
      return generateArrayOfNumbers(props.modelValue - 1, props.modelValue + 2);
    }

    return generateArrayOfNumbers(props.totalPage - 4, props.totalPage);
  });

  function paginate(value: number) {
    emit('update:modelValue', value);
  }

  function setPageItemClasses(isActive: boolean): string {
    return `flex items-center text-sm justify-center min-w-[36px] h-[36px] mx-[6px] py-[13px] ${
      isActive ? 'bg-white border border-grey-100 rounded text-primary' : 'hover:bg-primary-300'
    }`;
  }

  function generateArrayOfNumbers(from: number, to: number): number[] {
    let i: number | null = from;
    const arrayOfNumbers = [];
    for (i; i < to; ++i) {
      arrayOfNumbers.push(i);
    }
    i = null;
    return arrayOfNumbers;
  }

  function prevPage() {
    if (props.modelValue > 1) {
      emit('update:modelValue', props.modelValue - 1);
    }
  }
  function nextPage() {
    if (props.totalPage > props.modelValue) {
      emit('update:modelValue', props.modelValue + 1);
    }
  }
  function changePageSize(size: string | number | boolean | null) {
    //@ts-ignore
    emit('update:pageSize', +size);
  }
</script>
