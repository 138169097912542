<template>
  <div>
    <div class="mx-auto max-w-7xl min-h-[80vh]" :class="{ 'py-6 sm:px-6 lg:px-8': !noHeader }">
      <h3 v-if="!noHeader" class="text-3xl font-medium pl-4 md:pl-0">{{ t('allApplications') }}</h3>

      <div class="bg-white rounded-md p-5 mt-10">
        <div class="md:flex justify-between items-center mb-6">
          <p>{{ noHeader ? t('employerPage.recentApplicants') : t('allApplications') }}</p>
          <div>
            <Input
              :placeholder="t('search')"
              class-name="rounded-md w-full md:w-[400px] mt-4 md:mt-0"
              v-model="query.query"
            />
          </div>
        </div>
        <div v-if="loading" class="flex justify-center my-10">
          <Spinner />
        </div>
        <div v-else>
          <div v-if="applications?.items.length">
            <div v-for="vacation in applications?.items" :key="vacation.id" class="mb-8">
              <div
                @click="showApplications(vacation.id)"
                class="p-4 rounded-md bg-primary-500 flex justify-between mb-8 text-white cursor-pointer"
              >
                <p>{{ vacation?.[`position${localePostfix}`] || t('notSpecified') }}</p>
                <p>{{ t('total') }}: {{ vacation?.applicants?.length || 0 }}</p>
                <SvgIcon :class="vacation.id === vacationShowId ? 'rotate-90' : ''" name="chevron-down" />
              </div>
              <div
                v-if="vacation?.applicants?.length && vacationShowId === vacation.id"
                class="grid grid-cols-1 lg:grid-cols-2 gap-6"
              >
                <div
                  v-for="application in vacation?.applicants"
                  :key="application.id"
                  class="rounded-md p-5 bg-white flex shadow-inner space-x-5 w-full"
                >
                  <div>
                    <img
                      v-if="application.photo"
                      class="rounded-full w-24 h-24"
                      :src="application.photo"
                      alt="avatar"
                    />
                    <PersonIcon v-else class="w-24 h-24" />
                  </div>
                  <div class="relative">
                    <p class="text-lg">{{ application.fullName }}</p>
                    <div class="flex gap-x-4 gap-y-1.5 !text-xs text-grey-400 mt-1 flex-wrap">
                      <p class="text-primary-500 font-medium">{{ application.position }}</p>
                      <div class="flex items-center space-x-1">
                        <SvgIcon name="money" />
                        <p>{{ t('from') }} {{ application.currentSalary || t('notSpecified') }}</p>
                      </div>
                      <div class="flex items-center space-x-1">
                        <SvgIcon name="location" />
                        <p>{{ application.region?.[`name${localePostfix}`] }}</p>
                      </div>
                    </div>
                    <div class="flex gap-2 mt-4 overflow-x-auto flex-wrap">
                      <div
                        class="rounded-full text-xs py-0.5 px-3 text-grey-400 bg-grey-50"
                        v-for="(skill, index) in 2"
                        :key="index"
                      >
                        {{ 'dashb' }}
                      </div>
                    </div>
                  </div>
                  <div v-if="application.statusId === 1" class="flex space-x-1 items-center w-full">
                    <div class="rounded bg-primary-200 p-0.5 inline cursor-pointer">
                      <SvgIcon name="eye" class="text-primary-500" />
                    </div>
                    <span
                      @click="approveApplication(vacation.id, application.applicantId)"
                      class="rounded bg-primary-200 p-0.5 cursor-pointer"
                    >
                      <SvgIcon filled name="check2" class="text-primary-500" />
                    </span>
                    <div
                      @click="rejectApplication(vacation.id, application.applicantId)"
                      class="rounded bg-primary-200 p-0.5 cursor-pointer"
                    >
                      <SvgIcon name="x" class="text-primary-500 w-3 h-3" />
                    </div>
                    <span
                      @click="deleteApplication(vacation.id, application.applicantId)"
                      class="rounded bg-primary-200 p-0.5 w-4 h-4 cursor-pointer"
                    >
                      <SvgIcon name="blue-trash" class="text-primary-500 w-3 h-3" />
                    </span>
                  </div>
                  <div v-else class="flex space-x-1 items-center">
                    <div class="rounded bg-primary-200 p-0.5 inline cursor-pointer">
                      <SvgIcon name="eye" class="text-primary-500 w-3 h-3" />
                    </div>
                    <span
                      :class="
                        application.statusId === 3 ? 'bg-primary-500 text-white' : 'bg-primary-200 text-primary-500'
                      "
                      class="rounded p-0.5"
                    >
                      <SvgIcon filled name="check2" class="" />
                    </span>
                    <div
                      :class="application.statusId === 2 ? 'bg-red text-white' : 'bg-primary-200 text-primary-500'"
                      class="rounded p-0.5"
                    >
                      <SvgIcon name="x" class="w-3 h-3" />
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-else-if="!vacation?.applicants?.length"
                class="mt-8 flex items-center justify-center p-8 border border-[#ECEDF2] bg-white rounded-lg"
              >
                <p class="text-grey-200">{{ t('noApplications') }}</p>
              </div>
            </div>
          </div>
          <div v-else class="mt-8 flex items-center justify-center p-8 border border-[#ECEDF2] bg-white rounded-lg">
            <p class="text-grey-200">{{ t('noData') }}</p>
          </div>
        </div>
        <div v-if="applications?.totalPages > 1" class="mt-8">
          <Pagination
            v-model:modelValue="query.page"
            v-model:pageSize="query.size"
            :totalCount="applications.totalCount"
            :totalPage="applications.totalPages"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { getCompanyApplications } from '@/services/company';
  import { useCoreStore } from '@/stores/core';
  import { computed, onMounted, ref, watch } from 'vue';
  import Spinner from '@/components/ui/Spinner.vue';
  import { defineAsyncComponent } from 'vue';
  import SvgIcon from '@/components/ui/SvgIcon.vue';
  import Input from '@/components/ui/Input.vue';
  import Pagination from '@/components/ui/Pagination.vue';
  import { approveApplicationRequest, deleteApplicationRequest, rejectApplicationRequest } from '@/services/applicant';
  import { debounce } from '@/utils/debounce';
  import { useI18n } from 'vue-i18n';
  const PersonIcon = defineAsyncComponent(() => import('@/assets/images/person.svg'));

  const { t, locale } = useI18n();

  defineProps({ noHeader: Boolean });

  const coreStore = useCoreStore();

  const vacationShowId = ref<number | null>(null);

  const showApplications = (vacationId: number) => {
    if (vacationShowId.value === vacationId) vacationShowId.value = null;
    else vacationShowId.value = vacationId;
  };

  const applications = ref<any>({
    items: [],
  });

  const loading = ref(true);

  const query = ref({
    query: '',
    page: 1,
    size: 10,
  });

  const profile = computed(() => {
    return coreStore.profile;
  });

  const localePostfix = computed(() => {
    return locale.value === 'ru' ? 'Ru' : locale.value === 'en' ? 'En' : 'Tj';
  });

  const getApplications = async () => {
    loading.value = true;
    const { data } = await getCompanyApplications(query.value, coreStore.profile?.companyId || '');
    applications.value = data?.payload;
    if (applications.value?.items?.length === 1) {
      vacationShowId.value = applications.value.items[0].id;
    }
    loading.value = false;
  };

  const approveApplication = (vacancyId: string, applicantId: string) => {
    approveApplicationRequest(vacancyId, applicantId).then(() => {
      getApplications();
    });
  };

  const rejectApplication = (vacancyId: string, applicantId: string) => {
    rejectApplicationRequest(vacancyId, applicantId).then(() => {
      getApplications();
    });
  };

  const deleteApplication = (vacancyId: string, applicantId: string) => {
    deleteApplicationRequest(vacancyId, applicantId).then(() => {
      getApplications();
    });
  };
  onMounted(() => {
    if (profile.value) getApplications();
  });

  watch(
    profile,
    () => {
      getApplications();
    },
    { deep: true }
  );

  watch(
    () => query.value,
    debounce(() => {
      getApplications();
    }, 400),
    { deep: true }
  );
</script>
