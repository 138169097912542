<template>
  <main class="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
    <h3 class="text-3xl font-medium">{{ t('resume') }}</h3>

    <section class="bg-white px-8 py-10 mt-8 rounded">
      <div class="flex justify-center" v-if="loading">
        <Spinner />
      </div>
      <div v-else>
        <div v-if="!profile?.applicantId" class="mb-4 p-6 bg-yellow-100">
          {{ t('applicantPage.firstFillOutProfile') }}
        </div>
        <p>{{ t('applicantPage.myResume') }}</p>
        <div class="mt-8">
          <p>{{ t('description') }}</p>
          <div v-if="applicant?.about" class="mt-2 text-grey-200 p-4 bg-grey-50 rounded">
            <p v-html="applicant.about" />
          </div>
          <p v-else class="mt-2 text-grey-200 p-4 bg-grey-50 rounded">
            {{ t('applicantPage.fillOnProfile') }}
          </p>
        </div>
        <div class="mt-8">
          <div class="flex justify-between">
            <p>{{ t('education') }}</p>
            <div
              @click="addEducationModal = !addEducationModal"
              class="cursor-pointer flex text-red items-center gap-1"
            >
              <div class="p-0.5 rounded-full hover:bg-danger-200 bg-danger-100 text-sm"><SvgIcon name="plus" /></div>
              <span>{{ t('add') }}</span>
            </div>
          </div>
          <div v-if="applicant?.educations.length" class="flex flex-col gap-16 mt-8">
            <div v-for="education in applicant?.educations" :key="education.id" class="flex items-start md:gap-7 gap-2">
              <span class="rounded-full text-red bg-danger-100 py-1 px-2 mt-2"> M </span>
              <div>
                <div class="mb-6">
                  <div class="flex md:gap-20 gap-2">
                    <p class="font-normal">{{ education[`educationName${localePostfix}`] }}</p>
                    <div class="flex gap-2 items-center">
                      <span class="bg-danger-100 text-red px-3 py-1 rounded-full text-sm">
                        {{ education.startDate + '-' + (education.endDate || t('applicantPage.stillStudy')) }}
                      </span>
                      <div
                        @click="selectEducationHandler(education)"
                        class="cursor-pointer px-2 py-2 bg-primary-200 rounded-full text-primary-500"
                      >
                        <SvgIcon name="pen" />
                      </div>
                      <div
                        @click="getDataToDelete(education.id, 'education')"
                        class="cursor-pointer px-2 py-2 bg-primary-200 rounded-full text-primary-500"
                      >
                        <SvgIcon name="trash2" />
                      </div>
                    </div>
                  </div>

                  <p class="text-red text-sm">{{ education.universityName }}</p>
                </div>
                <div class="text-grey-500">{{ education.description }}</div>
              </div>
            </div>
          </div>
          <div class="mt-8 text-grey-200 text-sm" v-else>{{ t('noData') }}</div>
        </div>
        <hr class="my-12 text-grey-50" />
        <div>
          <div class="flex justify-between">
            <p>{{ t('workExperience') }}</p>
            <div
              @click="addExperienceModal = !addExperienceModal"
              class="cursor-pointer flex text-red items-center gap-1"
            >
              <div class="p-0.5 rounded-full hover:bg-danger-200 bg-danger-100 text-sm"><SvgIcon name="plus" /></div>
              <span>{{ t('add') }}</span>
            </div>
          </div>
          <div v-if="applicant?.applicantWorkExperiences.length" class="flex flex-col mt-8 gap-16">
            <div
              v-for="experience in applicant?.applicantWorkExperiences"
              :key="experience.id"
              class="flex items-start gap-2 md:gap-7"
            >
              <span class="rounded-full text-primary-500 bg-primary-100 py-1 px-3 mt-2"> S </span>

              <div>
                <div class="mb-6">
                  <div class="flex gap-2 md:gap-20">
                    <p class="font-normal">{{ experience.position }}</p>
                    <div class="flex gap-2 items-center">
                      <span class="bg-danger-100 text-red px-3 py-1 rounded-full text-sm">
                        {{ experience.startDate + '-' + (experience.endDate || t('applicantPage.stillWorking')) }}
                      </span>
                      <div
                        @click="selectExperienceHandler(experience)"
                        class="cursor-pointer px-2 py-2 bg-primary-200 rounded-full text-primary-500"
                      >
                        <SvgIcon name="pen" />
                      </div>
                      <div
                        @click="getDataToDelete(experience.id, 'experience')"
                        class="cursor-pointer px-2 py-2 bg-primary-200 rounded-full text-primary-500"
                      >
                        <SvgIcon name="trash2" />
                      </div>
                    </div>
                  </div>
                  <p class="text-primary-500 text-sm">{{ experience.companyName }}</p>
                </div>
                <div class="text-grey-500">{{ experience.description }}</div>
              </div>
            </div>
          </div>
          <div class="mt-8 text-grey-200 text-sm" v-else>{{ t('noData') }}</div>
        </div>
        <hr class="my-12 text-grey-50" />
        <div>
          <p>{{ t('portfolio') }}</p>
          <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-10">
            <div class="grid-cols-5" v-for="portfolio in applicant?.portfolios" :key="portfolio.id">
              <div class="relative rounded mt-5 w-[190px] h-[167px] bg-grey-50 flex items-center justify-center">
                <Image class="absolute w-[190px] h-[167px]" :src="portfolio.file" alt="portfolio"></Image>
                <div class="absolute flex gap-2 items-center text-white">
                  <div
                    @click="selectPortfolioHandler(portfolio)"
                    class="cursor-pointer px-2 py-2 bg-white rounded-md text-primary-500"
                  >
                    <SvgIcon name="pen" />
                  </div>
                  <div
                    @click="getDataToDelete(portfolio.id, 'portfolio')"
                    class="cursor-pointer px-2 py-2 bg-white rounded-md text-primary-500"
                  >
                    <SvgIcon name="trash2" />
                  </div>
                </div>
              </div>
            </div>
            <div
              @click="addPortfolioModal = !addPortfolioModal"
              class="cursor-pointer rounded mt-5 w-[190px] h-[167px] bg-primary-500 flex items-center justify-center"
            >
              <div class="flex flex-col items-center text-white">
                <span class="px-2 py-0.5 rounded-full bg-primary-400">+</span>
                <span>{{ t('applicantPage.addPhoto') }}</span>
              </div>
            </div>
          </div>
        </div>

        <hr class="my-12 text-grey-50" />
        <div>
          <div class="flex justify-between">
            <p>{{ t('awards') }}</p>
            <div @click="addAwardModal = !addAwardModal" class="cursor-pointer flex text-red items-center gap-1">
              <div class="p-0.5 rounded-full hover:bg-danger-200 bg-danger-100 text-sm"><SvgIcon name="plus" /></div>
              <span>{{ t('add') }}</span>
            </div>
          </div>
          <div v-if="applicant?.award.length" class="flex flex-col mt-8 gap-16">
            <div v-for="award in applicant?.award" :key="award.id" class="flex items-start gap-7">
              <span class="rounded-full text-primary-500 bg-primary-100 py-1 px-3 mt-2"> S </span>

              <div>
                <div class="mb-6">
                  <div class="flex gap-1">
                    <p class="font-normal">{{ award[`name${localePostfix}`] || '-' }}</p>
                    <div class="flex md:gap-2 gap-1 items-center">
                      <span class="bg-danger-100 text-red px-3 py-1 rounded-full text-sm">
                        {{ award.startDate }}
                      </span>
                      <div
                        @click="selectAwardHandler(award)"
                        class="cursor-pointer px-2 py-2 bg-primary-200 rounded-full text-primary-500"
                      >
                        <SvgIcon name="pen" />
                      </div>
                      <div
                        @click="getDataToDelete(award.id, 'award')"
                        class="cursor-pointer px-2 py-2 bg-primary-200 rounded-full text-primary-500"
                      >
                        <SvgIcon name="trash2" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-grey-500">{{ award.description }}</div>
              </div>
            </div>
          </div>
          <div class="mt-8 text-grey-200 text-sm" v-else>{{ t('noData') }}</div>
        </div>

        <hr class="my-12 text-grey-50" />
        <div>
          <p>{{ t('skills') }}</p>

          <div class="mt-8 h-[150px] md:w-2/3 rounded-md bg-primary-100 flex gap-2 p-4">
            <template v-if="applicant?.skills.length">
              <div class="grid md:grid-cols-10 grid-cols-4 gap-1">
                <div class="sm:col-span-1" v-for="skill in applicant?.skills" :key="skill.id">
                  <Badge size="sm" variant="active" class="flex gap-1 items-center mb-1 md:mb-0">
                    {{ skill?.[`professionalSkillsName${localePostfix}`] }}
                    <SvgIcon class="cursor-pointer" name="x2" @click="getDataToDelete(skill.id, 'skill')" />
                  </Badge>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="text-grey-200 text-sm">{{ t('noData') }}</div>
            </template>
          </div>
          <div @click="addSkillsModal = !addSkillsModal" class="cursor-pointer flex text-red items-center gap-1 mt-4">
            <div class="p-0.5 rounded-full hover:bg-danger-200 bg-danger-100 text-sm"><SvgIcon name="plus" /></div>
            <span>{{ t('add') }}</span>
          </div>
        </div>
        <AddSkills
          :applicantId="applicant?.id"
          @created="getApplicantHandler(profile?.profileId || '')"
          @updateModalValue="addSkillsModal = $event"
          :modal="addSkillsModal"
        />
        <AddEducation
          :applicantId="applicant?.id"
          @created="getApplicantHandler(profile?.profileId || '')"
          @updateModalValue="addEducationModal = $event"
          :modal="addEducationModal"
        />
        <UpdateEducation
          :applicantId="applicant?.id"
          :dataFromParent="selectedEducation"
          @updated="getApplicantHandler(profile?.profileId || '')"
          @updateModalValue="editEducationModal = $event"
          :modal="editEducationModal"
        />
        <AddExperience
          :applicantId="applicant?.id"
          @created="getApplicantHandler(profile?.profileId || '')"
          @updateModalValue="addExperienceModal = $event"
          :modal="addExperienceModal"
        />

        <UpdateExperience
          :applicantId="applicant?.id"
          :dataFromParent="selectedExperience"
          @updated="getApplicantHandler(profile?.profileId || '')"
          @updateModalValue="editExperienceModal = $event"
          :modal="editExperienceModal"
        />
        <AddPortfolio
          :applicantId="applicant?.id"
          @created="getApplicantHandler(profile?.profileId || '')"
          @updateModalValue="addPortfolioModal = $event"
          :modal="addPortfolioModal"
        />
        <UpdatePortfolio
          :applicantId="applicant?.id"
          :dataFromParent="selectedPortfolio"
          @updated="getApplicantHandler(profile?.profileId || '')"
          @updateModalValue="editPortfolioModal = $event"
          :modal="editPortfolioModal"
        />
        <AddAward
          :applicantId="applicant?.id"
          @created="getApplicantHandler(profile?.profileId || '')"
          @updateModalValue="addAwardModal = $event"
          :modal="addAwardModal"
        />
        <UpdateAward
          :applicantId="applicant?.id"
          :dataFromParent="selectedAward"
          @updated="getApplicantHandler(profile?.profileId || '')"
          @updateModalValue="editAwardModal = $event"
          :modal="editAwardModal"
        />
        <UIModal
          @cancel="deleteModal = false"
          title="Удаление"
          submitButtonText="Удалить"
          @submit="deleteElementHandler"
          v-model="deleteModal"
        >
          <p>
            {{ t('doYouReallyWantToDelete') }}
            <strong>
              {{
                selectedElement?.type === 'education'
                  ? t('education')
                  : selectedElement?.type === 'experience'
                    ? t('workExperience')
                    : selectedElement?.type === 'skill'
                      ? t('skill')
                      : selectedElement?.type === 'award'
                        ? t('award')
                        : t('portfolio')
              }}
            </strong>
            ?
          </p>
        </UIModal>
      </div>
    </section>
  </main>
</template>
<script setup lang="ts">
  import { ref, computed, watch } from 'vue';
  import Spinner from '@/components/ui/Spinner.vue';
  import Badge from '@/components/ui/Badge.vue';
  import UIModal from '@/components/ui/Modal.vue';
  import Image from '@/components/ui/Image.vue';
  import { useCoreStore } from '@/stores/core';
  import {
    deleteAwardRequest,
    deleteEducationRequest,
    deletePortfolioRequest,
    deleteSkillRequest,
    deleteWorkExperienceRequest,
    getApplicantByProfileId,
  } from '@/services/applicant';
  import SvgIcon from '@/components/ui/SvgIcon.vue';
  import AddEducation from './components/AddEducation.vue';
  import UpdateEducation from './components/UpdateEducation.vue';
  import UpdateExperience from './components/UpdateExperience.vue';
  import AddExperience from './components/AddExperience.vue';
  import AddPortfolio from './components/AddPortfolio.vue';
  import AddAward from './components/AddAward.vue';
  import UpdateAward from './components/UpdateAward.vue';
  import AddSkills from './components/AddSkills.vue';
  import UpdatePortfolio from './components/UpdatePortfolio.vue';
  import { useI18n } from 'vue-i18n';

  const { t, locale } = useI18n();

  const profile = computed(() => {
    return useCoreStore().profile;
  });

  const applicant = ref<any>(null);
  const selectedElement = ref<{ id: string; type: string }>({ id: '', type: '' });
  const selectedEducation = ref<any>(null);
  const selectedExperience = ref<any>(null);
  const selectedPortfolio = ref<any>(null);
  const selectedAward = ref<any>(null);
  const isProfileFilled = ref(true);

  const loading = ref(false);
  const deleteLoading = ref(false);
  const addEducationModal = ref(false);
  const addExperienceModal = ref(false);
  const deleteModal = ref(false);
  const editEducationModal = ref(false);
  const editExperienceModal = ref(false);
  const editPortfolioModal = ref(false);
  const editAwardModal = ref(false);
  const addPortfolioModal = ref(false);
  const addAwardModal = ref(false);
  const addSkillsModal = ref(false);

  const localePostfix = computed(() => {
    return locale.value === 'ru' ? 'Ru' : locale.value === 'en' ? 'En' : 'Tj';
  });

  function getApplicantHandler(id: string) {
    if (!profile.value?.applicantId) return;
    loading.value = true;
    getApplicantByProfileId(id)
      .then(response => {
        applicant.value = response.data.payload;
      })
      .catch(() => {
        isProfileFilled.value = false;
      })
      .finally(() => {
        loading.value = false;
      });
  }

  function getDataToDelete(id: string, type: string) {
    selectedElement.value.id = id;
    selectedElement.value.type = type;
    deleteModal.value = true;
  }
  function selectEducationHandler(education: any) {
    selectedEducation.value = education;
    editEducationModal.value = true;
  }
  function selectExperienceHandler(experience: any) {
    selectedExperience.value = experience;
    editExperienceModal.value = true;
  }
  function selectPortfolioHandler(data: any) {
    selectedPortfolio.value = data;
    editPortfolioModal.value = true;
  }
  function selectAwardHandler(data: any) {
    selectedAward.value = data;
    editAwardModal.value = true;
  }

  function deleteElementHandler() {
    deleteLoading.value = true;
    if (selectedElement.value.type === 'education') {
      deleteEducationRequest(selectedElement.value.id)
        .then(() => {
          getApplicantHandler(profile?.value?.profileId || '');
          deleteModal.value = false;
        })
        .finally(() => (deleteLoading.value = false));
    } else if (selectedElement.value.type === 'experience') {
      deleteWorkExperienceRequest(selectedElement.value.id)
        .then(() => {
          getApplicantHandler(profile?.value?.profileId || '');
          deleteModal.value = false;
        })
        .finally(() => (deleteLoading.value = false));
    } else if (selectedElement.value.type === 'skill') {
      deleteSkillRequest(applicant.value.id, selectedElement.value.id)
        .then(() => {
          getApplicantHandler(profile?.value?.profileId || '');
          deleteModal.value = false;
        })
        .finally(() => (deleteLoading.value = false));
    } else if (selectedElement.value?.type === 'award') {
      deleteAwardRequest(selectedElement.value.id)
        .then(() => {
          getApplicantHandler(profile?.value?.profileId || '');
          deleteModal.value = false;
        })
        .finally(() => (deleteLoading.value = false));
    } else {
      deletePortfolioRequest(selectedElement.value.id)
        .then(() => {
          getApplicantHandler(profile?.value?.profileId || '');
          deleteModal.value = false;
        })
        .finally(() => (deleteLoading.value = false));
    }
  }

  watch(
    () => profile,
    () => {
      if (profile?.value?.profileId) getApplicantHandler(profile?.value?.profileId);
    },
    { immediate: true, deep: true }
  );
</script>

<style scoped></style>
