<template>
  <div class="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
    <div class="flex flex-col items-center justify-center -mx-8 p-4">
      <div class="p-12 mx-5 md:w-[650px] h-auto border-1 rounded-4 bg-white">
        <h3 class="text-center mb-4">{{ t('global.authorization') }}</h3>
        <div class="flex justify-center">
          <ButtonGroup
            @selectButton="type = $event"
            :selectedValue="type"
            class="my-6"
            :items="[
              { title: t('global.employee'), id: 1 },
              { title: t('global.employer'), id: 2 },
            ]"
          />
        </div>
        <div class="flex flex-col gap-2">
          <div v-if="type.id === 2" class="mb-4">
            <UIInput
              id="asdadasdsf"
              :disabled="loading"
              :isError="isSubmitted && v$.email.$invalid"
              :error-text="t('requiredField')"
              v-model="user.email"
              type="email"
              :label="t('global.login')"
              placeholder="test@test.com"
            />
          </div>
          <div v-else class="mb-4">
            <UIInput
              id="asdaweqd"
              :disabled="loading"
              :isError="isSubmitted && v$.phone?.$invalid"
              :error-text="`${t('requiredField')} (${t('format')}: +992ххххххххх)`"
              v-model="user.phone"
              type="tel"
              :label="t('global.phoneNumber')"
              placeholder="+992 000 00 00"
            />
          </div>
          <div>
            <UIInput
              id="asdad"
              type="password"
              :disabled="loading"
              :isError="isSubmitted && v$.password.$invalid"
              :error-text="t('requiredField')"
              v-model="user.password"
              :label="t('global.password')"
              placeholder="**********"
            />
          </div>
        </div>
        <div class="mt-4 flex justify-between">
          <Checkbox v-model="rememberMe" :label="t('rememberMe')" />
          <RouterLink :to="{ name: type.id === 1 ? 'forgot-password' : 'forgot-password-employer' }">
            <p class="cursor-pointer hover:text-grey-500 text-grey-200">{{ t('global.forgotPassword') }}?</p>
          </RouterLink>
        </div>
        <div class="flex items-center justify-center mt-4">
          <UIButton size="full" variant="primary" @click="authUser" :disabled="loading" :loading="loading">
            {{ t('global.entry') }}
          </UIButton>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import type { IUser } from '@/types/AuthView.types';
  import { computed, onMounted, reactive, ref } from 'vue';
  import { useVuelidate } from '@vuelidate/core';
  import { required, requiredIf } from '@vuelidate/validators';
  import UIInput from '@/components/ui/Input.vue';
  import UIButton from '@/components/ui/Button.vue';
  import { loginApplicant, loginEmployer } from '@/services/auth';
  import { useCookies } from 'vue3-cookies';
  import ButtonGroup from '@/components/ui/ButtonGroup.vue';
  import Checkbox from '@/components/ui/Checkbox.vue';
  import { useI18n } from 'vue-i18n';
  import { useRoute } from 'vue-router';

  const { cookies } = useCookies();
  const route = useRoute();
  const { t } = useI18n();

  const user = reactive<IUser>({ email: '', phone: '+992', password: '' });
  const rememberMe = ref(false);

  const type = ref({ title: t('applicant'), id: 1 });

  const loading = ref(false);
  const isSubmitted = ref(false);

  function authUser() {
    isSubmitted.value = true;
    if (v$.value.$invalid) {
      v$.value.$touch();
      return;
    }
    loading.value = true;
    if (type.value.id === 1) {
      loginApplicant(user)
        .then(response => {
          const token = response.data.payload.token || '';
          cookies.set('token', token);
          cookies.set('profile-type', 'user');
          window.location.reload();
        })
        .finally(() => {
          loading.value = false;
          isSubmitted.value = false;
        });
    } else {
      loginEmployer(user)
        .then(response => {
          const token = response.data.payload.token || '';
          cookies.set('token', token);
          cookies.set('profile-type', 'company');
          window.location.reload();
        })
        .finally(() => {
          loading.value = false;
          isSubmitted.value = false;
        });
    }
  }
  const mustBePhoneFormat = (value: string) => {
    if (type.value.id !== 1) return true;
    return value.match('(\\+992)([0-9]{9}$)');
  };
  const rules = computed(() => ({
    password: { required },
    email: {
      required: requiredIf(function () {
        return type.value.id === 2;
      }),
    },
    phone: {
      required: requiredIf(function () {
        return type.value.id === 1;
      }),
      mustBePhoneFormat,
    },
  }));

  onMounted(() => {
    if (route && route.query?.from === 'home-company') {
      type.value = { title: t('global.employer'), id: 2 };
    }
  });

  const v$ = useVuelidate(rules, user);
</script>
