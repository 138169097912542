import { type RouteRecordRaw } from 'vue-router';
import CoreLayout from '@/layouts/CoreLayout.vue';
import EmployerProfileView from '@/pages/employer/profile-page/ProfileView.vue';
import ApplicantProfileView from '@/pages/applicant/profile-page/ProfileView.vue';
import ResumeView from '@/pages/applicant/resume-page/ResumeView.vue';
import CVManagerView from '@/pages/applicant/cv-manager-page/CVManagerView.vue';
import AppliedJobsView from '@/pages/applicant/applied-jobs/AppliedJobsView.vue';
import HomePage from '@/pages/home/HomePage.vue';
import VacancyAddPage from '@/pages/vacancy/Add.vue';
import VacancyEditPage from '@/pages/vacancy/Edit.vue';
import ManageVacancies from '@/pages/vacancy/Manage.vue';
import AuthView from '@/pages/auth/AuthView.vue';
import RegistrationView from '@/pages/auth/RegistrationView.vue';
import ChangePasswordView from '@/pages/auth/ChangePasswordView.vue';
import MessagesIndexPage from '@/pages/messages/MessagesIndexPage.vue';
import ApplicationsIndex from '@/pages/applications/ApplicationsIndex.vue';
import FavoriteVacancies from '@/pages/applicant/favorite-vacancies/Favorite.vue';
import FavoriteResume from '@/pages/employer/favorite-resume/FavoriteResume.vue';
import UserAgreementsView from '@/pages/user-agreements/IndexPage.vue';
import RegularlyQuestionsView from '@/pages/regularly-questions/IndexPage.vue';
import AboutUsView from '@/pages/about-us/IndexPage.vue';
import CompaniesIndexPage from '@/pages/companies/Index.vue';
import CompanyDetail from '@/pages/companies/Detail.vue';
import SearchVacancies from '@/pages/search-vacancies/SearchVacancies.vue';
import VacancyDetail from '@/pages/search-vacancies/VacancyDetail.vue';
import VacancyTemplates from '@/pages/vacancy-templates/VacancyTemplates.vue';
import StartHomePage from '@/pages/start-home/StartHomePage.vue';

export const privateRoutes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: CoreLayout,
    redirect: { name: 'home' },
    children: [
      {
        path: '/home',
        name: 'home',
        component: HomePage,
      },
      {
        path: '/start-home',
        name: 'start-home',
        component: StartHomePage,
      },
      {
        path: '/home-company',
        name: 'home-company',
        component: () => import('@/pages/home-company/HomeCompanyView.vue'),
      },
      {
        path: '/vacancies',
        name: 'search-vacancies',
        component: SearchVacancies,
      },
      {
        path: '/vacancies/:id',
        name: 'vacancy-detail',
        component: VacancyDetail,
      },
      {
        path: 'companies',
        name: 'companies',
        component: CompaniesIndexPage,
      },
      {
        path: 'companies/:id',
        name: 'company-detail',
        component: CompanyDetail,
      },
      {
        path: '/contacts',
        name: 'contacts',
        component: () => import('@/pages/contacts/ContactsView.vue'),
      },
      {
        path: '/applicants',
        name: 'applicants',
        component: () => import('@/pages/applicants/ApplicantsView.vue'),
      },
      {
        path: '/applicants-show/:id',
        name: 'applicants-show',
        component: () => import('@/pages/applicants/ApplicantShowView.vue'),
      },
      {
        path: '/news',
        name: 'news',
        component: () => import('@/pages/news/NewsView.vue'),
      },
      {
        path: '/news-show/:id',
        name: 'news-show',
        component: () => import('@/pages/news/NewsShowView.vue'),
      },
      {
        path: '/profile',
        children: [
          {
            path: '/profile/change-password',
            name: 'change-password',
            component: ChangePasswordView,
          },
          {
            path: '/profile/messages',
            name: 'messages',
            component: MessagesIndexPage,
          },
          {
            path: '/profile/applications',
            name: 'applications',
            component: ApplicationsIndex,
          },
          {
            path: '/profile/company',
            name: 'company',
            component: EmployerProfileView,
          },
          {
            path: '/profile/applicant',
            name: 'applicant',
            component: ApplicantProfileView,
          },
          {
            path: '/profile/applicant-alerts',
            name: 'applicant-alerts',
            component: () => import('@/pages/applicant/applicant-alerts/Index.vue'),
          },
          {
            path: '/profile/resume',
            name: 'resume',
            component: ResumeView,
          },
          {
            path: '/profile/cv-manager',
            name: 'cv-manager',
            component: CVManagerView,
          },
          {
            path: '/profile/applied-jobs',
            name: 'applied-jobs',
            component: AppliedJobsView,
          },
          {
            path: '/profile/favorite-resume',
            name: 'favorite-resume',
            component: FavoriteResume,
          },

          {
            path: '/profile/add-new-vacancy',
            name: 'add-new-vacancy',
            component: VacancyAddPage,
          },
          {
            path: '/profile/vacancies/:id',
            name: 'vacancy-edit',
            component: VacancyEditPage,
          },
          {
            path: 'profile/vacancy-manage',
            name: 'vacancy-manage',
            component: ManageVacancies,
          },
          {
            path: 'profile/vacancy-templates',
            name: 'vacancy-templates',
            component: VacancyTemplates,
          },
          {
            path: 'profile/favorite-vacancies',
            name: 'favorite-vacancies',
            component: FavoriteVacancies,
          },
          {
            path: 'profile/alerts',
            name: 'alerts',
            component: () => import('@/pages/alerts/Index.vue'),
          },
          {
            path: 'profile/company-statistics',
            name: 'company-statistics',
            component: () => import('@/pages/employer/employer-statistics/EmployerStatisticsView.vue'),
          },
          {
            path: 'profile/applicant-statistics',
            name: 'applicant-statistics',
            component: () => import('@/pages/applicant/applicant-statistics/ApplicantStatisticsView.vue'),
          },
        ],
      },
      {
        path: '/auth',
        name: 'auth',
        component: AuthView,
      },
      {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('@/pages/auth/ForgotPassword.vue'),
      },
      {
        path: '/forgot-password-employer',
        name: 'forgot-password-employer',
        component: () => import('@/pages/auth/ForgotPasswordEmployer.vue'),
      },
      {
        path: '/registration',
        name: 'registration',
        component: RegistrationView,
      },
      {
        path: '/user-agreements',
        name: 'user-agreements',
        component: UserAgreementsView,
      },
      {
        path: '/regularly-questions',
        name: 'regularly-questions',
        component: RegularlyQuestionsView,
      },
      {
        path: '/about-us',
        name: 'about-us',
        component: AboutUsView,
      },
    ],
  },
];
