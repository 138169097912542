<template>
  <Modal @submit="saveData" @cancel="onCancel" v-model="modalValue" :title="t('portfolio')">
    <div>
      <div class="mt-16 flex flex-col md:flex-row md:items-center gap-8">
        <div class="w-full text-red max-w-[150px] max-h-[150px]">
          <Image :id="123" class="rounded" :src="data.File || blankImage" alt="logo" />
        </div>
        <div>
          <label for="file1-input" class="relative inline-block px-3.5 py-4 bg-primary-500 bg-opacity-5 cursor-pointer">
            <span class="text-primary-500 text-[15px] font-normal"> {{ t('chooseFile') }} </span>
            <input type="file" id="file1-input" hidden :multiple="false" @change="coverSelectFile" />
          </label>
          <p class="mt-4 font-normal text-sm text-grey-400">
            {{ t('applicantPage.coverAlert') }}
          </p>
          <p v-if="isSubmitted && v$.File.$invalid" class="text-sm text-red font-normal">{{ t('requiredField') }}</p>
        </div>
      </div>
      <div class="mt-4">
        <Textarea
          :error-text="t('requiredField')"
          :is-error="isSubmitted && v$.description.$invalid"
          :placeholder="`${t('description')}...`"
          :label="t('description')"
          class="w-full"
          v-model="data.description"
        />
      </div>
    </div>
  </Modal>
</template>
<script setup lang="ts">
  import Modal from '@/components/ui/Modal.vue';
  import { watch, ref, reactive, computed } from 'vue';
  import { required } from '@vuelidate/validators';
  import { useVuelidate } from '@vuelidate/core';
  import Textarea from '@/components/ui/Textarea.vue';
  import { createPortfolioRequest } from '@/services/applicant';
  import blankImage from '@/assets/images/blank-image.png';
  import Image from '@/components/ui/Image.vue';
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n();

  const props = defineProps<{ modal: boolean; applicantId: string }>();
  const emit = defineEmits(['updateModalValue', 'created']);

  const modalValue = ref(false);
  const isSubmitted = ref(false);
  const loading = ref(false);
  let data = reactive<any>({
    File: null,
    description: '',
  });

  const rules = computed(() => ({
    File: { required },
    description: { required },
  }));

  watch(
    () => props.modal,
    () => {
      modalValue.value = props.modal;
    }
  );

  function onCancel(value: any) {
    data = {
      File: null,
      description: '',
    };
    emit('updateModalValue', value);
  }

  function coverSelectFile(event: Event) {
    if (event.target instanceof HTMLInputElement && event.target?.files?.length) {
      data.File = event.target.files[0];
    }
  }

  function saveData() {
    isSubmitted.value = true;
    if (v$.value.$invalid) {
      v$.value.$touch();
      return;
    }

    const formData = new FormData();
    const profileKeys = Object.keys(data);
    profileKeys.forEach(key => {
      //@ts-ignore
      formData.append(key, data[key]);
    });

    loading.value = true;
    createPortfolioRequest(props.applicantId, formData)
      .then(() => {
        emit('created');
        onCancel(false);
      })
      .finally(() => {
        isSubmitted.value = false;
        loading.value = false;
      });
  }

  const v$ = useVuelidate(rules, data);
</script>

<style scoped></style>
