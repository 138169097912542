<template>
  <Teleport class="h-screen" to="#app">
    <div
      :class="[
        'fixed left-0 right-0 md:top-0 md:inset-0 z-50 flex items-center justify-center transition-all duration-300 overflow-auto',
        {
          'invisible opacity-0 pointer-events-none': !modelValue,
        },
        fullScreen ? 'top-0' : 'bottom-0',
      ]"
    >
      <div class="fixed inset-0 bg-grey-200 opacity-50" @click="closeModal" />
      <div
        :class="[
          'relative transform !rounded-lg bg-white text-left shadow-xl transition-all duration-300 w-full flex flex-col max-h-[100dvh] overflow-scroll',
          modelValue ? 'translate-y-0 md:scale-100' : 'translate-y-1/2 md:translate-y-0 md:scale-75',
          modalDialogSizes[size],
          fullScreen ? 'h-screen sm:h-auto' : 'rounded-tl-3xl md:rounded-tl-none rounded-tr-3xl md:rounded-tr-none',
        ]"
      >
        <div>
          <div class="p-[40px]">
            <div class="border-b border-grey-100 pb-8 mb-8">
              <p class="text-[22px] font-extra-medium" v-if="title">
                {{ title }}
              </p>
              <p class="text-xl" v-if="descriptionTitle">
                {{ descriptionTitle }}
              </p>
            </div>
            <slot></slot>
            <div class="flex space-x-[30px] mt-12" v-if="footerButton">
              <Button @click="cancel" variant="light" size="full">{{ cancelButtonText }}</Button>
              <Button @click="submit" size="full">{{ submitButtonText }}</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
  import { watch, onBeforeUnmount } from 'vue';
  import Button from '@/components/ui/Button.vue';

  const modalDialogSizes = {
    sm: 'max-w-[530px]',
    md: 'max-w-[630px]',
  };

  type TProps = {
    title?: string;
    descriptionTitle?: string;
    submitButtonText?: string;
    cancelButtonText?: string;
    modelValue: boolean;
    footerButton?: boolean;
    fullScreen?: boolean;
    size?: keyof typeof modalDialogSizes;
  };

  const props = withDefaults(defineProps<TProps>(), {
    size: 'md',
    modelValue: false,
    borderHeader: false,
    footerButton: true,
    submitButtonText: 'Добавить',
    cancelButtonText: 'Отменить',
  });

  const emit = defineEmits<{
    (e: 'update:modelValue', value: boolean): void;
    (e: 'cancel'): void;
    (e: 'submit'): void;
  }>();

  watch(
    () => props.modelValue,
    () => {
      const nuxtMainElement = document.body;
      if (props.modelValue && !nuxtMainElement?.classList?.contains('overflow-hidden')) {
        document.body?.classList.add('overflow-hidden');
      }

      if (!props.modelValue) {
        document.body?.classList.remove('overflow-hidden');
      }
    }
  );

  onBeforeUnmount(() => {
    closeModal();
    document.body?.classList.remove('overflow-hidden');
  });

  function closeModal() {
    emit('update:modelValue', false);
  }
  function cancel() {
    emit('cancel');
    emit('update:modelValue', false);
  }
  function submit() {
    emit('submit');
  }
</script>
