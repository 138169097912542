<template>
  <div class="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8 min-h-[80vh]">
    <h3 class="text-3xl font-medium pl-4 md:pl-0">{{ t('messagesPage.messages') }}</h3>
    <p class="text-grey-500 mt-1 pl-4 md:pl-0">{{ t('messagesPage.messagesList') }}</p>
    <div class="container mt-5 md:mt-10 md:grid grid-cols-3 gap-8 min-h-[50vh] max-h-[774px]">
      <div
        class="col-span-1 bg-white rounded-md h-full px-4 py-4 md:block md:p-10"
        :class="{ hidden: currentChat?.id }"
      >
        <div>
          <Input :placeholder="t('search')" class-name="rounded-md " v-model="query.query" />
        </div>
        <div class="flex flex-col gap-4 mt-4">
          <button
            v-for="chat in chats"
            :key="chat.id"
            class="flex cursor-pointer justify-between p-1 rounded-md visited:bg-primary-500 hover:bg-grey-50 border-grey-100"
            :class="{ 'bg-grey-50 cursor-default': currentChat?.id === chat.id }"
            @click="selectChat(chat)"
          >
            <div class="flex space-x-3 items-center">
              <div class="w-[40px] h-[40px]">
                <Image class="h-full w-full rounded-full" :src="chat.logo || userD" :alt="chat.name" />
              </div>
              <div>
                <h3 class="text-base">{{ chat.name.slice(0, 20) }}{{ chat.name.length > 20 ? '...' : '' }}</h3>
                <p v-if="chat.position" class="text-sm text-grey-200 text-start">{{ chat.position || 'N/A' }}</p>
              </div>
            </div>
            <div class="flex flex-col items-center">
              <div v-if="chat.messageCount" class="text-white rounded-full bg-primary-500 px-2">
                {{ chat.messageCount }}
              </div>
            </div>
          </button>
        </div>
      </div>
      <div
        class="md:flex flex-col col-span-2 bg-white rounded-lg relative h-full"
        :class="[!currentChat?.id ? 'hidden' : 'flex']"
      >
        <div class="flex space-x-3 items-center hover:bg-grey-50 px-4 md:px-10 py-5 border-b border-grey-100">
          <button class="md:hidden" @click="backFromSelectedChat">
            <SvgIcon name="chevron-left" />
          </button>
          <div class="w-full flex items-center space-x-3">
            <div class="!w-[50px] !h-[50px]">
              <Image
                class="!w-[50px] !h-[50px] rounded-full"
                :src="currentChat?.logo || userD"
                :alt="currentChat?.name || ''"
              />
            </div>
            <div>
              <h3 class="text-xl">
                {{ currentChat?.name.slice(0, 15) }}{{ currentChat?.name.length > 15 ? '...' : '' }}
              </h3>
            </div>
          </div>

          <button class="px-3" @click="removeChat">
            <p class="hidden md:block text-red whitespace-nowrap cursor-pointer text-right">
              {{ t('messagesPage.removeChat') }}
            </p>
            <SvgIcon class="text-red md:hidden" name="trash" />
          </button>
        </div>
        <div ref="messagesContainerRef" class="flex-1 max-h-[450px] overflow-auto flex flex-col gap-2 px-10 py-5">
          <div
            v-for="(message, index) in messages"
            :key="index"
            :class="[checkMessageAuthor(message) ? 'self-end' : 'self-start']"
          >
            <div v-if="messages[index - 1]?.authorId !== message.authorId" class="inline-flex items-center gap-2">
              <Image class="!w-[40px] !h-[40px] rounded-full" :src="message.logo || userD" alt="author image" />
              <p class="text-md font-medium text-black">
                {{ message.authorName.slice(0, 15) || 'N/A' }} {{ message.authorName?.length > 15 ? '...' : '' }}
              </p>
            </div>
            <div class="mt-1 p-1 px-2 rounded-lg bg-primary-50 flex flex-col">
              <span>{{ message.message }}</span>
              <small v-if="timeFilter(message.createdAt).includes('Invalid') === false" class="self-end">{{
                timeFilter(message.createdAt)
              }}</small>
            </div>
          </div>
        </div>
        <div class="flex w-full p-4 items-center h-32 space-x-4 border-t border-grey-100">
          <textarea
            v-model="newMessage"
            :placeholder="t('messagesPage.writeTheMessage')"
            rows="2"
            class="border-none focus:outline-none bg-white w-full p-2 text-lg"
            @keyup.enter="sendMessage"
          ></textarea>
          <button
            class="rounded-md py-2 px-4 bg-primary-500 text-white md:w-full md:!max-w-[200px]"
            @click="sendMessage"
          >
            {{ t('messagesPage.send') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { getApplicantById } from '@/services/applicant';

  const { cookies } = useCookies();
  import { useI18n } from 'vue-i18n';
  import { storeToRefs } from 'pinia';
  import { useCookies } from 'vue3-cookies';
  import { computed, onMounted, ref, watch } from 'vue';
  import { HubConnectionBuilder, HttpTransportType, HubConnection, LogLevel } from '@microsoft/signalr';

  import Input from '@/components/ui/Input.vue';
  import Image from '~/components/ui/Image.vue';
  import { useCoreStore } from '~/stores/core';
  import { getCompanyChatsRequest, getUserChatsRequest, removeChatRequest } from '~/services/message';

  import userD from '@/assets/images/userD.png';
  import { getChatMessagesRequest } from '~/services/message';
  import SvgIcon from '~/components/ui/SvgIcon.vue';
  import { debounce } from '~/utils/debounce';
  import { useRoute } from 'vue-router';
  import { getCompanyById } from '~/services/company';
  import { timeFilter } from '@/utils/filters';

  const { t } = useI18n();
  const route = useRoute();

  const coreStore = useCoreStore();
  const { profile } = storeToRefs(coreStore);

  const messagesContainerRef = ref<HTMLDivElement | null>(null);
  const connection = ref<HubConnection>();
  const chatsLoading = ref(false);
  const messagesLoading = ref(false);
  const chats = ref<any[]>([]);
  const currentChat = ref<any>(null);
  const messages = ref<any[]>([]);
  const newMessage = ref('');
  const authToken = cookies?.get('token');
  const query = ref({
    query: '',
  });

  const profileType = computed(() => {
    return cookies.get('profile-type');
  });

  watch(
    query,
    debounce(() => {
      getChatsList();
    }, 500),
    { deep: true, immediate: true }
  );

  watch(
    currentChat,
    () => {
      closeConnection();
      initSocketConnection();
    },
    { deep: true }
  );

  onMounted(async () => {
    if (route.query.companyId) {
      const { data } = await getCompanyById(route.query.companyId as string);
      currentChat.value = { id: data.payload.companyId, name: data.payload.name, logo: data.payload.logo };
    } else {
      const { data } = await getApplicantById(route.query.applicantId as string);
      currentChat.value = {
        id: data.payload.userVm.id,
        name: data.payload.userVm.name,
        logo: data.payload.userVm.photo,
      };
    }
  });

  function closeConnection() {
    if (connection.value) {
      connection.value.off('ReceiveMessage');
      connection.value.stop();
    }
  }

  function initSocketConnection() {
    if (!currentChat.value) {
      return;
    }
    const urlIds =
      profileType.value === 'company'
        ? `${currentChat.value.id}/${profile.value?.companyId}`
        : `${profile.value?.id}/${currentChat.value.id}`;
    const url = ref(`wss://api.volunteers.tj/comHub/${urlIds}`);

    connection.value = new HubConnectionBuilder()
      .configureLogging(LogLevel.Information)
      .withUrl(url.value, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
        accessTokenFactory: () => authToken,
      })
      .withAutomaticReconnect()
      .build();

    connection.value.on('ReceiveMessage', data => {
      messages.value.push(data);
      requestAnimationFrame(() => {
        if (messagesContainerRef.value?.scrollTo) {
          messagesContainerRef.value.scrollTo({
            top: messagesContainerRef.value.scrollHeight,
            left: 0,
            behavior: 'smooth',
          });
        }
      });
    });

    connection.value.start().catch(e => {
      console.error('error: ' + e);
    });
  }

  function backFromSelectedChat() {
    currentChat.value = null;
  }

  function checkMessageAuthor(message: any) {
    if (profileType.value === 'company') {
      return message.authorId === profile.value?.companyId;
    } else {
      return message.authorId === profile.value?.id;
    }
  }

  async function getChatsList() {
    chatsLoading.value = true;
    const res =
      profileType.value === 'company'
        ? await getCompanyChatsRequest(profile.value?.companyId as string, query.value)
        : await getUserChatsRequest(profile.value?.id as string, query.value);
    chats.value = res.payload;
    chatsLoading.value = false;
  }

  function sendMessage() {
    const data =
      profileType.value === 'company'
        ? {
            authorName: profile.value?.companyName,
            message: newMessage.value,
            isCompany: true,
            logo: profile.value?.logo,
          }
        : {
            authorName: `${profile.value?.name} ${profile.value?.lastName}`,
            message: newMessage.value,
            isCompany: false,
            logo: profile.value?.photo,
          };
    connection.value?.invoke('SendMessage', data);
    newMessage.value = '';
  }
  async function selectChat(chat: any) {
    if (currentChat.value?.id === chat?.id) {
      return;
    }
    currentChat.value = chat;
    await getChatMessages();
    getChatsList();
  }

  async function getChatMessages() {
    messagesLoading.value = true;
    const res =
      profileType.value === 'company'
        ? await getChatMessagesRequest(profile.value?.companyId as string, currentChat.value.id)
        : await getChatMessagesRequest(currentChat.value.id, profile.value?.id as string);

    messages.value = res.payload;
    requestAnimationFrame(() => {
      if (messagesContainerRef.value?.scrollTo) {
        messagesContainerRef.value.scrollTo({
          top: messagesContainerRef.value.scrollHeight,
          left: 0,
          behavior: 'instant',
        });
      }
    });
    messagesLoading.value = false;
  }

  async function removeChat() {
    const companyId = profileType.value === 'company' ? profile.value?.companyId : currentChat.value.id;
    const userId = profileType.value === 'user' ? profile.value?.id : currentChat.value?.id;
    await removeChatRequest(companyId, userId);
    currentChat.value = null;
    getChatsList();
  }
</script>
