import type { IAlert } from '@/types/alerts';
import { apiClient } from './apiClient';
import { type TResponse } from '@/types/global';

export type TGetAlertsResponse = {
  'page': 0;
  'size': 0;
  'totalCount': 0;
  'totalPages': 0;
  items: IAlert[];
};

export type TGetNotificationsResponse = {
  'id': string;
  'referenceId': string;
  'titleTj': string;
  'titleRu': string;
  'bodyTj': string;
  'bodyRu': string;
  'descriptionTj': string;
  'descriptionRu': string;
  'isDelete': true;
  'createdAt': string;
};

export const getCompanyAlertsRequest = async (companyId: string, params?: any) => {
  const { data } = await apiClient.get<TResponse<TGetAlertsResponse>>(`alerts/companies/${companyId}`, { params });
  return data;
};

export const getApplicantAlertsRequest = async (applicantId: string, params?: any) => {
  const { data } = await apiClient.get<TResponse<TGetAlertsResponse>>(`alerts/applicant/${applicantId}`, { params });
  return data;
};

export const deleteAlertRequest = (alertId: string) => apiClient.delete(`alerts/${alertId}`);
export const deleteApplicantAlertRequest = (alertId: string) => apiClient.delete(`alerts/applicant-alert/${alertId}`);

export const getNotifications = async (id: string) => {
  const { data } = await apiClient.get<TResponse<TGetNotificationsResponse[]>>(`notifications/${id}`);
  return data;
};

export const getNotificationRequest = async (id: string) => {
  const { data } = await apiClient.get<TResponse<TGetNotificationsResponse[]>>(`notifications/show/${id}`);
  return data;
};

export const markNotificationsAsRead = async (params: { ids: string[] }) => {
  const { data } = await apiClient.put<TResponse<TGetNotificationsResponse[]>>(`notifications`, params);
  return data;
};
