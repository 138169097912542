<template>
  <main class="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
    <h3 class="text-3xl font-medium">{{ t('applicantPage.CVManager') }}</h3>
    <section class="bg-white px-8 py-10 mt-8 rounded">
      <div class="flex justify-center" v-if="getLoading">
        <Spinner />
      </div>
      <div v-else>
        <p>{{ t('applicantPage.CVManager') }}</p>
        <FileUploader class="mt-8" v-model="files" accept=".doc,.pdf,.docx" />
        <Button class="mt-8" :disabled="!files.length" @click="saveData" :loading="loading">{{ t('save') }}</Button>
        <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-10">
          <div class="grid-cols-5" v-for="(CVManager, index) in CVManagers" :key="CVManager">
            <div class="relative rounded mt-5 w-[190px] h-[167px] bg-grey-50 flex items-center justify-center">
              <div class="absolute flex flex-col gap-2 items-center">
                {{ 'CV ' + (index + 1) }}
                <div class="text-white flex gap-2">
                  <div
                    @click="deleteCV(CVManager)"
                    class="cursor-pointer px-2 py-2 bg-white rounded-md text-primary-500"
                  >
                    <SvgIcon name="trash2" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <UIModal
      @cancel="deleteModal = false"
      :title="t('deletion')"
      :submitButtonText="t('delete')"
      @submit="deleteElementHandler"
      v-model="deleteModal"
    >
      <p>
        {{ t('doYouReallyWantToDelete') }}
        <strong>CV</strong>
        ?
      </p>
    </UIModal>
  </main>
</template>
<script setup lang="ts">
  import Spinner from '@/components/ui/Spinner.vue';

  import { computed, ref, watch } from 'vue';
  import FileUploader from '@/components/ui/FileUploader.vue';
  import Button from '@/components/ui/Button.vue';
  import {
    createCVManagerRequest,
    deleteCVManagerRequest,
    getApplicantByProfileId,
    getCVManagersRequest,
  } from '@/services/applicant';
  import { useCoreStore } from '@/stores/core';
  import SvgIcon from '@/components/ui/SvgIcon.vue';
  import UIModal from '@/components/ui/Modal.vue';
  import { toast } from 'vue3-toastify';
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n();

  const profile = computed(() => {
    return useCoreStore().profile;
  });

  const applicant = ref<any>(null);
  const CVManagers = ref<any>(null);
  const selectedElement = ref<any>(null);

  const files = ref([]);
  const loading = ref(false);
  const getLoading = ref(false);
  const deleteModal = ref(false);
  function saveData() {
    const formData = new FormData();
    formData.append('File', files.value[0]);

    loading.value = true;
    createCVManagerRequest(applicant.value?.id || '', formData)
      .then(() => {
        toast.success(t('successfullySaved'));
        getCVManagersHandler(applicant?.value?.id || '');
      })
      .finally(() => {
        loading.value = false;
      });
  }

  function getApplicantHandler(id: string) {
    getLoading.value = true;
    getApplicantByProfileId(id)
      .then(response => {
        applicant.value = response.data.payload;
      })
      .finally(() => {
        getCVManagersHandler(applicant?.value?.id || '');
      });
  }
  function getCVManagersHandler(id: string) {
    getLoading.value = true;
    getCVManagersRequest(id)
      .then(response => {
        CVManagers.value = response.payload;
      })
      .finally(() => {
        getLoading.value = false;
      });
  }

  watch(
    () => profile,
    () => {
      if (!profile?.value?.profileId) return;
      getApplicantHandler(profile?.value?.profileId);
    },
    { immediate: true, deep: true }
  );

  function deleteCV(data: any) {
    selectedElement.value = data;
    deleteModal.value = true;
  }

  function deleteElementHandler() {
    loading.value = true;

    deleteCVManagerRequest(selectedElement?.value?.id || '')
      .then(() => {
        toast.success(t('successfullyDeleted'));
        getCVManagersHandler(applicant?.value?.id || '');
        deleteModal.value = false;
      })
      .finally(() => (loading.value = false));
  }
</script>

<style scoped></style>
