<template>
  <div class="h-full">
    <div class="py-[50px] bg-slate-100">
      <h1 class="text-3xl font-medium text-center">{{ t('global.aboutUs') }}</h1>
      <div class="text-center pt-4">
        <RouterLink to="/" class="text-md">{{ t('global.home') }}</RouterLink> /
        <span>{{ t('global.aboutUs') }}</span>
      </div>
    </div>
    <div class="flex justify-center flex-col items-center">
      <div class="max-w-[1290px] w-full">
        <img src="@/assets/images/about-us.png" alt="about" class="py-[40px]" />
        <div class="flex flex-col items-center">
          <div class="w-full max-w-[860px] py-[50px] md:px-0 px-6">
            <p class="text-3xl font-medium mb-6">{{ t('aboutPage.aboutKorTj') }}</p>
            <p class="text-grey-400 text-md mb-6bg_shape.png">
              {{ t('aboutPage.description') }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-center pt-[80px] bg-white">
      <div class="max-w-[1240px] w-full md:grid md:grid-cols-2 flex flex-col md:p-0 px-6 gap-20">
        <div>
          <h3 class="text-3xl font-medium mb-4">{{ t('aboutPage.contactUs') }}</h3>
          <p class="text-grey-500 mb-6">{{ t('aboutPage.contactDescription') }}</p>
          <div class="">
            <Input
              :disabled="loading"
              class="w-full mb-3"
              v-model="data.name"
              :placeholder="t('contactsPage.yourName')"
              :error-text="t('requiredField')"
              :is-error="isSubmitted && v$.name.$invalid"
            />
            <Input
              :disabled="loading"
              class="w-full mb-3"
              v-model="data.phone"
              :placeholder="t('contactsPage.yourPhoneNumber')"
              :error-text="t('requiredField')"
              :is-error="isSubmitted && v$.phone.$invalid"
            />
            <Input
              :disabled="loading"
              class="w-full mb-3"
              v-model="data.topic"
              :placeholder="t('contactsPage.theme')"
              :error-text="t('requiredField')"
              :is-error="isSubmitted && v$.topic.$invalid"
            />
            <Textarea
              :disabled="loading"
              class="w-full mb-3"
              v-model="data.text"
              :placeholder="t('contactsPage.messageText')"
              :error-text="t('requiredField')"
              :is-error="isSubmitted && v$.text.$invalid"
            />

            <Button @click="saveData" :loading="loading" class="mt-8">{{ t('sendMessage') }}</Button>
          </div>
        </div>
        <div>
          <h3 class="text-3xl font-medium mb-16 text-center text-primary-500">
            {{ t('aboutPage.mapTitle') }}
          </h3>
          <GoogleMap
            api-key="AIzaSyB8bFrSy2jA0gZkmf9SUycVL26Lc00_UOI"
            style="width: 100%; height: 500px"
            :center="{ lat: 38.58651701265714, lng: 68.7530738675009 }"
            :zoom="15"
          >
            <Marker id="marker" :options="{ position: { lat: 38.58651701265714, lng: 68.7530738675009 } }" />
          </GoogleMap>
        </div>
      </div>
    </div>
    <div class="flex justify-center bg-white pb-[80px]">
      <div class="w-full flex justify-center items-center">
        <div class="bg-white p-8 grid md:grid-cols-3 grid-cols-1 gap-10">
          <div class="flex flex-col items-center gap-5">
            <SvgIcon class="text-primary-500" name="contactLocation" />
            <p>{{ t('address') }}</p>
            <p class="text-sm text-grey-200">{{ t('aboutPage.address') }}</p>
          </div>
          <div class="flex flex-col items-center gap-5">
            <SvgIcon class="text-primary-500" name="Smartphone" />
            <p>{{ t('contactsPage.callUs') }}</p>
            <p class="text-sm text-primary-500">+992 (37) 250 32-59</p>
          </div>
          <div class="flex flex-col items-center gap-5">
            <letter />
            <p>Email</p>
            <p class="text-sm text-grey-200">info@kor.tj</p>
          </div>
        </div>
      </div>
    </div>
    <PartnersSection />
  </div>
</template>

<script setup lang="ts">
  import letter from '@/components/icons/letter.vue';
  import PartnersSection from '@/components/home/PartnersSection.vue';
  import { GoogleMap, Marker } from 'vue3-google-map';
  import { useI18n } from 'vue-i18n';
  import Textarea from '@/components/ui/Textarea.vue';
  import Input from '@/components/ui/Input.vue';
  import Button from '@/components/ui/Button.vue';
  import { computed, reactive, ref } from 'vue';
  import { required } from '@vuelidate/validators';
  import { createMessage } from '@/services/messages';
  import { toast } from 'vue3-toastify';
  import { useVuelidate } from '@vuelidate/core';
  import SvgIcon from '@/components/ui/SvgIcon.vue';
  const { t } = useI18n();

  const isSubmitted = ref(false);
  const loading = ref(false);

  const rules = computed(() => ({
    name: { required },
    phone: { required },
    topic: { required },
    text: { required },
  }));

  const data = reactive({
    name: '',
    phone: '',
    topic: '',
    text: '',
  });

  const saveData = () => {
    isSubmitted.value = true;
    if (v$.value.$invalid) {
      v$.value.$touch();
      return;
    }
    loading.value = true;

    createMessage(data)
      .then(() => {
        toast.success(`${t('companiesPage.messageSent')}!`);
        data.name = '';
        data.phone = '';
        data.topic = '';
        data.text = '';
      })
      .finally(() => {
        isSubmitted.value = false;
        loading.value = false;
      });
  };

  const v$ = useVuelidate(rules, data);
</script>
