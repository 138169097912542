import { apiClient } from './apiClient';

export const getUserChatsRequest = async (userId: string, params?: Record<string, any>) => {
  const { data } = await apiClient.get(`messages/users/${userId}/companies`, { params });
  return data;
};

export const getCompanyChatsRequest = async (companyId: string, params?: Record<string, any>) => {
  const { data } = await apiClient.get(`messages/companies/${companyId}/users`, { params });
  return data;
};

export const getChatMessagesRequest = async (companyId: string, userId: string) => {
  const { data } = await apiClient.get(`messages/companies/${companyId}/users/${userId}`);
  return data;
};

export const removeChatRequest = async (companyId: string, userId: string) => {
  const { data } = await apiClient.delete(`messages/companies/${companyId}/users/${userId}`);
  return data;
};
