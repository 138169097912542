<template>
  <Modal
    @submit="saveData"
    @cancel="onCancel"
    :cancel-button-text="t('globalTs.cancel')"
    :submit-button-text="t('add')"
    v-model="modalValue"
    :title="t('skills')"
  >
    <div class="flex justify-center mt-4" v-if="getLoading">
      <Spinner />
    </div>
    <div v-else>
      <Multiselect
        v-model="data.professionalSkillIds"
        :items="skills"
        :label="t('skills')"
        :placeholder="t('skills')"
        class="w-full mt-4"
        :is-error="isSubmitted && v$.professionalSkillIds.$invalid"
        :errorText="t('requiredField')"
      />
    </div>
  </Modal>
</template>
<script setup lang="ts">
  import Modal from '@/components/ui/Modal.vue';
  import { watch, ref, reactive, computed, onMounted } from 'vue';
  import { required } from '@vuelidate/validators';
  import { useVuelidate } from '@vuelidate/core';
  import Spinner from '@/components/ui/Spinner.vue';
  import { createSkillRequest, getSkillsRequest } from '@/services/applicant';
  import Multiselect from '@/components/ui/Multiselect.vue';
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n();

  const props = defineProps<{ modal: boolean; applicantId: string }>();
  const emit = defineEmits(['updateModalValue', 'created']);

  const modalValue = ref(false);
  const skills = ref<any>([]);
  const getLoading = ref(false);
  const isSubmitted = ref(false);
  const loading = ref(false);
  let data = reactive({
    professionalSkillIds: [],
  });

  const rules = computed(() => ({
    professionalSkillIds: { required },
  }));

  watch(
    () => props.modal,
    () => {
      modalValue.value = props.modal;
    }
  );

  function onCancel(value: any) {
    data = {
      professionalSkillIds: [],
    };
    emit('updateModalValue', value);
  }

  function saveData() {
    isSubmitted.value = true;
    if (v$.value.$invalid) {
      v$.value.$touch();
      return;
    }

    loading.value = true;
    createSkillRequest(props.applicantId, data)
      .then(() => {
        emit('created');
        onCancel(false);
      })
      .finally(() => {
        isSubmitted.value = false;
        loading.value = false;
      });
  }

  const getSkillsHandler = () => {
    getLoading.value = true;
    getSkillsRequest()
      .then(response => {
        response &&
          response.payload.forEach((item: any) => {
            skills.value.push({ value: item?.id, title: item?.nameTj });
          });
      })
      .finally(() => (getLoading.value = false));
  };

  onMounted(() => {
    getSkillsHandler();
  });

  const v$ = useVuelidate(rules, data);
</script>

<style scoped></style>
