<template>
  <div class="w-[650px] bg-white md:my-24 md:p-10 p-4 rounded">
    <div class="flex justify-between">
      <h3 class="text-2xl">{{ t('authPage.applicantRegistration') }}</h3>
      <SvgIcon name="user" />
    </div>
    <hr v-if="step !== 4" class="text-grey-50 my-12" />
    <div v-if="step === 1">
      <div class="flex justify-between">
        <p class="font-extra-medium">{{ t('authPage.yourPhoneNumber') }}</p>
        <p class="text-grey-100 text-sm">{{ t('authPage.stepFromTo', { from: 1, to: 3 }) }}</p>
      </div>
      <div class="mb-4 mt-8">
        <Input
          inputMode="tel"
          :disabled="loading"
          :isError="step === 1 && isSubmitted && phoneInvalid"
          :error-text="`${t('requiredField')} (${t('format')}: +992ххххххххх)`"
          v-model="phone"
          :label="t('phoneNumber')"
          placeholder="+992"
          type="tel"
        />
      </div>
      <hr class="text-grey-50 my-12" />
      <div class="flex gap-6">
        <Button :disabled="loading" @click="$emit('toSelectType')" size="full" variant="light">{{ t('back') }}</Button>
        <Button :loading="loading" :disabled="loading" @click="submitStepOne" size="full" variant="primary">
          {{ t('continue') }}
        </Button>
      </div>
    </div>
    <div v-if="step === 2">
      <div class="flex justify-between">
        <p class="font-extra-medium">{{ t('authPage.confirmPhoneNumber') }}</p>
        <p class="text-grey-100 text-sm">{{ t('authPage.stepFromTo', { from: 2, to: 3 }) }}</p>
      </div>
      <div class="mb-8 mt-8">
        <Input
          :disabled="loading"
          :isError="step === 2 && isSubmitted && otpInvalid"
          :error-text="t('requiredField')"
          v-model="otp"
          :label="t('enterSentCode')"
          placeholder="1111"
          type="number"
        />
      </div>
      <div>
        <p v-if="attemptsNumber >= 3 && !timer" class="text-red">
          {{ t('authPage.yourUsedAllAttempts') }}
        </p>
        <p v-else-if="!!timer">{{ t('authPage.codeExpiresIn') }} {{ formattedTime }}</p>
        <div class="flex justify-between" v-else-if="!loading">
          <p class="text-red">{{ t('authPage.codeExpired') }}</p>
          <p class="cursor-pointer text-primary-500" @click="sendOtpHandler">{{ t('authPage.resendCode') }}</p>
        </div>
      </div>
      <hr class="text-grey-50 my-12" />
      <div class="flex gap-6">
        <Button :disabled="loading" @click="step = 1" size="full" variant="light">{{ t('back') }}</Button>
        <Button :loading="loading" :disabled="loading" @click="submitStepTwo" size="full" variant="primary">
          {{ t('continue') }}
        </Button>
      </div>
    </div>
    <div v-if="step === 3">
      <div class="flex justify-between">
        <p class="font-extra-medium">{{ t('authPage.personalData') }}</p>
        <p class="text-grey-100 text-sm">{{ t('authPage.stepFromTo', { from: 3, to: 3 }) }}</p>
      </div>
      <div class="mt-8">
        <div class="mb-4">
          <Input
            :disabled="loading"
            :isError="step === 3 && isSubmitted && v$.name.$invalid"
            :error-text="t('requiredField')"
            v-model="userData.name"
            :label="t('name')"
            placeholder="Иван"
          />
        </div>
        <div class="mb-4">
          <Input
            :disabled="loading"
            :isError="step === 3 && isSubmitted && v$.lastName.$invalid"
            :error-text="t('requiredField')"
            v-model="userData.lastName"
            :label="t('lastName')"
            placeholder="Иванов"
          />
        </div>
        <div class="mb-4">
          <Select
            :items="[
              { value: 1, title: t('male') },
              { value: 2, title: t('female') },
            ]"
            :disabled="loading"
            :isError="step === 3 && isSubmitted && v$.ganderId.$invalid"
            :error-text="t('requiredField')"
            v-model="userData.ganderId"
            :label="t('gender')"
            :placeholder="t('choose')"
          />
        </div>
        <div class="mb-4">
          <label class="block text-[15px] text-black font-normal mb-1.5"> {{ t('birthDate') }} </label>
          <DatePicker auto-apply format="dd.MM.yyyy" placeholder="05.05.1998" v-model="userData.birthDate" />
          <p class="mt-1 text-red text-sm" v-if="step === 3 && isSubmitted && v$.birthDate.$invalid">
            {{ t('authPage.requiredFieldAgeCantBeLessThane16') }}
          </p>
        </div>
        <div class="mb-4">
          <Input
            :disabled="loading"
            :isError="step === 3 && isSubmitted && v$.address.$invalid"
            :error-text="t('requireField')"
            v-model="userData.address"
            :label="t('address')"
            placeholder="ш. Душанбе, Сино, Алишер Навои 21/2"
          />
        </div>
      </div>
      <hr class="text-grey-50 my-12" />
      <div class="flex gap-6">
        <Button @click="step = 2" size="full" variant="light">{{ t('back') }}</Button>
        <Button :disabled="loading" @click="submitStepThree" size="full" variant="primary">{{ t('continue') }}</Button>
      </div>
    </div>
    <div v-if="step === 4">
      <div class="flex items-center justify-center">
        <div class="p-10 rounded-full bg-grey-50">
          <SvgIcon class="text-primary-500" name="check" />
        </div>
      </div>
      <div class="mt-8 text-center text-2xl">{{ t('authPage.accountSuccessfullyCreated') }}</div>
      <p class="text-grey-200 text-center">{{ t('authPage.nowYouCanLookForAJob') }}</p>
      <hr class="text-grey-50 my-12" />
      <div class="flex gap-6">
        <Button @click="router.push({ name: 'home' })" size="full" variant="softPrimary"> {{ t('great') }} </Button>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import SvgIcon from '@/components/ui/SvgIcon.vue';
  import Input from '@/components/ui/Input.vue';
  import Select from '@/components/ui/Select.vue';
  import { computed, ref } from 'vue';
  import { required } from '@vuelidate/validators';
  import { useVuelidate } from '@vuelidate/core';
  import Button from '@/components/ui/Button.vue';
  import { createApplicantUser, registrationApplicant, verifyApplicant } from '@/services/auth';
  import { useCookies } from 'vue3-cookies';
  import { useRouter } from 'vue-router';
  import { minBirthDate } from '@/utils/dateValidation';
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n();

  defineEmits(['toSelectType']);
  const { cookies } = useCookies();
  const router = useRouter();
  const loading = ref(false);
  const isSubmitted = ref(false);
  const otp = ref('');
  const timer = ref(240);
  const attemptsNumber = ref(1);
  const phone = ref('+992');
  const phoneInvalid = ref(false);
  const otpInvalid = ref(false);
  const userId = ref('');
  const userData = ref({
    name: '',
    lastName: '',
    ganderId: 0,
    birthDate: '',
    address: '',
  });

  const step = ref(1);

  const rules = computed(() => ({
    name: { required },
    lastName: { required },
    ganderId: { required },
    birthDate: { required, minBirthDate },
    address: { required },
  }));

  const formattedTime = computed(() => {
    const minutes = Math.floor(timer.value / 60);
    const seconds = timer.value % 60;
    //@ts-ignore
    const formattedMinutes = String(minutes).padStart(2, '0');
    //@ts-ignore
    const formattedSeconds = String(seconds).padStart(2, '0');

    return `${formattedMinutes}:${formattedSeconds}`;
  });

  const submitStepOne = () => {
    isSubmitted.value = true;
    if (!phone.value.match('(\\+992)([0-9]{9}$)')) {
      phoneInvalid.value = true;
      return;
    }

    loading.value = true;
    registrationApplicant({ phone: phone.value })
      .then(() => {
        step.value = 2;
        startOtpTimer();
      })
      .finally(() => {
        isSubmitted.value = false;
        loading.value = false;
      });
  };
  const submitStepTwo = () => {
    isSubmitted.value = true;
    if (!otp.value) {
      otpInvalid.value = true;
    }

    loading.value = true;
    verifyApplicant({ phone: phone.value, code: otp.value })
      .then(response => {
        userId.value = response.data.payload.id || '';
        const token = response.data.payload.token || '';
        cookies.set('token', token);
        step.value = 3;
      })
      .finally(() => {
        isSubmitted.value = false;
        loading.value = false;
      });
  };
  const submitStepThree = () => {
    isSubmitted.value = true;
    if (v$.value.$invalid) {
      v$.value.$touch();
      return;
    }
    loading.value = true;
    createApplicantUser(userId.value, userData.value)
      .then(() => {
        step.value = 4;
        router.push({ name: 'home' });
        window.location.reload();
      })
      .finally(() => {
        isSubmitted.value = false;
        loading.value = false;
      });
  };

  const sendOtpHandler = () => {
    attemptsNumber.value++;
    submitStepOne();
  };

  const startOtpTimer = () => {
    if (!timer.value) timer.value = 119;
    const timerInterval = setInterval(function () {
      timer.value--;
      if (timer.value === 0) {
        clearInterval(timerInterval);
      }
    }, 1000);
  };

  const v$ = useVuelidate(rules, userData);
</script>

<style scoped></style>
