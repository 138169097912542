<template>
  <img :src="source" :alt="alt" class="w-full h-full object-cover" />
</template>

<script setup lang="ts">
  import { ref, watch } from 'vue';

  type TProps = {
    src: string | File;
    alt: string;
  };
  const props = defineProps<TProps>();

  const source = ref<string>('');

  watch(
    () => props.src,
    () => {
      actualizeSource();
    },
    { immediate: true }
  );

  async function actualizeSource() {
    if (props.src instanceof File) {
      const reader = new FileReader();
      reader.onload = e => {
        source.value = e.target?.result as string;
      };
      await reader.readAsDataURL(props.src);
      return;
    }
    source.value = props.src;
  }
</script>
