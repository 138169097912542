<template>
  <div class="bg-[#101843]">
    <div class="mx-auto max-w-7xl pt-10 md:pt-[72px] px-4 sm:px-6 lg:px-8">
      <div class="md:flex justify-between">
        <div class="flex flex-col items-start md:items-center gap-5 w-[200px] mb-5 md:mb-0">
          <img src="@/assets/images/undp-2.png" alt="undp" />
          <p class="text-white text-xl md:text-center">{{ t('global.undp') }}</p>
        </div>
        <div>
          <img src="@/assets/images/logo-white.png" class="h-[50px] mb-8" alt="logo" />
          <div>
            <router-link class="text-white block mb-4" to="/"> {{ t('global.home') }} </router-link>
            <router-link class="text-white block mb-4" to="/news"> {{ t('global.news') }} </router-link>
            <router-link class="text-white block mb-4" to="/contacts"> {{ t('globalTs.contacts') }} </router-link>
            <router-link class="text-white block mb-4" to="/about-us"> {{ t('global.aboutUs') }} </router-link>
            <router-link class="text-white block mb-4" to="/user-agreements">
              {{ t('global.userAgreement') }}
            </router-link>
          </div>
        </div>
        <div>
          <a href="tel:+9922503252" class="text-white mb-4">(+992) 250 32 52</a>
          <p class="text-white block mb-4">Душанбе, А. Навои 5/1</p>
          <p class="text-white block mb-4">info@kor.tj</p>
        </div>
        <div>
          <p class="text-white block mb-4">{{ t('global.mobileApp') }}</p>
          <div class="flex space-x-4 mb-10">
            <a target="_blank" href="https://apps.apple.com/tj/app/kor-tj/id6478083890">
              <img src="@/assets/images/app-store.png" class="h-[40px]" alt="logo" />
            </a>
            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.kor.tj">
              <img src="@/assets/images/google-play.png" class="h-[40px]" alt="logo" />
            </a>
          </div>
          <p class="text-white block mb-4">{{ t('global.socialMedia') }}</p>
          <div class="text-white flex space-x-6">
            <SvgIcon name="facebook" />
            <SvgIcon name="youtube" />
            <SvgIcon name="instagram" />
            <SvgIcon name="twitter" />
          </div>
        </div>
      </div>
      <div class="border-t border-grey-50 pt-8 pb-4 mt-6">
        <p class="text-white text-center">
          © {{ new Date().getFullYear() }} KOR.TJ {{ t('global.allRightsReserved') }}
        </p>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import SvgIcon from '@/components/ui/SvgIcon.vue';
  import { useI18n } from 'vue-i18n';
  const { t } = useI18n();
</script>
