<template>
  <main>
    <section class="flex flex-col justify-center bg-[#F5F7FC] py-12">
      <div class="text-center">
        <h1 class="text-3xl font-medium text-black">{{ t('global.companies') }}</h1>
        <p class="text-[15px] font-normal text-grey-400">{{ t('global.home') }} / {{ t('global.companies') }}</p>
      </div>
    </section>
    <section class="md:px-52 md:py-12 px-6 py-6 grid grid-cols-1 md:grid-cols-12 gap-12 mb-3 bg-white">
      <div
        :class="{ 'hidden': !isShowFilters }"
        class="md:block col-span-4 absolute md:relative top-0 left-0 right-0 bottom-0 h-[90vh]"
      >
        <div class="p-8 rounded-lg bg-[#F5F7FC] flex flex-col gap-8">
          <div>
            <p class="text-lg font-medium text-black">{{ t('global.keywordSearch') }}</p>
            <div class="mt-1">
              <Input iswhite v-model="query.query" :placeholder="t('keywordSearchPlaceholder')" />
            </div>
          </div>
          <div>
            <p class="font-medium">{{ t('companiesPage.companyIndustry') }}</p>
            <Select
              is-white
              v-model="industry"
              class="mt-1"
              :items="industries"
              :placeholder="`${t('companiesPage.chooseCompanyIndustry')}...`"
            />
          </div>
          <div>
            <p class="font-medium">{{ t('companiesPage.companySphere') }}</p>
            <Select
              is-white
              v-model="query.sphereId"
              :items="spheres"
              class="mt-1"
              :placeholder="`${t('companiesPage.chooseCompanySphere')}...`"
            />
          </div>
          <div>
            <p class="font-medium">{{ t('region') }}</p>
            <Select
              :placeholder="t('selectRegion')"
              is-white
              :items="[{ title: t('all'), value: null }, ...regions]"
              v-model="regionId"
              @update:model-value="getCitiesHandler"
              class="mt-1"
            />
          </div>
          <div>
            <p class="font-medium">{{ t('regionCity') }}</p>
            <Select
              :placeholder="t('regionCityPlaceholder')"
              is-white
              :items="[{ title: t('all'), value: null }, ...cities]"
              v-model="query.cityId"
              class="mt-1"
            />
          </div>
          <div>
            <p class="font-medium">{{ t('companySize') }}</p>
            <Select
              is-white
              v-model="query.employers"
              :items="employers"
              :placeholder="t('companySize')"
              class="mt-1"
            />
          </div>

          <div>
            <p class="text-lg font-medium text-black">{{ t('global.createdDate') }}</p>
            <div class="flex items-center gap-5 mt-1">
              <DatePicker
                year-picker
                :start-date="new Date('01.01.1970')"
                auto-apply
                placeholder="2024"
                format="yyyy"
                :max-date="new Date()"
                v-model="query.CreatedAt"
              />
            </div>
          </div>
        </div>
        <div
          @click="isShowFilters = false"
          class="text-center mt-6 mx-6 left-4 right-4 bg-primary-500 text-white rounded-md mb-4 py-2 md:hidden"
        >
          {{ t('accept') }}
        </div>
      </div>
      <div class="md:col-span-8">
        <div class="h-64 flex justify-center items-center" v-if="loading">
          <Spinner />
        </div>
        <div v-else-if="companies.length" class="flex flex-col gap-8">
          <div>
            <button
              class="flex md:hidden space-x-2 items-center px-4 py-1 rounded-md bg-primary-400 cursor-pointer hover:bg-primary-500 hover:text-white"
              @click="isShowFilters = !isShowFilters"
            >
              <SvgIcon name="setting" />
              <span>{{ t('global.filters') }}</span>
            </button>
          </div>
          <div v-for="company in companies" :key="company.companyId">
            <RouterLink
              :to="{ name: 'company-detail', params: { id: company.companyId } }"
              class="block p-8 rounded-lg bg-white border border-[#ECEDF2]"
            >
              <div class="flex items-center justify-between gap-3 flex-wrap max-w-full">
                <div class="flex items-center gap-5">
                  <img
                    :src="company.logo || userD"
                    :alt="company.name"
                    class="w-[51px] h-[51px] rounded-full overflow-hidden"
                  />
                  <div>
                    <p class="text-lg font-medium text-black">
                      {{ company.name.length > 30 ? company.name.slice(0, 30) + '...' : company.name }}
                    </p>
                    <div class="flex items-center gap-8 mt-1.5">
                      <div class="flex items-center gap-2.5">
                        <SvgIcon name="location-marker" class="text-grey-400" />
                        <span class="text-sm font-normal text-grey-400">
                          {{ company.region[`name${localePostfix}`] }}
                        </span>
                      </div>
                      <div class="flex items-center gap-2.5">
                        <SvgIcon name="briefcase-outline" class="text-grey-400" />
                        <span class="text-sm font-normal text-grey-400">
                          {{ company.industry[`name${localePostfix}`] }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex items-center gap-4">
                  <div class="px-5 py-1 rounded-full bg-[#1967D2] text-white">
                    {{ t('companiesPage.openPositionsCount', { count: company.vacancyCount || 0 }) }}
                  </div>
                </div>
              </div>
              <p class="text-sm font-normal text-grey-400 mt-5 truncate">
                {{ company.description }}
              </p>
            </RouterLink>
          </div>
        </div>
        <div v-else>
          <p class="mt-9 text-xl font-medium text-black text-center">{{ t('global.notFound') }}</p>
        </div>
        <div class="mt-11">
          <Pagination
            v-model:modelValue="query.page"
            v-model:pageSize="query.size"
            :totalPage="meta.totalPages"
            :totalCount="meta.totalCount"
          />
        </div>
      </div>
    </section>
  </main>
</template>

<script setup lang="ts">
  import { computed, ref, watch } from 'vue';
  import { useRoute, useRouter } from 'vue-router';

  import type { TItem } from '~/types/global';
  import type { ICompany } from '~/types/company';
  import { getCompaniesRequest, getCompanyScopes } from '~/services/company';
  import SvgIcon from '~/components/ui/SvgIcon.vue';
  import Pagination from '~/components/ui/Pagination.vue';
  import Input from '~/components/ui/Input.vue';
  import Select from '~/components/ui/Select.vue';
  import { debounce } from '@/utils/debounce';
  import { getRegions } from '~/services/regions';
  import { clearQueryParams } from '~/utils/common';
  import userD from '@/assets/images/userD.png';
  import { useI18n } from 'vue-i18n';
  import Spinner from '@/components/ui/Spinner.vue';
  import { getIndustriesRequest } from '@/services/industries';

  const route = useRoute();
  const router = useRouter();

  const { t, locale } = useI18n();

  const employers = [
    { title: '10-20', value: '10-20' },
    { title: '21-50', value: '21-50' },
    { title: '51-100', value: '51-100' },
    { title: '101-1000', value: '101-1000' },
    { title: '1000+', value: '1000+' },
  ];

  const industry = ref('');

  const query = ref({
    page: 1,
    size: 10,
    query: '',
    cityId: '',
    sphereId: '',
    CreatedAt: '',
    employers: '',
  });
  const loading = ref(false);
  const companies = ref<ICompany[]>([]);
  const industries = ref<TItem[]>([]);
  const spheres = ref<TItem[]>([]);
  const regions = ref<TItem[]>([]);
  const cities = ref<any[]>([]);
  const allRegions = ref<any[]>([]);
  const regionId = ref('');
  const meta = ref({
    totalCount: 0,
    totalPages: 0,
  });
  const isShowFilters = ref(false);

  const localePostfix = computed(() => {
    return locale.value === 'ru' ? 'Ru' : locale.value === 'en' ? 'En' : 'Tj';
  });

  getDataForFilters();

  watch(
    query,
    debounce(() => {
      router.push({
        name: route.name || undefined,
        params: route.params,
        query: {
          ...route.query,
          ...query.value,
        },
      });
    }, 500),
    { deep: true }
  );

  watch(
    () => route.query,
    () => {
      getCompanies();
    },
    { immediate: true, deep: true }
  );

  const getCompanySpheres = async () => {
    try {
      spheres.value = [];
      const { data } = await getCompanyScopes({ industry: industry.value });
      data.payload?.items?.forEach((item: any) => {
        spheres.value.push({ value: item.id, title: item[`name${localePostfix.value}`] });
      });
    } catch (e) {
      console.error(e);
    }
  };

  const getCitiesHandler = () => {
    if (!regionId.value) return;
    cities.value = [];
    allRegions.value.forEach(region => {
      if (region.parentId === regionId.value) {
        cities.value.push({
          value: region.id,
          title: region[`name${localePostfix.value}`],
        });
      }
    });
  };

  watch(
    () => industry.value,
    () => {
      getCompanySpheres();
    },
    { immediate: true }
  );

  async function getCompanies() {
    loading.value = true;
    const res = await getCompaniesRequest(clearQueryParams(route.query));
    companies.value = res.payload.items;
    meta.value.totalCount = res.payload.totalCount;
    meta.value.totalPages = res.payload.totalPages;
    loading.value = false;
  }

  async function getDataForFilters() {
    //@ts-ignore
    Promise.allSettled([getIndustriesRequest(), getRegions()]).then(responses => {
      if (responses[0].status === 'fulfilled') {
        responses[0]?.value?.payload?.items?.forEach(item => {
          industries.value.push({ value: item.id, title: item[`name${localePostfix.value}`] });
        });
      }
      if (responses[1].status === 'fulfilled') {
        //@ts-ignore
        allRegions.value = responses[1].value?.data?.payload?.items || [];
        getCitiesHandler();
        responses[1].value?.data?.payload?.items?.forEach((item: any) => {
          if (!item.parentId) {
            regions.value.push({
              value: item.id,
              title: item[`name${localePostfix.value}`],
            });
          }
        });
      }
    });
  }
</script>
