<template>
  <div v-if="!loading && partners?.length">
    <div class="mx-auto max-w-7xl py-10 md:py-[120px] px-4 sm:px-6 lg:px-8">
      <div class="text-center">
        <h2 class="text-3xl mb-1">{{ t('partners') }}</h2>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-6 mt-6 md:mt-10">
        <a v-for="partner in partners" :key="partner.id" target="_blank" :href="partner.link">
          <div class="flex justify-center">
            <img :src="partner.file" :alt="partner.name" class="h-[90px] w-[190px] p-5 rounded-md bg-white shadow-md" />
          </div>
        </a>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { ref } from 'vue';
  import { getPartnersRequest } from '@/services/home';
  import type { TPartner } from '@/types/global';
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n();

  const loading = ref(false);
  const partners = ref<TPartner[]>([]);

  async function getPartners() {
    loading.value = true;
    const { data } = await getPartnersRequest();
    //@ts-ignore
    partners.value = data.payload.items;
    loading.value = false;
  }

  getPartners();
</script>
