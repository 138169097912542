import { getUserInfo, getCompanyInfo } from '@/services/auth';
import type { IProfile } from '@/types/AuthView.types';
import { defineStore } from 'pinia';
import { useCookies } from 'vue3-cookies';

type TState = {
  profile: IProfile | null;
  notificationsCount: number;
};

type TActions = {
  getUser: () => void;
  getCompanyData: () => void;
  setNotificationsCount: (value: number) => void;
};

const { cookies } = useCookies();

export const useCoreStore = defineStore<string, TState, Record<never, never>, TActions>('core', {
  state: () => ({
    profile: null,
    notificationsCount: 0,
  }),
  actions: {
    setNotificationsCount(value: number) {
      this.notificationsCount = value;
    },
    async getUser() {
      try {
        const res = await getUserInfo();
        this.profile = res?.data?.payload || null;
        this.notificationsCount = this.profile?.notificationCount || 0;
      } catch (err) {
        //@ts-ignore
        if (err?.response?.data?.messages?.en === 'User not found!') {
          cookies.remove('token');
          window.location.reload();
        }
      }
    },

    async getCompanyData() {
      const res = await getCompanyInfo();
      this.profile = res?.data || null;
      this.notificationsCount = this.profile?.notificationCount || 0;
    },
  },
});
