<template>
  <div class="bg-white">
    <div class="mx-auto max-w-7xl py-10 md:pt-[250px] md:pb-[120px] px-4 sm:px-6 lg:px-8 flex justify-center">
      <div>
        <div class="text-center">
          <h2 class="text-2xl mb-1">{{ t('homePage.popularCategories') }}</h2>
          <p class="text-grey-300 text-sm">
            {{ statistics?.vacancyCount || 0 }} {{ t('vacancies') }} - {{ statistics?.vacancyCountToday || 0 }}
            {{ t('todayAdded') }}.
          </p>
        </div>
        <div v-if="loading" class="flex h-64 justify-center items-center">
          <Spinner />
        </div>
        <div v-else>
          <div class="mt-14 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <router-link
              :to="{ name: 'search-vacancies', query: { categoryId: category.id } }"
              v-for="category in specializationCategories?.slice(0, 9)"
              :key="category.id"
              class="flex items-center space-x-4 py-4 pl-4 rounded-md border shadow-[#ECEDF2] hover:bg-primary-100 shadow-sm border-[#ECEDF2] pr-20 cursor-pointer"
            >
              <span
                class="min-h-[70px] min-w-[70px] h-[70px] w-[70px] rounded-md text-primary-500 hover:bg-[#ECEDF2] flex justify-center items-center"
              >
                <Image class="!h-[70px] !w-[70px]" :src="category.iconPath" alt="" />
              </span>
              <div>
                <p class="text text-lg md:text-xl text-primary-500 mb-1">{{ category[`name${localePostfix}`] }}</p>
              </div>
            </router-link>
          </div>
          <div class="flex justify-center mt-8 md:mt-14">
            <router-link :to="{ name: 'search-vacancies' }">
              <Button class="w-full md:w-auto" variant="primary">{{ t('homePage.allVacancies') }}</Button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { computed, ref } from 'vue';
  import Button from '@/components/ui/Button.vue';
  import { useI18n } from 'vue-i18n';
  import type { TStatistics } from '@/types/global';
  import type { IIndustry } from '@/types/industries';
  import { getSpecializationCategories } from '@/services/company';
  import Image from '@/components/ui/Image.vue';
  import Spinner from '@/components/ui/Spinner.vue';
  const { t, locale } = useI18n();
  type TProps = {
    statistics: TStatistics;
  };

  const localePostfix = computed(() => {
    return locale.value === 'ru' ? 'Ru' : locale.value === 'en' ? 'En' : 'Tj';
  });

  const loading = ref(true);

  const specializationCategories = ref<IIndustry[]>();

  getSpecializationCategories()
    .then(res => {
      specializationCategories.value = res.data?.payload.items;
    })
    .finally(() => {
      loading.value = false;
    });

  defineProps<TProps>();
</script>
<style scoped>
  .text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
</style>
