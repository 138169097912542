<template>
  <div class="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
    <h1 class="text-3xl font-medium text-black">{{ t('vacancyPage.postNewVacancy') }}</h1>
    <p class="text-[15px] font-normal text-grey-400 mt-4">{{ t('vacancyPage.publishNewVacanciesAndFindEmployees') }}</p>
    <div class="p-10 mx-auto shadow-sm rounded-lg max-w-[1440px] bg-white mt-16 mb-6">
      <h2 class="text-lg text-black font-medium">{{ t('vacancyPage.postVacancy') }}</h2>
      <div class="mt-8 flex flex-col md:flex-row">
        <Button
          class="rounded-b-none md:rounded-lg md:rounded-r-none border border-primary-600"
          variant="primaryOutline"
          :isActive="currentLocale === 'Ru'"
          @click="currentLocale = 'Ru'"
        >
          {{ t('russian') }}
        </Button>
        <Button
          class="!rounded-none border border-primary-600"
          variant="primaryOutline"
          :isActive="currentLocale === 'Tj'"
          @click="currentLocale = 'Tj'"
        >
          {{ t('tajik') }}
        </Button>
        <Button
          class="rounded-t-none md:rounded-lg md:rounded-l-none border border-primary-600"
          variant="primaryOutline"
          :isActive="currentLocale === 'En'"
          @click="currentLocale = 'En'"
        >
          {{ t('english') }}
        </Button>
      </div>
      <p class="text-xs font-normal text-[#7B7B7B] mt-2.5">{{ t('vacancyPage.fillOnOtherLanguages') }}</p>
      <div class="mt-10">
        <Input
          v-model="vacancy[`position${currentLocale}`]"
          :label="t('position')"
          :placeholder="t('vacancyPage.enterPositionName')"
        />
      </div>
      <div class="mt-10">
        <Textarea
          v-model="vacancy[`description${currentLocale}`]"
          :label="t('vacanciesPage.vacancyDescription')"
          :placeholder="t('vacancyPage.enterVacancyDescription')"
        />
      </div>
      <div class="mt-10">
        <Textarea
          v-model="vacancy[`requirements${currentLocale}`]"
          :label="t('vacancyPage.requirements')"
          :placeholder="t('vacancyPage.enterVacancyRequirements')"
        />
      </div>
      <div class="mt-10">
        <Textarea
          v-model="vacancy[`commitment${currentLocale}`]"
          :label="t('vacancyPage.commitment')"
          :placeholder="t('vacancyPage.enterTheCommitmentToVacancy')"
        />
      </div>
      <div class="flex flex-wrap md:flex-nowrap mt-6 gap-6">
        <div class="w-full">
          <div class="flex items-end flex-wrap md:flex-nowrap gap-7 w-full">
            <Input
              v-model="vacancy.ageFrom"
              :label="t('age')"
              :placeholder="`${t('age')} ${t('from')}`"
              class="w-full"
            />
            <Input v-model="vacancy.ageTo" :placeholder="`${t('age')} ${t('before')}`" class="w-full" />
          </div>
          <Select
            v-model="specializationCategory"
            :items="specializationCategories"
            :label="t('vacancyPage.categoriesOfSpecializations')"
            class="w-full mt-7"
            :placeholder="t('choose')"
          />
          <div class="pt-7 flex flex-wrap md:flex-nowrap gap-6 items-end">
            <Input
              :disabled="vacancy.contractual"
              v-model="vacancy.salaryCurrency"
              :label="t('vacancyPage.offeredSalary')"
            />
            <Checkbox v-model="vacancy.contractual" :label="t('vacancyPage.byContract')" class="mb-6" />
          </div>
          <Select
            v-model="vacancy.workExperience"
            :items="[
              { title: `${t('withoutExperience')}`, value: '0' },
              { title: '1-3', value: '1-3' },
              { title: '3-6', value: '3-6' },
              { title: '6+', value: '6+' },
            ]"
            :label="t('workExperience')"
            class="w-full mt-7"
            :placeholder="t('vacancyPage.selectTheRequiredExperience')"
          />
          <Select
            :items="regions"
            class="w-full mt-9"
            v-model="vacancy.regionId"
            :label="t('region')"
            :placeholder="t('regionCityPlaceholder')"
            @update:model-value="getCitiesHandler"
            :isError="isSubmit && v$.regionId?.$invalid"
            :errorText="t('requiredField')"
          />
          <div class="mt-8">
            <label class="block text-[15px] text-black font-normal mb-1.5"> {{ t('vacancyPage.closingDate') }} </label>
            <DatePicker
              auto-apply
              :placeholder="t('vacancyPage.closingDate')"
              @change="
                () => {
                  //@ts-ignore
                  filters.from = filters.from.toISOString();
                }
              "
              :select-text="t('select')"
              :errorText="t('requiredField')"
              :modelValue="vacancy.durationDate"
              @update:modelValue="($event: any) => (vacancy.durationDate = $event?.toISOString())"
              :label="t('vacancyPage.closingDate')"
            />
            <p class="mt-1 text-red text-sm" v-if="isSubmit && v$.durationDate.$invalid">
              {{ t('requiredField') }}
            </p>
          </div>
          <div class="mt-11">
            <Checkbox v-model="vacancy.workForDisabled" :label="t('vacancyPage.employmentOfDisabledPersons')" />
            <Checkbox v-model="vacancy.active" :label="t('active')" />
            <Checkbox v-model="vacancy.AsTemplate" :label="t('asTemplate')" />
          </div>
          <div class="mt-10">
            <Input
              v-model="vacancy.address"
              :isError="isSubmit && v$.address?.$invalid"
              :errorText="t('requiredField')"
              :label="t('applicantPage.fullAddress')"
              placeholder="Душанбе, Сино 44"
            />
          </div>
        </div>
        <div class="w-full">
          <div class="flex items-end gap-7 w-full">
            <Select
              v-model="vacancy.gender"
              :items="[
                { title: t('optional'), value: 0 },
                { title: t('male'), value: 1 },
                { title: t('female'), value: 2 },
              ]"
              class="w-full"
              :label="t('gender')"
              :placeholder="t('choose')"
            />
          </div>
          <Multiselect
            v-model="vacancy.specializationIds"
            :items="specializations"
            class="w-full mt-7"
            :label="t('vacanciesPage.specializations')"
            :error="isSubmit && v$.specializationIds.$invalid ? t('requiredField') : ''"
          />
          <Multiselect
            v-model="vacancy.educationIds"
            :items="educations"
            class="w-full mt-7"
            :label="t('education')"
            :error="isSubmit && v$.educationIds.$invalid ? t('requiredField') : ''"
          />
          <Select
            v-model="vacancy.EmploymentTypeId"
            :items="employmentTypes"
            class="w-full mt-[40px]"
            :isError="isSubmit && v$.EmploymentTypeId?.$invalid"
            :label="t('vacanciesPage.employmentType')"
            :errorText="t('requiredField')"
            :placeholder="t('choose')"
          />
          <Select
            :disabled="!vacancy.regionId"
            class="w-full mt-9"
            :items="cities"
            v-model="vacancy.cityId"
            :label="t('regionCity')"
            :placeholder="t('regionCityPlaceholder')"
            :isError="isSubmit && v$.cityId?.$invalid"
            :errorText="t('requiredField')"
          />
          <Multiselect
            v-model="vacancy.workScheduleIds"
            :items="workSchedules"
            :label="t('vacanciesPage.workSchedule')"
            :placeholder="t('vacancyPage.selectWorkSchedule')"
            class="w-full mt-9"
            :error="isSubmit && v$.workScheduleIds?.$invalid ? t('requiredField') : ''"
          />
          <div class="mt-7">
            <Input
              :isError="isSubmit && v$.vacancyCount.$invalid"
              :errorText="t('requiredField')"
              v-model="vacancy.vacancyCount"
              :label="t('vacancyPage.vacancyCount')"
              :placeholder="t('vacancyPage.enterVacancyCount')"
            />
          </div>
        </div>
      </div>
      <div>
        <div v-for="(language, index) in languagesIds" :key="index">
          <div
            v-if="languagesIds.length > 0 && language?.languageId && language?.languageLevelId"
            class="flex flex-col md:flex-row justify-start items-center gap-8 mt-8"
          >
            <p>{{ allLanguages.find(item => item.value === language?.languageId)?.title }}</p>
            <p>{{ apps.find(item => item.value === language?.languageLevelId)?.title }}</p>

            <div class="flex gap-4">
              <SvgIcon
                @click.stop="removeLanguage(index, language?.languageId)"
                class="text-red cursor-pointer"
                :class="{ '!text-grey-500': index !== languagesIds.length - 1 }"
                name="trash"
              />
              <SvgIcon
                v-if="index === languagesIds.length - 1 && languagesIds.length < allLanguages.length"
                @click.stop="addLanguage(language.languageId)"
                class="text-primary-500 cursor-pointer"
                name="plus"
              />
            </div>
          </div>
          <div v-else class="flex flex-col md:flex-row justify-between items-center gap-8 mt-8">
            <Select
              :placeholder="t('choose')"
              :items="languages"
              class="w-full"
              v-model="language.languageId"
              :label="t('languages')"
            />
            <Select
              :items="apps"
              :class="[index === languagesIds.length - 1 ? 'w-[82%]' : 'w-[90%]']"
              v-model="language.languageLevelId"
              :label="t('languageLevels')"
              :placeholder="t('choose')"
            />
            <div class="mt-5 flex gap-4">
              <SvgIcon
                @click.stop="removeLanguage(index, null)"
                class="text-red cursor-pointer"
                :class="{ '!text-grey-500': index === 0 }"
                name="trash"
              />
              <SvgIcon
                v-if="index === languagesIds.length - 1 && language.languageId && language.languageLevelId"
                @click.stop="addLanguage(language.languageId)"
                class="text-primary-500 cursor-pointer"
                name="plus"
              />
            </div>
          </div>
          <p class="mt-1 text-red text-sm" v-if="isSubmit && !languagesIds[0].languageId">
            {{ t('requiredField') }}
          </p>
        </div>
      </div>
      <div class="mt-20 flex justify-end">
        <Button :disabled="loading" @click="saveHandler">{{ t('save') }}</Button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { computed, ref, watch } from 'vue';
  import useVuelidate from '@vuelidate/core';
  import { useCoreStore } from '~/stores/core';

  import Button from '@/components/ui/Button.vue';
  import Input from '@/components/ui/Input.vue';
  import Textarea from '~/components/ui/Textarea.vue';
  import Select from '~/components/ui/Select.vue';
  import Checkbox from '~/components/ui/Checkbox.vue';
  import Multiselect from '~/components/ui/Multiselect.vue';
  import { createVacancyRequest } from '~/services/vacancy';
  import { getWorkSchedulesRequest } from '~/services/workSchedules';
  import { getSpecializationCategoriesRequest } from '~/services/specializationCategory';
  import { getSpecializationsRequest } from '~/services/specialization';
  import { getEducationsRequest } from '~/services/educations';
  import { type TItem } from '~/types/global';
  import { toast } from 'vue3-toastify';
  import { useI18n } from 'vue-i18n';
  import { getEmploymentTypesRequest } from '@/services/employmentTypes';
  import { getRegions } from '@/services/regions';
  import { getApps, getCompanyInfo } from '@/services/auth';
  import { getCompanyById } from '@/services/company';
  import { getLanguages } from '@/services/languages';
  import SvgIcon from '@/components/ui/SvgIcon.vue';
  import { required } from '@vuelidate/validators';

  const { t, locale } = useI18n();

  const coreStore = useCoreStore();

  const isSubmit = ref(false);
  const loading = ref(false);
  const workSchedules = ref<TItem[]>([]);
  const educations = ref<TItem[]>([]);
  const specializations = ref<TItem[]>([]);
  const specializationCategories = ref<TItem[]>([]);
  const specializationCategory = ref('');
  const employmentTypes = ref<TItem[]>([]);
  const regions = ref<any[]>([]);
  const allRegions = ref<any[]>([]);
  const cities = ref<any[]>([]);
  const apps = ref<any[]>([]);
  const languagesIds = ref<{ languageId: string; languageLevelId: number | null }[]>([
    {
      languageId: '',
      languageLevelId: null,
    },
  ]);
  const languages = ref<any[]>([]);
  const allLanguages = ref<any[]>([]);
  const vacancy = ref<any>({
    'positionTj': '',
    'positionEn': '',
    'positionRu': '',
    'ageFrom': 0,
    'ageTo': 0,
    'gender': 0,
    'vacancyCount': '',
    'address': '',
    'salaryCurrency': 0,
    'contractual': true,
    'requirementsTj': '',
    'requirementsRu': '',
    'requirementsEn': '',
    'commitmentTj': '',
    'commitmentRu': '',
    'commitmentEn': '',
    'descriptionEn': '',
    'descriptionTj': '',
    'descriptionRu': '',
    'durationDate': '',
    'active': true,
    'workForDisabled': true,
    'AsTemplate': false,
    'specializationIds': [],
    'workExperience': '',
    'educationIds': [],
    'workScheduleIds': [],
    'EmploymentTypeId': '',
    'regionId': '',
    'cityId': '',
    Languages: [],
  });
  const rules = computed(() => ({
    educationIds: { required },
    EmploymentTypeId: { required },
    durationDate: { required },
    regionId: { required },
    cityId: { required },
    Languages: { required },
    address: { required },
    specializationIds: { required },
    workScheduleIds: { required },
    vacancyCount: { required },
  }));

  const currentLocale = ref('Ru');

  const v$ = useVuelidate(rules, vacancy);

  getDatas();

  const fillEmptyValues = (data: any) => {
    let filledValue = '';
    if (data.some((item: any) => item.length === 0)) {
      filledValue = data.find((item: any) => item.length > 0);
    }
    return filledValue || null;
  };
  async function saveHandler() {
    vacancy.value.Languages = languagesIds.value;
    isSubmit.value = true;
    v$.value.$touch();
    if (v$.value.$invalid) {
      return;
    }
    loading.value = true;
    //@ts-ignore
    const position = fillEmptyValues([vacancy.value.positionRu, vacancy.value.positionEn, vacancy.value.positionTj]);
    if (position) {
      [vacancy.value.positionRu, vacancy.value.positionEn, vacancy.value.positionTj] = [position, position, position];
    }
    const requirement = fillEmptyValues([
      vacancy.value.requirementsTj,
      vacancy.value.requirementsRu,
      vacancy.value.requirementsEn,
    ]);
    if (requirement) {
      [vacancy.value.requirementsTj, vacancy.value.requirementsRu, vacancy.value.requirementsEn] = [
        requirement,
        requirement,
        requirement,
      ];
    }

    const commitment = fillEmptyValues([
      vacancy.value.commitmentTj,
      vacancy.value.commitmentRu,
      vacancy.value.commitmentEn,
    ]);
    if (commitment) {
      [vacancy.value.commitmentTj, vacancy.value.commitmentRu, vacancy.value.commitmentEn] = [
        commitment,
        commitment,
        commitment,
      ];
    }
    const description = fillEmptyValues([
      vacancy.value.descriptionEn,
      vacancy.value.descriptionTj,
      vacancy.value.descriptionRu,
    ]);
    if (description) {
      [vacancy.value.descriptionEn, vacancy.value.descriptionTj, vacancy.value.descriptionRu] = [
        description,
        description,
        description,
      ];
    }

    v$.value.$touch();
    if (v$.value.$invalid) {
      return;
    }
    console.log(vacancy.value);
    if (!vacancy.value.AsTemplate) {
      //@ts-ignore
      await createVacancyRequest(coreStore.profile?.profileId as string, vacancy.value)
        .then(() => {
          toast.success(t('successfullySaved'));
        })
        .finally(() => {
          setTimeout(() => {
            loading.value = false;
          }, 30000);
        });
    } else {
      await createVacancyRequest(coreStore.profile?.profileId as string, vacancy.value);
      vacancy.value.AsTemplate = false;
      await createVacancyRequest(coreStore.profile?.profileId as string, vacancy.value)
        .then(() => {
          toast.success(t('successfullySaved'));
        })
        .finally(() => {
          setTimeout(() => {
            loading.value = false;
          }, 30000);
        });
    }
  }

  const getCompanyInfoHandler = () => {
    getCompanyInfo().then((response: any) => {
      getCompanyByIdHandler(response?.data.companyId);
    });
  };

  function getCompanyByIdHandler(id: string) {
    getCompanyById(id).then((response: any) => {
      vacancy.value.address = response.data.payload.contactInformation.completeAddress;
    });
  }
  function getDatas() {
    Promise.all([
      getWorkSchedulesRequest(),
      getEducationsRequest(),
      getSpecializationCategoriesRequest(),
      getEmploymentTypesRequest(),
      getApps(),
    ])
      .then(res => {
        workSchedules.value = res[0].payload.items.map(i => ({ title: i[`name${localePostfix.value}`], value: i.id }));
        educations.value = res[1].payload.map(i => ({ title: i[`name${localePostfix.value}`], value: i.id }));
        specializationCategories.value = res[2].payload.items.map(i => ({
          title: i[`name${localePostfix.value}`],
          value: i.id,
        }));
        employmentTypes.value = res[3].payload.items.map(i => ({
          title: i[`name${localePostfix.value}`],
          value: i.id,
        }));
      })
      .catch(err => {
        console.error('error on getting data: ', err);
      });
  }

  const localePostfix = computed(() => {
    return locale.value === 'ru' ? 'Ru' : locale.value === 'en' ? 'En' : 'Tj';
  });
  const getRegionsHandler = () => {
    getRegions().then((response: any) => {
      allRegions.value = response.data.payload.items || [];
      getCitiesHandler();
      response.data.payload.items?.forEach((item: any) => {
        if (!item.parentId) {
          regions.value.push({
            value: item.id,
            title: item[`name${localePostfix.value}`],
            viewTitle: item[`name${localePostfix.value}`],
          });
        }
      });
    });
  };

  const getSpecializationsHandler = () => {
    if (!specializationCategory.value) return;
    specializations.value = [];
    getSpecializationsRequest({ Category: specializationCategory.value }).then((response: any) => {
      response &&
        response.payload.items.forEach((item: any) => {
          specializations.value.push({ value: item?.id, title: item[`name${localePostfix.value}`] });
        });
    });
  };

  watch(
    () => vacancy.value.cityId,
    () => {
      getCitiesHandler();
    }
  );

  watch(
    () => specializationCategory.value,
    () => {
      getSpecializationsHandler();
    },
    { immediate: true }
  );

  const getCitiesHandler = () => {
    if (!vacancy.value.regionId) return;
    cities.value = [];
    allRegions.value.forEach(region => {
      if (region.parentId === vacancy.value.regionId) {
        cities.value.push({
          value: region.id,
          title: region[`name${localePostfix.value}`],
          viewTitle: region[`name${localePostfix.value}`],
        });
      }
    });
  };

  const getLanguagesHandler = () => {
    getLanguages().then((response: any) => {
      response.data &&
        response.data.payload.items.forEach((item: any) => {
          languages.value.push({
            value: item?.id,
            title: locale.value === 'ru' ? item?.nameRu : locale.value === 'en' ? item?.nameEn : item?.nameTj,
          });
          allLanguages.value.push({
            value: item?.id,
            title: locale.value === 'ru' ? item?.nameRu : locale.value === 'en' ? item?.nameEn : item?.nameTj,
          });
        });
    });
  };
  const getAppsHandler = () => {
    getApps().then((response: any) => {
      response.data &&
        response.data.languageLevels.forEach((item: any) => {
          apps.value.push({
            value: item?.id,
            title: locale.value === 'ru' ? item?.nameRu : locale.value === 'en' ? item?.nameEn : item?.nameTj,
          });
        });
    });
  };

  const addLanguage = (id: string) => {
    languages.value = languages.value.filter((language: any) => {
      return language.value !== id;
    });
    languagesIds.value.push({ languageId: '', languageLevelId: null });
  };
  const removeLanguage = (index: number, id: string | null) => {
    if (index !== languagesIds.value.length - 1) return;
    if (id) {
      allLanguages.value.forEach((item: any) => {
        if (item.value === id && !languages.value.find(item => item.value === id)) {
          languages.value.push(item);
        }
      });
    }
    languagesIds.value.splice(index, 1);
    if (!languagesIds.value.length) {
      languagesIds.value.push({
        languageId: '',
        languageLevelId: null,
      });
    }
  };

  getRegionsHandler();
  getCompanyInfoHandler();
  getLanguagesHandler();
  getAppsHandler();
</script>
