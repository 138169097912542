<template>
  <div class="mx-auto max-w-7xl py-6 px-6 lg:px-8">
    <h1 class="text-3xl font-medium text-black">{{ t('jobApplications') }}</h1>
    <div class="flex justify-center mt-8" v-if="getLoading">
      <Spinner />
    </div>
    <div v-else-if="appliedVacancies && appliedVacancies.length" class="grid grid-cols-1 gap-2 mt-8 pb-8">
      <div
        v-for="vacancy in appliedVacancies"
        :key="vacancy.id"
        class="flex items-center justify-between p-8 border border-[#ECEDF2] bg-white rounded-lg"
      >
        <div class="flex gap-6">
          <Image class="!w-[50px] !h-[50px]" :src="vacancy.companyLogo || blankImage" :alt="t('companyLogotype')" />
          <div>
            <p class="text-lg font-medium text-[#202124]">{{ vacancy.positionRU || '12' }}</p>
            <div class="text-sm font-normal flex gap-8 items-center text-grey-400">
              <p class="flex gap-2"><SvgIcon name="briefcase" /> {{ vacancy.companyName || 'company name' }}</p>
            </div>
          </div>
        </div>
        <time class="text-grey-400 hidden md:block">{{ readableDate(vacancy.createdAt, locale) }}</time>
        <div class="flex gap-2">
          <router-link
            :to="{ name: 'vacancy-detail', params: { id: vacancy.vacancyId } }"
            class="p-2 rounded-lg bg-[#1967D2] bg-opacity-10"
          >
            <SvgIcon name="eye" class="text-[#1967D2]" />
          </router-link>
          <button class="p-2 rounded-lg bg-[#1967D2] bg-opacity-10" @click="deleteVacancy(vacancy)">
            <SvgIcon name="blue-trash" class="text-[#1967D2]" />
          </button>
        </div>
      </div>
    </div>
    <div v-else class="mt-8 flex items-center justify-center p-8 border border-[#ECEDF2] bg-white rounded-lg">
      <p class="text-grey-200">{{ t('noData') }}</p>
    </div>
    <UIModal
      @cancel="deleteModal = false"
      :title="t('deletion')"
      :submitButtonText="t('delete')"
      @submit="deleteElementHandler"
      v-model="deleteModal"
    >
      <p>
        {{ t('doYouReallyWantToDelete') }}
        <strong>{{ t('applicantPage.applicationForAJob') }}</strong>
        ?
      </p>
    </UIModal>
  </div>
</template>
<script setup lang="ts">
  import Spinner from '@/components/ui/Spinner.vue';
  import { computed, ref, watch } from 'vue';
  import {
    deleteAppliedVacanciesRequest,
    getApplicantByProfileId,
    getAppliedVacanciesRequest,
  } from '@/services/applicant';
  import { useCoreStore } from '@/stores/core';
  import SvgIcon from '@/components/ui/SvgIcon.vue';
  import Image from '@/components/ui/Image.vue';
  import { readableDate } from '@/utils/filters';
  import UIModal from '@/components/ui/Modal.vue';
  import { toast } from 'vue3-toastify';
  import { useI18n } from 'vue-i18n';
  import blankImage from '@/assets/images/blank-image.png';
  const { t, locale } = useI18n();

  type IAppliedVacancies = {
    id: string;
    vacancyId: string;
    positionTj: string | null;
    positionRU: string | null;
    positionEN: string | null;
    companyName: string | null;
    companyLogo: string | null;
    statusId: number;
    createdAt: string;
  };

  const loading = ref(false);
  const getLoading = ref(false);
  const deleteModal = ref<any>(false);
  const selectedElement = ref<IAppliedVacancies | null>(null);
  const applicant = ref<any>(null);
  const appliedVacancies = ref<IAppliedVacancies[] | null>(null);

  const profile = computed(() => {
    return useCoreStore().profile;
  });

  watch(
    () => profile,
    () => {
      if (profile?.value?.profileId) getApplicantHandler(profile?.value?.profileId);
    },
    { immediate: true, deep: true }
  );

  function deleteVacancy(data: any) {
    selectedElement.value = data;
    deleteModal.value = true;
  }

  function getApplicantHandler(id: string) {
    getLoading.value = true;
    getApplicantByProfileId(id)
      .then(response => {
        applicant.value = response.data.payload;
      })
      .finally(() => {
        getAppliedVacanciesHandler(applicant?.value?.id || '');
      });
  }

  function getAppliedVacanciesHandler(id: string) {
    getLoading.value = true;
    getAppliedVacanciesRequest(id)
      .then(response => {
        appliedVacancies.value = response.payload;
      })
      .finally(() => {
        getLoading.value = false;
      });
  }
  function deleteElementHandler() {
    loading.value = true;

    deleteAppliedVacanciesRequest(selectedElement.value?.id || '')
      .then(() => {
        toast.success(t('successfullyDeleted'));
        deleteModal.value = false;
        getAppliedVacanciesHandler(applicant?.value?.id || '');
      })
      .finally(() => (loading.value = false));
  }
</script>

<style scoped></style>
