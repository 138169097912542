<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_5_8554)">
      <path
        d="M8.79037 7.44629C8.42905 7.44629 8.08939 7.58698 7.83334 7.84271C7.306 8.37036 7.3063 9.22882 7.83365 9.75616C8.08939 10.0119 8.42905 10.1526 8.79037 10.1526C9.1517 10.1526 9.49167 10.0119 9.74741 9.75616C10.2747 9.22882 10.2747 8.37036 9.7471 7.84241C9.49136 7.58698 9.1517 7.44629 8.79037 7.44629Z"
        fill="black"
      />
      <path
        d="M34.7598 13.4991L39.9957 0L26.4966 5.2359L20.3235 11.409L8.92334 0.00915531L0 8.9325L11.4001 20.3326L2.97119 28.7616L1.07422 30.6583C0.390015 31.3428 0.0128173 32.2528 0.0128173 33.2208C0.0128173 34.1888 0.390015 35.0986 1.07422 35.7834L4.21234 38.9215C4.91882 39.628 5.84686 39.9811 6.7749 39.9811C7.70294 39.9811 8.63098 39.628 9.33746 38.9215L19.6634 28.5956L31.0675 40L39.9908 31.0767L28.5867 19.6722L34.7598 13.4991ZM9.58771 32.0648L30.4538 11.1987L32.1002 12.8452L11.2341 33.7112L9.58771 32.0648ZM33.3145 10.7462L29.2496 6.68121L35.8905 4.10553L33.3145 10.7462ZM11.4157 17.0349L13.5876 14.863L11.9308 13.2062L9.75891 15.3781L3.3136 8.9325L8.92334 3.32245L18.6667 13.0658L13.0569 18.6758L11.4157 17.0349ZM27.1506 7.89551L28.797 9.54193L7.93121 30.408L6.28448 28.7616L27.1506 7.89551ZM7.68097 37.2647C7.43896 37.5067 7.11731 37.6398 6.7749 37.6398C6.4328 37.6398 6.11115 37.5067 5.86914 37.2647L2.73102 34.1266C2.48932 33.8846 2.35596 33.5629 2.35596 33.2208C2.35596 32.8787 2.48932 32.5568 2.73102 32.3151L4.62799 30.4181L9.57764 35.368L7.68097 37.2647ZM36.6776 31.0767L31.0675 36.6867L27.9318 33.551L30.1038 31.3791L28.447 29.7223L26.2753 31.8942L24.6286 30.2478L26.8005 28.0759L25.1437 26.4191L22.9718 28.591L21.3199 26.9391L26.9299 21.329L36.6776 31.0767Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_5_8554">
        <rect width="40" height="40" fill="black" />
      </clipPath>
    </defs>
  </svg>
</template>
