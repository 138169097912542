<template>
  <div>
    <Disclosure as="nav" class="bg-white shadow-md" v-slot="{ open }">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="flex h-16 items-center justify-between">
          <div class="hidden md:flex items-center">
            <router-link :to="{ name: 'home' }">
              <div class="flex-shrink-0 text-primary-500">
                <logo />
              </div>
            </router-link>
            <div class="hidden md:block">
              <div class="ml-10 flex items-baseline space-x-4">
                <div v-for="item in navigation" :key="item.name">
                  <router-link
                    @click="selectedNav = item.name"
                    v-if="item.show"
                    :to="{ name: item.to }"
                    :class="[
                      'text-gray-300 hover:bg-gray-700 hover:text-gray-500',
                      'rounded-md px-3 py-2 text-sm font-medium',
                      { 'text-primary-500': selectedNav === item.name },
                    ]"
                  >
                    {{ t(item.name) }}
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <Menu as="div" class="relative ml-3 hidden md:block">
            <div>
              <MenuButton
                class="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
              >
                <div class="flex-shrink-0">
                  <div class="flex gap-2 items-center">
                    {{ languageTitle }}
                  </div>
                </div>
              </MenuButton>
            </div>
            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="absolute right-0 z-50 mt-2 w-48 origin-top-right bg-white rounded-md py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <MenuItem @click="getLanguage('tj')">
                  <div :class="['block px-4 py-2 hover:bg-primary-500 hover:text-white text-sm text-gray-700']">
                    Тоҷикӣ
                  </div>
                </MenuItem>
                <MenuItem @click="getLanguage('ru')">
                  <div :class="['block px-4 py-2 hover:bg-primary-500 hover:text-white text-sm text-gray-700']">
                    Русский
                  </div>
                </MenuItem>
                <MenuItem @click="getLanguage('en')">
                  <div :class="['block px-4 py-2 hover:bg-primary-500 hover:text-white text-sm text-gray-700']">
                    English
                  </div>
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
          <div v-if="token" class="hidden md:block">
            <div class="ml-4 flex items-center md:ml-6">
              <Menu as="div" class="relative ml-3 hidden md:block">
                <div>
                  <MenuButton
                    @click="getNotificationsHandler()"
                    class="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  >
                    <span class="absolute -inset-1.5" />
                    <BellIcon class="h-6 w-6" aria-hidden="true" />
                    <span
                      class="absolute -top-1.5 -right-1 text-xs rounded-full bg-primary-500 text-white w-4 h-4"
                      v-if="notificationsCount"
                    >
                      {{ notificationsCount }}
                    </span>
                  </MenuButton>
                </div>
                <transition
                  enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95"
                >
                  <MenuItems
                    class="absolute right-0 z-50 mt-5 w-[462px] origin-top-right bg-white rounded-3xl p-8 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-auto max-h-[500px]"
                  >
                    <div class="flex flex-col items-center" v-if="notificationsLoading || markAsReadLoading">
                      <Spinner />
                    </div>
                    <div v-else>
                      <div v-if="notifications.length">
                        <div class="flex justify-between font-medium border-b pb-5 border-grey-50">
                          <p>{{ t('notifications') }}</p>
                          <a
                            @click="markAsReadAllNotifications"
                            class="text-sm text-primary-500 cursor-pointer active:text-primary-600"
                          >
                            {{ t('homePage.markAsRead') }}
                          </a>
                        </div>
                        <MenuItem v-for="notification in notifications" :key="notification.id">
                          <div
                            @click="getNotificationHandler(notification.id, notification.titleRu)"
                            :class="[
                              'block text-sm text-gray-700 py-3 border-b  border-grey-50 hover:bg-grey-50 hover:px-1 rounded-2xl',
                            ]"
                          >
                            <div class="flex gap-3 items-start">
                              <div
                                class="p-2 rounded-full text-white"
                                :class="[notification.titleRu === 'Cообщение' ? 'bg-primary-500' : 'bg-red']"
                              >
                                <SvgIcon name="info-circle" />
                              </div>
                              <div class="flex flex-col gap-1.5">
                                <p class="font-medium">{{ notification[`title${localePostfix}`] }}</p>
                                <p class="text-sm">{{ notification[`body${localePostfix}`] }}</p>
                                <time class="text-grey-200">{{
                                  readableDateShort(notification?.createdAt, locale)
                                }}</time>
                              </div>
                            </div>
                          </div>
                        </MenuItem>
                      </div>
                      <div class="text-center text-grey-200" v-else>
                        {{ t('noData') }}
                      </div>
                    </div>
                  </MenuItems>
                </transition>
              </Menu>
              <Menu as="div" class="relative ml-3">
                <div>
                  <MenuButton
                    class="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  >
                    <div class="flex-shrink-0">
                      <Image
                        v-if="profile?.photo || profile?.logo"
                        class="!h-10 !w-10 rounded-full border border-primary-500"
                        :src="profile?.photo || profile?.logo || ''"
                        alt=""
                      />
                      <PersonIcon class="rounded-full border border-primary-500 w-10 h-10" v-else />
                    </div>
                    <div class="ml-3">
                      <div class="font-medium text-left leading-none mb-0.5">
                        {{
                          profileType === 'company' ? profile?.companyName?.slice(0, 15) : profile?.name.slice(0, 15)
                        }}
                        {{
                          profile?.companyName && profile?.companyName.length > 15
                            ? '...'
                            : profile?.name && profile?.name.length > 15
                              ? '...'
                              : ''
                        }}
                      </div>
                      <div class="text-xs font-medium leading-none text-grey-300">
                        {{ profile?.email }}
                      </div>
                    </div>
                  </MenuButton>
                </div>
                <transition
                  enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95"
                >
                  <MenuItems
                    class="absolute right-0 z-50 mt-2 w-48 origin-top-right bg-white rounded-md py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <MenuItem
                      v-for="item in profileType === 'company' ? companyNavigation : userNavigation"
                      :key="item.name"
                      v-slot="{ active }"
                    >
                      <div
                        :class="[
                          active ? 'bg-gray-300' : '',
                          'block px-4 py-2 hover:bg-primary-500 hover:text-white text-sm text-gray-700',
                        ]"
                        v-if="item.to === 'exit'"
                        @click="clickMenuButton('exit')"
                      >
                        {{ t(item.name) }}
                      </div>
                      <router-link
                        v-else
                        :to="{ name: item.to }"
                        :class="[
                          active ? 'bg-gray-300' : '',
                          'block px-4 py-2 hover:bg-primary-500 hover:text-white text-sm text-gray-700',
                        ]"
                      >
                        {{ t(item.name) }}
                      </router-link>
                    </MenuItem>
                  </MenuItems>
                </transition>
              </Menu>
            </div>
          </div>
          <div class="items-center space-x-4 hidden md:flex" v-else>
            <div class="text-primary-500 cursor-pointer">
              <span @click="router.push({ name: 'auth' })">{{ t('login') }}</span>
            </div>
            <button
              @click="router.push({ name: 'registration' })"
              class="px-4 p-1 bg-primary-500 text-white rounded-md"
            >
              {{ t('global.registration') }}
            </button>
          </div>
          <div class="flex md:hidden mr-2">
            <DisclosureButton
              class="relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700"
            >
              <span class="absolute -inset-0.5" />
              <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
              <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
            </DisclosureButton>
          </div>
          <div class="md:hidden">
            <router-link :to="{ name: 'home' }">
              <img class="md:h-10" src="@/assets/images/kor-logo.png" alt="Your Company" />
            </router-link>
          </div>
          <div class="text-primary-500 md:hidden">
            <span @click="router.push({ name: 'auth' })" v-if="!token && $route.name !== 'auth'">
              {{ t('login') }}
            </span>
          </div>
        </div>
      </div>

      <DisclosurePanel class="md:hidden absolute bg-white z-50 right-0 left-0 border-t shadow-md pt-2 border-grey-200">
        <div v-if="token" class="border-b border-grey-200 pb-3 pt-4">
          <div class="flex items-center px-5">
            <div class="flex-shrink-0">
              <Image v-if="profile?.photo" class="!h-10 !w-10 rounded-full" :src="profile?.photo" alt="" />
              <PersonIcon class="rounded-full border border-primary-500 w-10 h-10" v-else />
            </div>
            <div class="ml-3">
              <div class="text-base font-medium">
                {{ profileType === 'company' ? profile?.companyName : profile?.name }}
              </div>
              <div class="text-sm font-medium leading-none text-gray-400">{{ profile?.email }}</div>
            </div>
          </div>
          <div v-if="profileType" class="mt-3 space-y-1 px-2">
            <DisclosureButton
              v-for="item in profileType === 'company' ? companyNavigation : userNavigation"
              :key="item.name"
              @click="clickMenuButton(item.to)"
              class="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
            >
              {{ t(item.name) }}
            </DisclosureButton>
          </div>
        </div>

        <div class="space-y-1 px-2 pb-3 pt-2 sm:px-3">
          <DisclosureButton
            v-if="!token"
            @click="router.push({ name: 'registration' })"
            class="px-4 p-1 bg-primary-500 text-white rounded-md"
          >
            {{ t('global.registration') }}
          </DisclosureButton>
          <DisclosureButton
            v-for="item in navigation"
            :key="item.name"
            @click="clickMenuButton(item.to)"
            :class="[
              'text-gray-300 hover:bg-gray-700 hover:text-white',
              'block rounded-md px-3 py-2 text-base font-medium',
            ]"
          >
            {{ t(item.name) }}
          </DisclosureButton>
        </div>
      </DisclosurePanel>
    </Disclosure>
  </div>
</template>

<script setup lang="ts">
  import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
  } from '@headlessui/vue';
  import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/vue/24/outline';
  import { useCookies } from 'vue3-cookies';
  import { computed, defineAsyncComponent, onMounted, ref, watch } from 'vue';
  import { useCoreStore } from '@/stores/core';
  import Image from '@/components/ui/Image.vue';
  import { useI18n } from 'vue-i18n';
  import {
    getApplicantAlertsRequest,
    getCompanyAlertsRequest,
    getNotificationRequest,
    getNotifications,
    markNotificationsAsRead,
  } from '@/services/alerts';
  import { RouterLink, useRouter } from 'vue-router';
  import SvgIcon from '@/components/ui/SvgIcon.vue';
  import { readableDateShort } from '../../utils/filters';
  import Spinner from '@/components/ui/Spinner.vue';
  import logo from '@/components/icons/logo.vue';

  const { cookies } = useCookies();
  const PersonIcon = defineAsyncComponent(() => import('@/assets/images/person.svg'));
  const { t, locale } = useI18n({ useScope: 'global' });
  const token = ref(cookies.get('token'));
  const profileType = ref(cookies.get('profile-type'));
  const router = useRouter();
  const alerts = ref(0);
  const selectedNav = ref('');

  const notifications = ref<any>([]);
  const notificationsLoading = ref(false);
  const markAsReadLoading = ref(false);

  const profile = computed(() => {
    return useCoreStore().profile;
  });

  const notificationsCount = computed(() => {
    return useCoreStore().notificationsCount;
  });

  const localePostfix = computed(() => {
    return locale.value === 'ru' ? 'Ru' : locale.value === 'en' ? 'En' : 'Tj';
  });

  const getApplicantAlerts = () => {
    if (profile.value && profile.value.applicantId) {
      if (profile.value.companyId) {
        getCompanyAlertsRequest(profile.value.companyId as string).then(res => {
          //@ts-ignore
          alerts.value = res?.items?.length || 0;
        });
      } else {
        getApplicantAlertsRequest(profile.value.applicantId as string).then(res => {
          //@ts-ignore
          alerts.value = res?.items?.length || 0;
        });
      }
    }
  };

  const getNotificationsHandler = () => {
    try {
      notificationsLoading.value = true;
      getNotifications(profile.value?.id || profile.value?.companyId || '').then(res => {
        notifications.value = res.payload;
      });
    } catch (e) {
      console.error(e);
    } finally {
      notificationsLoading.value = false;
    }
  };
  watch(
    () => profile.value,
    () => {
      token.value = cookies.get('token');
      profileType.value = cookies.get('profile-type');
      getApplicantAlerts();
    },
    { deep: true }
  );

  enum LanguageTitles {
    TAJIK = 'Тоҷикӣ',
    RUSSIAN = 'Русский',
    ENGLISH = 'English',
  }
  const languageTitle = ref('');
  onMounted(() => {
    getLanguage(locale.value);
  });

  const getLanguage = (selectedLanguage: string) => {
    localStorage.setItem('locale', selectedLanguage);
    locale.value = selectedLanguage;
    switch (selectedLanguage) {
      case 'ru':
        languageTitle.value = LanguageTitles.RUSSIAN;
        break;
      case 'tj':
        languageTitle.value = LanguageTitles.TAJIK;
        break;
      case 'en':
        languageTitle.value = LanguageTitles.ENGLISH;
        break;
    }
  };

  watch(
    () => locale.value,
    () => {
      window.location.reload();
    }
  );

  const clickMenuButton = (routeName: string) => {
    if (routeName === 'exit') {
      cookies.remove('token');
      cookies.remove('profile-type');
      window.location.reload();
      return;
    }
    router.push({ name: routeName });
  };

  const navigation = [
    { name: 'global.home', to: 'home', show: true },
    { name: 'global.searchWork', to: 'search-vacancies', show: true },
    { name: 'global.companies', to: 'companies', show: true },
    {
      name: 'global.candidates',
      to: 'applicants',
      show: Boolean(profileType.value === 'company' && profile.value?.profileId),
    },
    { name: 'global.news', to: 'news', show: true },
    { name: 'global.aboutUs', to: 'about-us', show: true },
  ];
  const companyNavigation = [
    { name: 'global.myProfile', to: 'company' },
    { name: 'global.allApplications', to: 'applications' },
    { name: 'global.statistics', to: 'company-statistics' },
    { name: 'global.vacancies', to: 'vacancy-manage' },
    { name: 'global.vacancyTemplates', to: 'vacancy-templates' },
    { name: 'global.addVacancy', to: 'add-new-vacancy' },
    { name: 'global.selectedResumes', to: 'favorite-resume' },
    { name: 'global.notification', to: 'alerts' },
    { name: 'global.messages', to: 'messages' },
    { name: 'global.changePassword', to: 'change-password' },
    { name: 'global.exit', to: 'exit' },
  ];
  const userNavigation = [
    { name: 'global.myProfile', to: 'applicant' },
    { name: 'global.statistics', to: 'applicant-statistics' },
    { name: 'global.resume', to: 'resume' },
    { name: 'global.manegerCV', to: 'cv-manager' },
    { name: 'global.jobApplications', to: 'applied-jobs' },
    { name: 'global.notification', to: 'applicant-alerts' },
    { name: 'global.selectedVacancies', to: 'favorite-vacancies' },
    { name: 'global.messages', to: 'messages' },
    { name: 'global.changePassword', to: 'change-password' },
    { name: 'global.exit', to: 'exit' },
  ];

  const markAsReadAllNotifications = async () => {
    try {
      markAsReadLoading.value = true;
      const notificationIds: string[] = [];
      notifications.value.forEach((notification: any) => {
        notificationIds.push(notification.id);
      });
      await markNotificationsAsRead({ ids: notificationIds });
      if (profileType.value === 'company') {
        useCoreStore().getCompanyData();
      } else {
        useCoreStore().getUser();
      }

      getNotificationsHandler();
    } catch (e) {
      console.error(e);
    } finally {
      markAsReadLoading.value = false;
    }
  };

  const getNotificationHandler = (id: string, title: string) => {
    getNotificationRequest(id).then(() => {
      if (title === 'Cообщение') {
        router.push({ name: 'messages' });
      }
    });
  };
</script>
