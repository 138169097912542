export const startHomeRu: Record<string, string> = {
  header: 'СТАРТ КАРЬЕРЫ',
  subHeader: 'Работа, подработка, стажировка для студентов и молодых специалистов',
  buttonFind: 'Найти',
  buttonFindPlaceholder: 'Поиск по вакансиям',
  newOffers: 'Новые предложения',
  recommendetVacancies: 'Рекомендуемые вакансии',
  buttonLoadMore: 'Загрузить больше',
  workWithBest: 'Работай у лучших',
  workWithBestText: 'Крупнейшие работодатели',
  vacancies: 'вакансии',
  downloadAndEnjoy: 'СКАЧАЙТЕ И НАСЛАЖДАЙТЕСЬ',
  downloadAppText: 'Загрузите приложение KOR.TJ для поиска стажёрство и работы',
  downloadAppSubText:
    'Просмотрите тысячи вакансий и найдите подходящую. Просто скачайте приложение и зарегестрируйтесь.',
  yourCompanyTitle: 'Не хватает вашей компании?',
  yourCompanySubTitle: 'Создадим страницу и привлечем лучших молодых специалистов?',
  email: 'email',
  phone: 'Номер телефона',
  name: 'Название компании',
  buttonOrderCall: 'Заказать звонок',
  validField: 'Поле обязательно для заполнение',
  showPerPage: 'Показать за страницу',
};
