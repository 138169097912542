<template>
  <Modal
    :cancel-button-text="t('globalTs.cancel')"
    :submit-button-text="t('save')"
    @submit="saveData"
    @cancel="onCancel"
    v-model="modalValue"
    :title="t('workExperience')"
  >
    <div>
      <Input
        class="w-full mb-4"
        :error-text="t('requiredField')"
        :is-error="isSubmitted && v$.companyName.$invalid"
        v-model="data.companyName"
        :label="t('company')"
        placeholder="Tages"
      />
      <Input
        class="w-full mb-4"
        :error-text="t('requiredField')"
        :is-error="isSubmitted && v$.position.$invalid"
        v-model="data.position"
        :label="t('position')"
        placeholder="Дизайнер"
      />
      <div class="flex flex-col md:flex-row items-end justify-between gap-8 mt-4">
        <div class="w-full">
          <label class="block text-[15px] text-black font-normal mb-1.5">{{ t('yearFrom') }}</label>
          <VueDatePicker
            auto-apply
            :start-date="new Date('01.01.1970')"
            year-picker
            v-model="data.startDate"
            format="yyyy"
            name="2013"
          />
          <p class="text-red" v-if="isSubmitted && v$.startDate.$invalid">{{ t('validationError') }}</p>
        </div>
        <div class="w-full">
          <label class="block text-[15px] text-black font-normal mb-1.5">{{ t('yearTo') }}</label>
          <VueDatePicker
            auto-apply
            :start-date="new Date('01.01.1970')"
            year-picker
            v-model="data.endDate"
            format="yyyy"
            :name="t('yearTo')"
          />
          <p class="text-red" v-if="isSubmitted && data.endDate && +data.endDate < +data.startDate">
            {{ t('validationError') }}
          </p>
        </div>
        <Checkbox v-model="data.rightNow" class="w-full mb-4" :label="t('applicantPage.stillWorking')" />
      </div>
      <div class="mt-4">
        <Textarea
          :error-text="t('requiredField')"
          :is-error="isSubmitted && v$.description.$invalid"
          :placeholder="`${t('description')}...`"
          :label="t('description')"
          class="w-full"
          v-model="data.description"
        />
      </div>
    </div>
  </Modal>
</template>
<script setup lang="ts">
  import Modal from '@/components/ui/Modal.vue';
  import { watch, ref, reactive, computed } from 'vue';
  import { required } from '@vuelidate/validators';
  import { useVuelidate } from '@vuelidate/core';
  import Input from '@/components/ui/Input.vue';
  import VueDatePicker from '@vuepic/vue-datepicker';
  import Textarea from '@/components/ui/Textarea.vue';
  import Checkbox from '@/components/ui/Checkbox.vue';
  import { editWorkExperienceRequest } from '@/services/applicant';
  import { maxMinDateValidation } from '@/utils/dateValidation';
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n();

  const props = defineProps({
    modal: Boolean,
    applicantId: { type: String, default: '' },
    dataFromParent: { type: Object, default: () => {} },
  });
  const emit = defineEmits(['updateModalValue', 'created']);

  const modalValue = ref(false);
  const isSubmitted = ref(false);
  const loading = ref(false);
  const elementsCopy = ref();
  let data = reactive({
    position: '',
    companyName: '',
    description: '',
    startDate: '',
    endDate: '',
    rightNow: false,
  });

  const rules = computed(() => ({
    position: { required },
    companyName: { required },
    description: { required },
    startDate: { required, maxMinDateValidation },
  }));

  watch(
    () => [props.modal, props.dataFromParent],
    () => {
      fillDataFromProps();
    },
    { immediate: true, deep: true }
  );

  function fillDataFromProps() {
    modalValue.value = JSON.parse(JSON.stringify(props.modal || false));
    if (props.dataFromParent) {
      elementsCopy.value = JSON.parse(JSON.stringify(props.dataFromParent));
      data.position = elementsCopy.value.position;
      data.companyName = elementsCopy.value.companyName;
      data.description = elementsCopy.value.description;
      data.startDate = elementsCopy.value.startDate;
      data.endDate = elementsCopy.value.endDate;
    }
  }

  function onCancel(value: any) {
    data = {
      position: '',
      companyName: '',
      description: '',
      startDate: '',
      endDate: '',
      rightNow: false,
    };
    emit('updateModalValue', value);
  }

  function saveData() {
    isSubmitted.value = true;
    if (v$.value.$invalid) {
      v$.value.$touch();
      return;
    }

    if (data.endDate && +data.endDate < +data.startDate) {
      return;
    }

    data.rightNow = !data.endDate;
    data.endDate = data.endDate ? data.endDate : new Date().getFullYear().toString();
    data.endDate = data.endDate.toString();
    data.startDate = data.startDate.toString();
    loading.value = true;

    editWorkExperienceRequest(elementsCopy.value.id, data)
      .then(() => {
        emit('created');
        onCancel(false);
      })
      .finally(() => {
        isSubmitted.value = false;
        loading.value = false;
      });
  }

  const v$ = useVuelidate(rules, data);
</script>

<style scoped></style>
