<template>
  <div class="container py-[72px] h-full">
    <h1 class="text-3xl font-medium text-center">{{ t('frequentlyAskedQuestions') }}</h1>
    <div class="text-center pt-4">
      <RouterLink to="/" class="text-md">{{ t('home') }}</RouterLink> /
      <span>{{ t('faq') }}</span>
    </div>
    <div class="py-[74px] flex flex-col items-center space-y-[60px]">
      <div class="max-w-[780px] w-full">
        <p class="md:text-2xl text-xl font-medium mb-4">{{ t('payments') }}</p>
        <div class="space-y-5">
          <div
            v-for="(item, index) in paymentsQuestions"
            :key="index"
            class="cursor-pointer max-w-[780px] border border-grey-100 rounded-lg"
            @click="selectedPaymentQuestionIndex = index"
          >
            <div
              class="p-6 flex justify-between items-center"
              :class="{ 'border-b border-grey-100': selectedPaymentQuestionIndex === index }"
            >
              <p
                class="font-medium md:text-md text-sm"
                :class="{ '!text-primary-500': selectedPaymentQuestionIndex === index }"
              >
                {{ item.title[currentLocale] }}
              </p>
              <SvgIcon name="minus" v-if="selectedPaymentQuestionIndex === index" />
              <SvgIcon name="plus" v-else />
            </div>
            <Transition name="drop">
              <div v-if="selectedPaymentQuestionIndex === index" class="overflow-hidden">
                <p v-for="(answer, subIndex) in item.answers" :key="subIndex" class="text-grey-500 p-6">
                  {{ answer }}
                </p>
              </div>
            </Transition>
          </div>
        </div>
      </div>
      <div class="max-w-[780px] w-full">
        <p class="md:text-2xl text-xl font-medium mb-4">{{ t('suggestions') }}</p>
        <div class="space-y-5">
          <div
            v-for="(item, index) in paymentsQuestions"
            :key="index"
            class="cursor-pointer max-w-[780px] border border-grey-100 rounded-lg"
            @click="selectedSuggestionQuestionIndex = index"
          >
            <div
              class="p-6 flex justify-between items-center"
              :class="{ 'border-b border-grey-100': selectedSuggestionQuestionIndex === index }"
            >
              <p
                class="font-medium md:text-md text-sm"
                :class="{ '!text-primary-500': selectedSuggestionQuestionIndex === index }"
              >
                {{ item.title[currentLocale] }}
              </p>
              <SvgIcon name="minus" v-if="selectedSuggestionQuestionIndex === index" />
              <SvgIcon name="plus" v-else />
            </div>
            <Transition name="drop">
              <div v-if="selectedSuggestionQuestionIndex === index" class="overflow-hidden">
                <p v-for="(answer, subIndex) in item.answers" :key="subIndex" class="text-grey-500 p-6">
                  {{ answer }}
                </p>
              </div>
            </Transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { computed, ref } from 'vue';
  import SvgIcon from '@/components/ui/SvgIcon.vue';
  import { useI18n } from 'vue-i18n';

  type TQuestions = {
    title: {
      ru: string;
      en: string;
      tj: string;
    };
    answers: string[];
  };

  const { t, locale } = useI18n();

  const selectedPaymentQuestionIndex = ref<number>(0);
  const selectedSuggestionQuestionIndex = ref<number | null>(null);
  const paymentsQuestions = ref<TQuestions[]>([
    {
      title: {
        ru: "Why won't my payment go through?",
        en: "Why won't my payment go through?",
        tj: "Why won't my payment go through?",
      },
      answers: [
        'Pharetra nulla ullamcorper sit lectus. Fermentum mauris pellentesque nec nibh sed et, vel diam, massa. Placerat quis vel fames interdum urna lobortis sagittis sed pretium. Aliquam eget posuere sit enim elementum nulla vulputate magna. Morbi sed arcu proin quis tortor non risus. ',
        'Elementum lectus a porta commodo suspendisse arcu, aliquam lectus faucibus. Nisl malesuada tortor, ligula aliquet felis vitae enim. Mi augue aliquet mauris non elementum tincidunt eget facilisi. Pellentesque massa ipsum tempus vel aliquam massa eu pulvinar eget. ',
      ],
    },
    {
      title: {
        ru: 'How do I get a refund?',
        en: 'How do I get a refund?',
        tj: 'How do I get a refund?',
      },
      answers: [
        'Pharetra nulla ullamcorper sit lectus. Fermentum mauris pellentesque nec nibh sed et, vel diam, massa. Placerat quis vel fames interdum urna lobortis sagittis sed pretium. Aliquam eget posuere sit enim elementum nulla vulputate magna. Morbi sed arcu proin quis tortor non risus. ',
        'Elementum lectus a porta commodo suspendisse arcu, aliquam lectus faucibus. Nisl malesuada tortor, ligula aliquet felis vitae enim. Mi augue aliquet mauris non elementum tincidunt eget facilisi. Pellentesque massa ipsum tempus vel aliquam massa eu pulvinar eget. ',
      ],
    },
    {
      title: {
        ru: 'How do I redeem a coupon?',
        en: 'How do I redeem a coupon?',
        tj: 'How do I redeem a coupon?',
      },
      answers: [
        'Pharetra nulla ullamcorper sit lectus. Fermentum mauris pellentesque nec nibh sed et, vel diam, massa. Placerat quis vel fames interdum urna lobortis sagittis sed pretium. Aliquam eget posuere sit enim elementum nulla vulputate magna. Morbi sed arcu proin quis tortor non risus. ',
        'Elementum lectus a porta commodo suspendisse arcu, aliquam lectus faucibus. Nisl malesuada tortor, ligula aliquet felis vitae enim. Mi augue aliquet mauris non elementum tincidunt eget facilisi. Pellentesque massa ipsum tempus vel aliquam massa eu pulvinar eget. ',
      ],
    },
    {
      title: {
        ru: 'Changing account name',
        en: 'Changing account name',
        tj: 'Changing account name',
      },
      answers: [
        'Pharetra nulla ullamcorper sit lectus. Fermentum mauris pellentesque nec nibh sed et, vel diam, massa. Placerat quis vel fames interdum urna lobortis sagittis sed pretium. Aliquam eget posuere sit enim elementum nulla vulputate magna. Morbi sed arcu proin quis tortor non risus. ',
        'Elementum lectus a porta commodo suspendisse arcu, aliquam lectus faucibus. Nisl malesuada tortor, ligula aliquet felis vitae enim. Mi augue aliquet mauris non elementum tincidunt eget facilisi. Pellentesque massa ipsum tempus vel aliquam massa eu pulvinar eget. ',
      ],
    },
  ]);

  const currentLocale = computed<'ru' | 'en' | 'tj'>(() => locale.value as 'ru' | 'tj' | 'en');
</script>
