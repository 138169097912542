import type { IIndustry } from '@/types/industries';
import { apiClient } from './apiClient';
import { type TResponse } from '@/types/global';

type TGetIndustriesResponse = {
  page: number;
  size: number;
  totalCount: number;
  totalPages: number;
  items: IIndustry[];
};

export const getIndustriesRequest = async () => {
  const { data } = await apiClient.get<TResponse<TGetIndustriesResponse>>('industries');
  return data;
};
