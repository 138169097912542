import axios from 'axios';
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { useCookies } from 'vue3-cookies';

interface ErrorStatus {
  [key: number]: () => void;
}

const { cookies } = useCookies();

const apiClient = axios.create({
  //@ts-ignore
  baseURL: `${import.meta.env.VITE_BASE_URL}/api`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

apiClient.defaults.withCredentials = true;

const generateErrorToaster = (error: any) => {
  if (!error) {
    return;
  }

  toast.error(error?.messages?.default || 'Ошибка, попробуйте ещё раз', {
    autoClose: 3000,
  });
};

const authInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const authToken = cookies?.get('token');
  if (authToken) {
    config.headers['Authorization'] = `bearer ${authToken}`;
  }
  return config;
};

const handleAuthenticationError = (): void => {
  cookies.remove('token');
  toast.error('Время авторизации закончилось', {
    autoClose: 3000,
  });
};

const errorStatus: ErrorStatus = {
  403: handleAuthenticationError,
};

const errorInterceptor = (error: AxiosError): Promise<AxiosError> => {
  console.error(error);
  if (error.response?.status && error.response.status in errorStatus) {
    errorStatus[error.response.status]();
  } else {
    generateErrorToaster(error?.response?.data);
  }
  return Promise.reject(error);
};

const responseInterceptor = (response: AxiosResponse): AxiosResponse => response;

apiClient.interceptors.response.use(responseInterceptor, errorInterceptor);
apiClient.interceptors.request.use(authInterceptor);

export { apiClient };
