<template>
  <main v-if="company">
    <section class="py-20 bg-[#F5F7FC]">
      <div class="flex flex-wrap justify-between container">
        <div class="flex items-center gap-5">
          <img :src="company.logo" :alt="company.name" class="w-28 h-28 object-contain rounded-lg overflow-hidden" />
          <div>
            <div>
              <h1 class="text-2xl font-medium text-black">
                {{ company.name.length > 30 ? company.name.slice(0, 30) + '...' : company.name }}
              </h1>
              <div>
                <div class="flex items-center flex-wrap gap-x-6 md:gap-x-8 gap-y-3 mt-1.5">
                  <div class="flex items-center gap-2.5">
                    <SvgIcon name="location-marker" class="text-grey-400" />
                    <span class="text-sm font-normal text-grey-400">
                      {{ company.region[`name${localePostfix}`] }}
                    </span>
                  </div>
                  <div class="flex items-center gap-2.5">
                    <SvgIcon name="briefcase-outline" class="text-grey-400" />
                    <span class="text-sm font-normal text-grey-400">
                      {{ company.industry[`name${localePostfix}`] }}
                    </span>
                  </div>
                  <div class="flex items-center gap-2.5">
                    <SvgIcon name="phone" class="text-grey-400" />
                    <span class="text-sm font-normal text-grey-400">
                      {{ company.phone }}
                    </span>
                  </div>
                  <div class="flex items-center gap-2.5">
                    <SvgIcon name="mail" class="text-grey-400" />
                    <span class="text-sm font-normal text-grey-400">
                      {{ company.email }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="hidden md:inline-block px-5 py-2.5 mt-4 rounded-full bg-[#E4EFFF] text-[#1967D2]">
                <router-link :to="{ name: 'search-vacancies', query: { searchText: company.name } }">
                  {{ t('companiesPage.openPositionsCount', { count: company.vacancyCount || 0 }) }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3 md:mt-0">
          <div class="flex gap-5 items-center">
            <div
              class="md:hidden inline-block px-5 text-xs md:text-md py-2.5 leading-5 rounded-full bg-[#E4EFFF] text-[#1967D2]"
            >
              <router-link :to="{ name: 'search-vacancies', query: { searchText: company.name } }">
                {{ t('companiesPage.openPositionsCount', { count: company.vacancyCount || 0 }) }}
              </router-link>
            </div>
          </div>
          <div
            v-if="profileType === 'user' && !company.isSubscribe"
            @click="subscribe"
            class="flex mt-5 items-center gap-2 cursor-pointer"
          >
            <Spinner v-if="subscribeLoading" />
            <SvgIcon v-else name="feed-rectangle" class="text-[#1967D2]" />
            <span class="text-[15px] font-normal text-[#1967D2]">{{ t('companiesPage.signUpForUpdates') }}</span>
          </div>
        </div>
      </div>
    </section>
    <section class="bg-white md:px-52 md:py-12 px-6 py-6 grid grid-cols-1 md:grid-cols-12 gap-12">
      <div class="md:col-span-8 order-2 md:order-1">
        <p class="text-lg font-medium text-black">{{ t('companiesPage.aboutCompany') }}</p>
        <p class="text-[15px] font-normal text-grey-400">
          {{ company.description }}
        </p>
        <div v-if="companyVacancies.length">
          <h2 class="text-2xl font-medium text-black mt-14">
            {{ t('companiesPage.vacanciesIn', { name: company.name }) }}
          </h2>
          <div class="flex flex-col gap-8 mt-8">
            <div
              v-for="vacancy in companyVacancies"
              :key="vacancy.id"
              class="p-8 rounded-lg border border-[#ECEDF2] bg-white"
            >
              <!-- TODO: add link to vacancy detail page -->
              <div class="flex gap-6 relative">
                <img
                  :src="company.logo"
                  :alt="company.name"
                  class="w-14 h-14 object-contain rounded-lg overflow-hidden"
                />
                <div>
                  <p class="text-lg font-medium text-black">
                    {{ vacancy[`position${localePostfix}`] }}
                  </p>
                  <div class="flex items-center gap-8">
                    <div class="flex items-center gap-2.5">
                      <SvgIcon name="briefcase-outline" />
                      <span class="text-sm font-normal text-grey-400">
                        {{ company.name }}
                      </span>
                    </div>
                    <div class="flex items-center gap-2.5">
                      <SvgIcon name="location-marker" />
                      <span class="text-sm font-normal text-grey-400">
                        {{ vacancy.region?.[`name${localePostfix}`] }}
                      </span>
                    </div>

                    <div class="flex items-center gap-2.5">
                      <SvgIcon name="time" />
                      <span class="text-sm font-normal text-grey-400">
                        {{ readableDate(vacancy.createdAt, locale) }}
                      </span>
                    </div>
                    <div class="flex items-center gap-2.5">
                      <SvgIcon name="money" />
                      <span class="text-sm font-normal text-grey-400">
                        {{ vacancy.salaryCurrency }}
                      </span>
                    </div>
                  </div>
                </div>
                <button v-if="profile" class="p-2 absolute top-0 right-0" @click="addVacancyToFavorite(vacancy.id)">
                  <SvgIcon name="bookmark" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="md:col-span-4 order-1 md:order-2">
        <div class="p-8 flex flex-col gap-5 rounded-lg bg-[#F5F7FC]">
          <div v-if="company.companySphere?.[0]" class="flex justify-between">
            <span class="text-md font-medium text-black"> {{ t('scopeOfActivity') }}: </span>
            <span class="text-[15px] font-normal text-grey-400 truncate">
              {{ company.companySphere?.[0]?.[`name${localePostfix}`] || 'N/A' }}
            </span>
          </div>
          <div v-if="company.сompanyEmployeers" class="flex justify-between">
            <span class="text-md font-medium text-black"> {{ t('employeesNumber') }}: </span>
            <span class="text-[15px] font-normal text-grey-400">
              {{ company.сompanyEmployeers || 'N/A' }}
            </span>
          </div>
          <div v-if="company.companyInsertDate" class="flex justify-between">
            <span class="text-md font-medium text-black"> {{ t('foundedIn') }}: </span>
            <span class="text-[15px] font-normal text-grey-400">
              {{ readableDate(company.companyInsertDate, locale) || 'N/A' }}
            </span>
          </div>
          <div v-if="company.phone" class="flex justify-between">
            <span class="text-md font-medium text-black"> {{ t('phone') }}: </span>
            <span class="text-[15px] font-normal text-grey-400">
              {{ company.phone || 'N/A' }}
            </span>
          </div>
          <div v-if="company.email" class="flex justify-between">
            <span class="text-md font-medium text-black"> {{ t('email') }}: </span>
            <span class="text-[15px] font-normal text-grey-400">
              {{ company.email || 'N/A' }}
            </span>
          </div>
          <div v-if="company.region" class="flex justify-between">
            <span class="text-md font-medium text-black"> {{ t('region') }}: </span>
            <span class="text-[15px] font-normal text-grey-400">
              {{ company.region[`name${localePostfix}`] || 'N/A' }}
            </span>
          </div>
          <div v-if="company.socialNetwork" class="flex justify-between">
            <span class="text-md font-medium text-black"> {{ t('socialMedia') }}: </span>
            <div v-if="company.socialNetwork" class="text-[15px] font-normal text-grey-400 flex items-center gap-4">
              <a
                v-if="company.socialNetwork.facebook"
                :href="company.socialNetwork.facebook"
                rel="noopener noreferrer"
                target="_blank"
              >
                <SvgIcon name="facebook-logo" />
              </a>
              <a
                v-if="company.socialNetwork.telegramm"
                :href="company.socialNetwork.telegramm"
                rel="noopener noreferrer"
                target="_blank"
              >
                <SvgIcon class="w-4 h-4" name="icons8_telegram_app" />
              </a>
              <a
                v-if="company.socialNetwork.linkedin"
                :href="company.socialNetwork.linkedin"
                rel="noopener noreferrer"
                target="_blank"
              >
                <SvgIcon name="linkedin-logo" />
              </a>
              <a
                v-if="company.socialNetwork.whatsAPP"
                :href="company.socialNetwork.whatsAPP"
                rel="noopener noreferrer"
                target="_blank"
              >
                <SvgIcon class="w-4 h-4" name="icons8_whatsapp" />
              </a>
            </div>
            <span v-else class="text-[15px] font-normal text-grey-400"> N/A </span>
          </div>
          <div v-if="company.webSite">
            <a :href="`https://${company.webSite}`" rel="noopener noreferrer" target="_blank">
              <Button class="w-full">
                {{ company.webSite }}
              </Button>
            </a>
          </div>
        </div>
        <div v-if="companyPositionOnMap.lat && companyPositionOnMap.lng">
          <GoogleMap
            class="mt-2"
            api-key="AIzaSyB8bFrSy2jA0gZkmf9SUycVL26Lc00_UOI"
            style="width: 100%; height: 500px"
            :center="companyPositionOnMap"
            :zoom="15"
          >
            <Marker id="marker" :options="{ position: companyPositionOnMap }" />
          </GoogleMap>
        </div>
      </div>
    </section>
  </main>
</template>

<script setup lang="ts">
  import { computed, ref } from 'vue';
  import { RouterLink, useRoute } from 'vue-router';

  import { GoogleMap, Marker } from 'vue3-google-map';

  import { getCompanyById, subscribeToCompany } from '~/services/company';
  import type { ICompany } from '~/types/company';

  import SvgIcon from '~/components/ui/SvgIcon.vue';
  import Button from '~/components/ui/Button.vue';
  import { addVacancyToFavoriteRequest, getCompanyVacanciesRequest } from '~/services/vacancy';
  import type { IVacancy } from '~/types/vacancy';
  import { useCoreStore } from '~/stores/core';
  import { readableDate } from '~/utils/filters';
  import Spinner from '@/components/ui/Spinner.vue';
  import { useCookies } from 'vue3-cookies';
  import { toast } from 'vue3-toastify';
  import { getApplicantByProfileId } from '@/services/applicant';
  import { useI18n } from 'vue-i18n';

  const { t, locale } = useI18n();
  const { cookies } = useCookies();
  const route = useRoute();

  const profile = computed((): any => {
    return useCoreStore().profile;
  });

  const profileType = computed(() => {
    return cookies.get('profile-type');
  });

  const localePostfix = computed(() => {
    return locale.value === 'ru' ? 'Ru' : locale.value === 'en' ? 'En' : 'Tj';
  });

  const loading = ref(false);
  const subscribeLoading = ref(false);
  const company = ref<ICompany | null>(null);
  const companyVacancies = ref<IVacancy[]>([]);

  const companyPositionOnMap = computed(() => {
    return {
      lat: company.value?.contactInformation?.latitude ? +company.value?.contactInformation?.latitude : 0,
      lng: company.value?.contactInformation?.longitude ? +company.value?.contactInformation?.longitude : 0,
    };
  });

  getCompanyData();
  getCompanyVacancies();

  function subscribe() {
    subscribeLoading.value = true;
    getApplicantByProfileId(profile.value.profileId)
      .then(response => {
        const applicantId = response.data.payload.id;
        subscribeToCompany(company?.value?.companyId || '', applicantId).then(() => {
          toast.success('Успешно сохранено');
          getCompanyData();
        });
      })
      .finally(() => (subscribeLoading.value = false));
  }

  async function getCompanyData() {
    loading.value = true;
    const { data } = await getCompanyById(route.params?.id as string);
    company.value = data.payload;
    loading.value = false;
  }

  async function getCompanyVacancies() {
    const { payload } = await getCompanyVacanciesRequest(route.params?.id as string);
    companyVacancies.value = payload.items;
  }

  async function addVacancyToFavorite(vacancyId: string) {
    await addVacancyToFavoriteRequest(profile.value?.id || '', vacancyId);
  }
</script>
