import { apiClient } from '@/services/apiClient';
import { type ICompany } from '@/types/company';
import { type TResponsePayload, type TResponse } from '@/types/global';
export const createCompanyProfile = (companyId: string, params: any) =>
  apiClient.post(`company-profiles/${companyId}`, params);
export const updateCompanyProfile = (companyId: string, params: any) =>
  apiClient.put(`company-profiles/${companyId}`, params);
export const createCompanyPerson = (companyId: string, params: any) =>
  apiClient.post(`company-responsible-persons/${companyId}`, params);
export const updateCompanyPerson = (companyId: string, params: any) =>
  apiClient.put(`company-responsible-persons/${companyId}`, params);
export const createCompanySocialNetworks = (companyId: string, params: any) =>
  apiClient.post(`company-social-networks/${companyId}`, params);

export const updateCompanySocialNetworks = (companyId: string, params: any) =>
  apiClient.put(`company-social-networks/${companyId}`, params);

export const createCompanyContactInformation = (companyId: string, params: any) =>
  apiClient.post(`contact-informations/${companyId}`, params);
export const updateCompanyContactInformation = (companyId: string, params: any) =>
  apiClient.put(`contact-informations/${companyId}`, params);
export const getCompanyTypes = () => apiClient.get('/company-types');
export const getCompanyTypeItems = () => apiClient.get('/company-type-items');
export const getCompanyById = (id: string) => apiClient.get(`client/companies/${id}`);
export const getCompanyIndustries = () => apiClient.get('/industries');
export const getCompanyCategories = () => apiClient.get('/categories');
export const getSpecializationCategories = () => apiClient.get('/specialization-categories');
export const getSpecializations = (Category: string) =>
  apiClient.get('/client/specialization', { params: { page: 1, size: 100, Category } });
export const getCompanyScopes = (params: any) => apiClient.get('/company-spheres', { params });
export const getCompanyStatistics = (id: string) => apiClient.get(`/statistics/companies/${id}`);
export const getCompanyApplications = (params: any, id: string) =>
  apiClient.get(`/applied-vacancies/companies/${id}`, { params });

export const addApplicantToFavorite = (companyId: string, params: { applicantId: string }) =>
  apiClient.post(`company-favorite-applicants/${companyId}`, params);

export const getFavoriteApplicants = (companyId: string, params: any) =>
  apiClient.get(`company-favorite-applicants/${companyId}`, { params });

export const deleteApplicantFromFavorite = (companyId: string, applicantId: string) =>
  apiClient.delete(`company-favorite-applicants/${companyId}/${applicantId}`);
export const getCompaniesRequest = async (params?: any) => {
  const { data } = await apiClient.get<TResponse<TResponsePayload<ICompany>>>('client/companies', { params });
  return data;
};

export const subscribeToCompany = (companyId: string, applicantId: string) =>
  apiClient.post(`company-subscribes/${companyId}/${applicantId}`);

// export const getCompan
