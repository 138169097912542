<template>
  <div v-if="!loading && companies?.length">
    <div class="mx-auto max-w-7xl py-10 md:py-[120px] px-4 sm:px-6 lg:px-8">
      <div class="text-center">
        <h2 v-if="isStartPage" class="text-3xl mb-1">{{ t('startHome.workWithBest') }}</h2>
        <h2 v-else class="text-2xl mb-1">{{ t('startHome.workWithBestText') }}</h2>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mt-6 md:mt-10">
        <router-link
          :to="{ name: 'company-detail', params: { id: company.companyId } }"
          v-for="company in companies"
          :key="company.companyId"
          class="rounded-md bg-white p-8 hover:bg-grey-50/90"
        >
          <div class="flex justify-center">
            <img
              :src="company.logo"
              :alt="company.name"
              class="h-[120px] w-[120px] rounded-md shadow-sm border border-grey-50"
            />
          </div>
          <p class="text-lg mt-5 text-center">{{ company.name }}</p>
          <p class="text-sm text-grey-200 text-center mt-2">
            {{ company.vacancyCount || 0 }} - {{ t('startHome.vacancies') }}
          </p>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { ref } from 'vue';
  import type { ICompany } from '@/types/company';
  import { getCompaniesRequest } from '@/services/company';
  import { clearQueryParams } from '@/utils/common';
  import { useI18n } from 'vue-i18n';

  type TProps = {
    isStartPage?: boolean;
  };

  defineProps<TProps>();

  const { t } = useI18n();

  const loading = ref(false);
  const companies = ref<ICompany[]>([]);

  async function getCompanies() {
    loading.value = true;
    const data = await getCompaniesRequest(clearQueryParams({ size: 4, isLarge: true }));
    companies.value = data.payload.items;
    loading.value = false;
  }

  getCompanies();
</script>
