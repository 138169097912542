import type { ISpecializationCategory } from '@/types/specializationCategory';
import { apiClient } from './apiClient';
import type { TResponse } from '@/types/global';

type TGetSpecializationCategoriesResponse = {
  page: number;
  size: number;
  totalCount: number;
  totalPages: number;
  items: ISpecializationCategory[];
};

export const getSpecializationCategoriesRequest = async () => {
  const url = 'specialization-categories';
  const { data } = await apiClient.get<TResponse<TGetSpecializationCategoriesResponse>>(url);
  return data;
};
