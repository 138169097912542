import './assets/styles/main.css';
import '@vuepic/vue-datepicker/dist/main.css';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import 'vue3-toastify/dist/index.css';
import { createI18n } from 'vue-i18n';
import { messages } from '@/languages/languages';

import VueDatePicker from '@vuepic/vue-datepicker';
import Vue3Toastify, { type ToastContainerOptions } from 'vue3-toastify';
import VueCookie from 'vue3-cookies';

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';

const i18n = createI18n({
  legacy: false,
  locale: 'tj',
  locales: ['tj', 'ru', 'en'],
  fallbackLocale: 'en',
  messages,
});

const app = createApp(App);
app.use(router);
app.use(createPinia());
app.use(VueCookie);

app.use(i18n);
app.use(Vue3Toastify, {
  autoClose: 3000,
} as ToastContainerOptions);
app.component('DatePicker', VueDatePicker);
app.directive('click-outside', {
  beforeMount(el, binding) {
    const handleClick = (e: MouseEvent) => {
      if (!(el as HTMLElement).contains(e.target as Node)) {
        binding.value();
      }
    };

    document.addEventListener('click', handleClick);
    el._clickOutside = handleClick;
  },
  beforeUnmount(el) {
    document.removeEventListener('click', el._clickOutside);
  },
});

app.mount('#app');

export default app;
