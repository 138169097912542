<template>
  <button
    :type="type"
    :disabled="disabled || loading"
    :class="[
      'transition duration-200 text-[15px] cursor-pointer leading-5 rounded-lg flex items-center justify-center gap-2 relative',
      variants[variant],
      sizes[size],
      //@ts-ignore
      isActive ? activeClasses[variant] || activeClasses['primary'] : '',
    ]"
  >
    <Spinner class="mr-1" v-if="loading" :width="22" :height="22" />
    <slot />
  </button>
</template>

<script setup lang="ts">
  type TProps = {
    size?: keyof typeof sizes;
    variant?: keyof typeof variants;
    type?: 'button' | 'submit' | 'reset';
    disabled?: boolean;
    loading?: boolean;
    isActive?: boolean;
  };

  const sizes = {
    md: 'py-2.5 px-3 md:px-[82px] md:py-5',
    sm: '!px-[30px] !py-3',
    full: '!w-full !py-5',
  };

  const variants = {
    primary:
      'bg-primary-500 border-primary-500 hover:bg-primary-600 focus:bg-primary-600 active:bg-primary-700 text-white disabled:bg-primary-50 disabled:text-grey-100',
    softPrimary:
      'bg-primary-300 text-primary-500  hover:bg-primary-400 active:bg-primary-400 disabled:bg-primary-50 disabled:text-grey-100',
    light:
      'bg-primary-200 border border-primary-300 text-primary-500 hover:bg-primary-300 active:bg-primary-400 disabled:border-none disabled:bg-primary-50 disabled:text-grey-100',
    yellow:
      'bg-yellow-500 border border-yellow-600 text-white hover:bg-yellow-600 active:bg-yellow-800 disabled:border-none disabled:bg-primary-50 disabled:text-grey-100',
    primaryOutline:
      'border-primary-500 hover:bg-primary-600 active:bg-primary-700 hover:text-white text-[#002659] disabled:bg-primary-50 disabled:text-grey-100',
  };

  const activeClasses = {
    primary: 'bg-primary-600 text-white',
    light: 'bg-primary-300 text-primary-500',
    primaryOutline: 'bg-primary-600 !text-white',
    softPrimary: '',
  };

  withDefaults(defineProps<TProps>(), {
    size: 'md',
    variant: 'primary',
    type: 'submit',
  });

  import Spinner from './Spinner.vue';
</script>
