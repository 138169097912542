<template>
  <div class="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
    <div class="flex md:pt-20 justify-center md:bg-primary-200 h-auto">
      <div v-if="isSelectType" class="flex flex-col md:items-start items-center gap-7">
        <h3 class="text-3xl mt-8">{{ t('global.accountRegistration') }}</h3>
        <div class="p-10 h-auto border-1 rounded bg-white">
          <p>{{ t('global.chooseWhoAreYou') }}</p>
          <div class="mt-10 flex gap-2">
            <div
              @click="selectApplicant"
              class="cursor-pointer md:w-[250px] md:h-[218px] w-[150px] h-[140px] gap-6 bg-grey-50 rounded flex justify-center items-center hover:bg-primary-500 hover:text-white"
            >
              <div class="flex flex-col items-center gap-2">
                <SvgIcon name="user" />
                <p>{{ t('global.employee') }}</p>
              </div>
            </div>
            <div
              @click="selectEmployer"
              class="cursor-pointer md:w-[250px] md:h-[218px] w-[150px] h-[140px] gap-6 bg-grey-50 rounded flex justify-center items-center hover:bg-primary-500 hover:text-white"
            >
              <div class="flex flex-col items-center gap-2">
                <SvgIcon name="briefcase" />
                <p>{{ t('global.employer') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EmployerRegistration
        @toSelectType="
          () => {
            isApplicant = false;
            isEmployer = false;
          }
        "
        v-if="isEmployer"
      />
      <ApplicantRegistration
        @toSelectType="
          () => {
            isApplicant = false;
            isEmployer = false;
          }
        "
        v-if="isApplicant"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
  import SvgIcon from '@/components/ui/SvgIcon.vue';
  import ApplicantRegistration from './components/ApplicantRegistration.vue';
  import EmployerRegistration from './components/EmployerRegistration.vue';
  import { computed, ref } from 'vue';
  import { useCookies } from 'vue3-cookies';
  import { useI18n } from 'vue-i18n';

  const { cookies } = useCookies();

  const { t } = useI18n();

  const isApplicant = ref(false);
  const isEmployer = ref(false);
  const selectEmployer = () => {
    cookies.set('profile-type', 'company');
    isEmployer.value = true;
    isApplicant.value = false;
  };

  const isSelectType = computed(() => !isEmployer.value && !isApplicant.value);
  const selectApplicant = () => {
    cookies.set('profile-type', 'user');
    isApplicant.value = true;
    isEmployer.value = false;
  };
</script>
