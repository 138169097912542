<template>
  <router-link :to="{ name: 'vacancy-detail', params: { id: vacancy.id } }">
    <div
      class="relative flex space-x-4 bg-primary-50 md:bg-white p-5 md:p-7 rounded-md border border-[#ECEDF2] mb-6 w-full hover:bg-primary-150 cursor-pointer"
    >
      <img
        class="w-[50px] h-[50px] md:w-[65px] md:h-[65px] border border-[#ECEDF2] rounded-md object-contain"
        :src="vacancy.logo || userD"
        alt=""
      />
      <div>
        <h3 class="text-xl mb-1">{{ vacancy[`position${localePostfix}`] }}</h3>
        <div class="flex flex-wrap items-center gap-y-2 gap-x-6 w-full">
          <span class="flex text-grey-500 text-sm">
            <SvgIcon class="mr-1" name="portfolio" />
            {{ vacancy.companyName }}
          </span>
          <span v-if="vacancy.address" class="flex text-grey-500 text-sm">
            <SvgIcon class="mr-1" name="location" />
            {{ vacancy.address }}
          </span>
          <span class="flex text-grey-500 text-sm">
            <SvgIcon class="mr-1" name="time" />
            {{ readableDate(vacancy.createdAt, locale) }}
          </span>
          <span class="flex text-grey-500 text-sm">
            <SvgIcon class="mr-1" name="money" />
            {{ vacancy.salaryCurrency || t('globalTs.negotiable') }}
          </span>
        </div>
        <div class="flex flex-wrap gap-y-2 gap-x-4 mt-4 overflow-x-auto">
          <div
            v-for="(vacancySchedule, index) in vacancy.vacancySchdule"
            :key="index"
            :class="{
              'bg-primary-300 text-primary-500': !index,
              'bg-green-pale text-green': index,
            }"
            class="rounded-full px-4 py-0.5 whitespace-nowrap"
          >
            {{ vacancySchedule[`name${localePostfix}`] }}
          </div>
        </div>
      </div>
      <Spinner class="absolute top-4 right-4" v-if="loading" />
      <button
        v-else-if="profile"
        :disabled="vacancy?.isFavorite"
        :class="{ 'bg-grey-100/60': vacancy?.isFavorite }"
        class="p-2 absolute top-4 right-4 rounded-full hover:bg-grey-100/60 w-8 h-8 flex justify-center items-center"
        @click.prevent="addVacancyToFavorite(vacancy.id)"
      >
        <SvgIcon name="bookmark" />
      </button>
    </div>
  </router-link>
</template>
<script setup lang="ts">
  import { readableDate } from '@/utils/filters';
  import SvgIcon from '@/components/ui/SvgIcon.vue';
  import type { IVacancy } from '@/types/vacancy';
  import userD from '@/assets/images/userD.png';
  import { useI18n } from 'vue-i18n';
  import { computed, ref } from 'vue';
  import { addVacancyToFavoriteRequest } from '@/services/vacancy';
  import { useCoreStore } from '@/stores/core';
  import { toast } from 'vue3-toastify';
  import Spinner from '@/components/ui/Spinner.vue';

  type TProps = {
    vacancy: IVacancy;
  };

  const { t, locale } = useI18n();

  defineProps<TProps>();
  const emit = defineEmits(['updateData']);

  const loading = ref(false);

  const profile = computed(() => {
    return useCoreStore().profile;
  });

  const localePostfix = computed(() => {
    return locale.value === 'ru' ? 'Ru' : locale.value === 'en' ? 'En' : 'Tj';
  });

  async function addVacancyToFavorite(vacancyId: string) {
    loading.value = true;
    await addVacancyToFavoriteRequest(profile.value?.id || '', vacancyId)
      .then(() => {
        emit('updateData');
        toast.success('Успешно добавлено в избранные');
      })
      .finally(() => (loading.value = false));
  }
</script>
