export const companyHomeTj = {
  title: 'Вакансияи худро дар илова кунед',
  titleStart: 'Вакансияи худро дар',
  titleEnd: 'илова кунед',
  titleDescription:
    'Ва дар байни онҳое, ки мехоҳанд бо Шумо кор кунанд, коргаронро пайдо намоед. KOR.TJ-кори нави дӯстдоштаи шумо инҷост!',
  addVacancy: 'Иловаи вакансия',
  whichEmployee: 'Дар {kor.tj} чӣ гуна кормандон ҳастанд?',
  whichEmployeeStart: 'Дар',
  whichEmployeeEnd: ' чӣ гуна кормандон ҳастанд?',
  whichEmployeeDescription:
    'Интизори посух нашавед - дар байни {count} резюме коргари беҳтаринро худатонро пайдо кунед',
  templatesType: 'Намунаи ҳуҷҷатҳои зарурӣ',
  forEmployer: 'Барои корфармоён',
  forApplicant: 'Барои корҷӯён',
  chooseCategory: 'Категорияро интихоб кунед',
  toStartTitle: 'Ҷустуҷӯи кормандонро аз чӣ оғоз кардан лозим аст?',
  signUp: 'Бақайдгирӣ',
  getAccess: 'Дастрасӣ ба тамоми функсияҳои kor.tj',
  postJob: 'Иловаи вакансия',
  postJobDescription: 'Тамос ва посух аз корҷӯён',
  selectBest: 'Беҳтаринро интихоб кунед',
  selectBestDescription: 'Аз байни онҳое, ки аллакай бо шумо кор кардан мехоҳанд',
  startSelection: 'Интихобро дар kor.tj оғоз кунед',
  WhyPostVacancy: 'Барои чӣ дар kor.tj гузоштани ҷои холӣ беҳтарин интихоб аст?',
  whyPostVacancyDescription:
    'Вакансия - эълон дар бораи ҷустуҷӯи коргар аст. Тавсиф кунед, ки ба Шумо чӣ гуна коргар лозим аст ва аз байни беҳтарин посухдиҳандагонро интихоб кунед',
  postVacancyFree: 'Вакансияи худро ройгон ҷойгир кунед',
  whoWillRespond: 'Кӣ ҷавоб медиҳад?',
  cansTrustUs: 'Номзадҳо ба мо эътимод доранд',
  comesFirst: 'ҷои аввалро мегирад',
  SearchSites: 'дар байни сомонаҳои ҷустуҷӯи кор ва кадрҳо',
  candidateAlreadyHere: 'Номзади шумо аллакай дар ин ҷост',
  candidateDescription:
    '{count} резюме, ки дар kor.tj интишор шудааст. Ҳамарӯза ба kor.tj ҳазорҳо нафар ворид мешаванд',
  vettedCandidates: 'Номзадҳои сифат ва санҷидашуда',
  vettedCandidatesDescription: '100% корбарони kor.tj рақамҳои телефонро тасдиқ кардаанд',
  interestedCandidates: 'Номзадҳои фаъол ва манфиатдор',
  interestedCandidatesDescription:
    'Истифодабарандагон дар kor.tj назар ба дигар сомонаҳои ҷустуҷӯи кор ва кадрҳо вақти бештар сарф мекунанд',
  buttonFindPlaceholder: 'Ҷустуҷӯ аз рӯи по резюмеҳо',
};
