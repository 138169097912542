<template>
  <Modal
    @submit="saveData"
    @cancel="onCancel"
    v-model="modalValue"
    :title="t('awards')"
    :cancel-button-text="t('globalTs.cancel')"
    :submit-button-text="t('add')"
  >
    <div>
      <UIButtonGroup :selectedValue="currentTab" @selectButton="changeCurrentTab" :items="buttonGroupItems" />
      <div class="mt-16 flex flex-col md:flex-row md:items-center gap-8">
        <div class="w-full text-red max-w-[150px] max-h-[150px]">
          <Image class="rounded" :src="data.File || blankImage" alt="logo" />
        </div>
        <div>
          <label for="award-input" class="relative inline-block px-3.5 py-4 bg-primary-500 bg-opacity-5 cursor-pointer">
            <span class="text-primary-500 text-[15px] font-normal"> {{ t('chooseFile') }} </span>
            <input type="file" id="award-input" hidden :multiple="false" @change="coverSelectFile" />
          </label>
          <p class="mt-4 font-normal text-sm text-grey-400">
            {{ t('applicantPage.coverAlert') }}
          </p>
          <p v-if="isSubmitted && v$.File.$invalid" class="text-sm text-red font-normal">{{ t('requiredField') }}</p>
        </div>
      </div>
      <Input
        v-if="currentTab.id === 1"
        class="w-full mb-4 pt-4"
        :error-text="t('requiredField')"
        :is-error="isSubmitted && v$.nameTj.$invalid"
        v-model="data.nameTj"
        :label="t('title') + '*'"
        :placeholder="t('applicantPage.awardName')"
      />
      <Input
        v-if="currentTab.id === 2"
        class="w-full mb-4 pt-4"
        :error-text="t('requiredField')"
        :is-error="isSubmitted && v$.nameRu.$invalid"
        v-model="data.nameRu"
        :label="t('title') + '*'"
        :placeholder="t('applicantPage.awardName')"
      />
      <Input
        v-if="currentTab.id === 3"
        class="w-full mb-4 pt-4"
        :error-text="t('requiredField')"
        :is-error="isSubmitted && v$.nameEn.$invalid"
        v-model="data.nameEn"
        :label="t('title') + '*'"
        :placeholder="t('applicantPage.awardName')"
      />
      <div class="flex flex-col md:flex-row items-end justify-between gap-8 mt-4">
        <div class="w-full">
          <label class="block text-[15px] text-black font-normal mb-1.5">{{ t('year') + '*' }}</label>
          <VueDatePicker
            auto-apply
            :start-date="new Date('01.01.1970')"
            year-picker
            v-model="data.startDate"
            format="yyyy"
            name="2013"
          />
          <p class="text-red" v-if="isSubmitted && v$.startDate.$invalid">{{ t('requiredField') }}</p>
        </div>
      </div>
      <div class="mt-4">
        <Textarea
          :placeholder="`${t('description')}...`"
          :label="t('description')"
          class="w-full"
          v-model="data.description"
        />
      </div>
    </div>
  </Modal>
</template>
<script setup lang="ts">
  import Modal from '@/components/ui/Modal.vue';
  import { watch, ref, reactive, computed } from 'vue';
  import { required } from '@vuelidate/validators';
  import { useVuelidate } from '@vuelidate/core';
  import Textarea from '@/components/ui/Textarea.vue';
  import { createAwardRequest } from '@/services/applicant';
  import blankImage from '@/assets/images/blank-image.png';
  import Image from '@/components/ui/Image.vue';
  import Input from '@/components/ui/Input.vue';
  import UIButtonGroup from '@/components/ui/ButtonGroup.vue';
  import VueDatePicker from '@vuepic/vue-datepicker';
  import { useI18n } from 'vue-i18n';

  const { t, locale } = useI18n();

  const props = defineProps<{ modal: boolean; applicantId: string }>();
  const emit = defineEmits(['updateModalValue', 'created']);

  const modalValue = ref(false);
  const isSubmitted = ref(false);
  const loading = ref(false);
  let data = reactive<any>({
    File: null,
    nameTj: '',
    nameRu: '',
    nameEn: '',
    startDate: '',
    endDate: '',
    description: '',
  });
  const buttonGroupItems: { title: string; id: number; component?: any }[] = [
    { id: 1, title: 'Тоҷикӣ' },
    { id: 2, title: 'Русский' },
    { id: 3, title: 'English' },
  ];
  const currentTab = ref<{ title: string; id: number; component?: any }>(
    locale.value === 'tj' ? buttonGroupItems[0] : locale.value === 'ru' ? buttonGroupItems[1] : buttonGroupItems[2]
  );

  const changeCurrentTab = (data: { title: string; id: number; component: any }) => {
    currentTab.value = data;
  };

  const rules = computed(() => ({
    File: { required },
    startDate: { required },
    nameTj: { required },
    nameRu: { required },
    nameEn: { required },
  }));

  watch(
    () => props.modal,
    () => {
      modalValue.value = props.modal;
    }
  );

  function onCancel(value: any) {
    data = {
      File: null,
      nameTj: '',
      nameRu: '',
      nameEn: '',
      startDate: '',
      endDate: '',
      description: '',
    };
    emit('updateModalValue', value);
  }

  function coverSelectFile(event: Event) {
    if (event.target instanceof HTMLInputElement && event.target?.files?.length) {
      data.File = event.target.files[0] || null;
    }
  }

  function saveData() {
    isSubmitted.value = true;
    if (v$.value.$invalid) {
      v$.value.$touch();
      return;
    }

    const formData = new FormData();
    const profileKeys = Object.keys(data);
    profileKeys.forEach(key => {
      //@ts-ignore
      formData.append(key, data[key]);
    });

    loading.value = true;
    createAwardRequest(props.applicantId, formData)
      .then(() => {
        emit('created');
        onCancel(false);
      })
      .finally(() => {
        isSubmitted.value = false;
        loading.value = false;
      });
  }

  const v$ = useVuelidate(rules, data);
</script>

<style scoped></style>
