import { type TResponse, type TResponsePayload } from '@/types/global';
import { apiClient } from './apiClient';

import type { IFavoriteVacancy, INewVacancy, IVacancy } from '@/types/vacancy';

export type TGetVacanciesResponse = {
  page: number;
  size: number;
  totalCount: number;
  totalPages: number;
  items: IVacancy[];
};

type TGetFavoriteVacanciesResponse = {
  page: number;
  size: number;
  totalCount: number;
  totalPages: number;
  items: IFavoriteVacancy[];
};

export const getAllVacanciesRequest = async (params: any) => {
  const { data } = await apiClient.get<TResponse<TGetVacanciesResponse>>('client/vacancies', { params });
  return data;
};

export const createVacancyRequest = (companyProfileId: string, body: INewVacancy) =>
  apiClient.post(`vacancies/${companyProfileId}`, body);

export const getVacanciesRequest = async (companyProfileId: string, params?: any) => {
  const { data } = await apiClient.get<TResponse<TGetVacanciesResponse>>(
    `vacancies/company-profile/${companyProfileId}`,
    { params }
  );
  return data;
};

export const getVacancyByIdRequest = async (vacancyId: string) => {
  const { data } = await apiClient.get<TResponse<IVacancy>>(`client/vacancies/${vacancyId}`);
  return data;
};

export const updateVacancyRequest = (vacancyId: string, body: INewVacancy) => {
  return apiClient.put(`vacancies/${vacancyId}`, body);
};

export const removeVacancyRequest = (vacancyId: string) => {
  return apiClient.delete(`vacancies/${vacancyId}`);
};

export const getFavoriteVacanciesRequest = async (userId: string, params?: any) => {
  const { data } = await apiClient.get<TResponse<TGetFavoriteVacanciesResponse>>(`favorite-vacancies/users/${userId}`, {
    params,
  });
  return data;
};

export const addVacancyToFavoriteRequest = (userId: string, vacancyId: string) =>
  apiClient.post(`favorite-vacancies/users/${userId}`, { vacancyId });

export const removeFavoriteVacancyRequest = (vacancyId: string) => apiClient.delete(`favorite-vacancies/${vacancyId}`);

export const getCompanyVacanciesRequest = async (companyId: string) => {
  const { data } = await apiClient.get<TResponse<TResponsePayload<IVacancy>>>(`vacancies/company-profile/${companyId}`);
  return data;
};

export const applyToVacancy = (vacancyId: string, applicantId: string) => {
  return apiClient.post(`applied-vacancies/applicants/${applicantId}`, { vacancyId: vacancyId });
};

export const checkVacancyForApply = (vacancyId: string, applicantId: string) => {
  return apiClient.get(`client/vacancies/is-applied-vacancies/${vacancyId}/${applicantId}`);
};
