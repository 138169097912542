<script setup lang="ts">
  import { defineAsyncComponent } from 'vue';

  type TProps = {
    name: string;
  };

  const props = defineProps<TProps>();
  //@ts-ignore
  const DynamicComponent = defineAsyncComponent(() => import(`../../assets/icons/${props.name}.svg`));
</script>

<template>
  <div class="svg-container">
    <DynamicComponent v-bind="$attrs" />
  </div>
</template>
