import { apiClient } from './apiClient';
import { type TResponse } from '@/types/global';

type TGetEmploymentTypesResponse = {
  page: number;
  size: number;
  totalCount: number;
  totalPages: number;
  items: {
    'id': string;
    'nameTj': string;
    'nameRu': string;
    'nameEn': string;
    'explanation': string;
    'order': number;
    'createdAt': string;
    'updatedAt': string | null;
  }[];
};

export const getEmploymentTypesRequest = async () => {
  const { data } = await apiClient.get<TResponse<TGetEmploymentTypesResponse>>('employment-types');
  return data;
};
