export const homeRu = {
  header: 'Лучшие Вакансии ждут вас здесь',
  headerSubtitle: 'Найдите работу среди {vacanciesCount} вакансии',
  linkText: 'Я ищу сотрудника',
  createResume: 'Создать резюме',
  internship: 'Стажерство',
  studentWork: 'Трудоустройство для студентов',
  invalidWork: 'Трудоустройство для инвалидов',
  popularCategories: 'Популярные категории вакансий',
  allVacancies: 'Все вакансии',
  recommendedVacancies: 'Рекомендуемые вакансии',
  showMore: 'Загрузить больше',
  howItsWork: {
    title: 'Как это работает?',
    description: 'Прости шаги для того чтобы найти подходящую работу',
    register: 'Зарегистрируйтесь',
    registerDescription: 'Пройдите простую процедуру регистрации подтвердив свой номер телефона',
    createProfile: 'Создать профиль',
    createProfileDescription: 'Пройдите простую процедуру регистрации подтвердив свой номер телефона',
    resume: 'Загрузите резюме',
    resumeDescription: 'Если у Вас есть готовый резюме загрузите его если нет Вы можете создать резюме онлайн',
    applyToVacancy: 'Отправляйте отклики на вакансии',
    applyToVacancyDescription: 'Теперь вы можете отправлять отклики на понравившиеся вакансии',
  },
  markAsRead: 'Отметить все как прочитанное',
  find: 'Найти',
};
