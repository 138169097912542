<script setup lang="ts"></script>

<template>
  <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_5_11317)">
      <path
        d="M11.5673 15.0565H52.6958C52.6968 12.2169 50.3944 9.91446 47.5548 9.91547H6.42623C3.58659 9.91446 1.28415 12.2169 1.28516 15.0565V39.0524C1.28415 41.892 3.58659 44.194 6.42623 44.1935V20.1976C6.42522 17.358 8.72766 15.0555 11.5673 15.0565Z"
        fill="#D4E1F4"
      />
      <path
        d="M52.0666 13.9255L26.9908 27.9093L1.91506 13.9255C1.29402 13.581 0.51181 13.8055 0.167398 14.4265C-0.177013 15.0476 0.0474065 15.8303 0.668452 16.1747L26.3738 30.5054C26.5671 30.6113 26.7835 30.6686 27.0039 30.6726C27.2248 30.6751 27.4427 30.6174 27.6335 30.5054L53.3388 16.1747C53.9583 15.8293 54.1828 15.0481 53.8399 14.4265C53.4819 13.807 52.6957 13.5851 52.0666 13.9255Z"
        fill="#1AE5BE"
      />
      <path
        d="M47.5549 8.63025H6.42634C2.87779 8.63125 0.00150618 11.508 0 15.0566V39.0524C0.00150618 42.601 2.87779 45.4773 6.42634 45.4788H47.5549C51.1034 45.4773 53.9797 42.601 53.9812 39.0524V15.0566C53.9797 11.508 51.1034 8.63125 47.5549 8.63025ZM51.4107 39.0394C51.4047 41.1666 49.6816 42.8891 47.5549 42.8952H6.42634C4.29962 42.8891 2.57656 41.1666 2.57053 39.0394V15.0566C2.57656 12.9294 4.29962 11.2068 6.42634 11.2008H47.5549C49.6816 11.2068 51.4047 12.9294 51.4107 15.0566V39.0394Z"
        fill="#0635C9"
      />
    </g>
    <defs>
      <clipPath id="clip0_5_11317">
        <rect width="54" height="54" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped></style>
