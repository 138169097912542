<template>
  <section class="bg-white px-4 md:px-8 py-10 mt-8 rounded">
    <p>{{ t('applicantPage.myProfile') }}</p>
    <div class="flex justify-center" v-if="getLoading">
      <Spinner />
    </div>
    <div v-else>
      <p></p>
      <div class="mt-8 flex gap-8 items-center">
        <div class="flex flex-col md:flex-row md:items-center gap-8">
          <Image
            class="rounded-full object-contain w-full !max-w-[190px] !max-h-[190px]"
            :src="profile.File || blankImage"
            alt="cover"
          />
          <div>
            <label
              for="cover-input"
              class="relative inline-block px-3.5 py-4 bg-primary-500 bg-opacity-5 cursor-pointer"
            >
              <span class="text-primary-500 text-[15px] font-normal"> {{ t('applicantPage.selectCover') }} </span>
              <input type="file" id="cover-input" hidden :multiple="false" @change="coverSelectFile" />
            </label>
            <p class="mt-4 font-normal text-sm text-grey-400">
              {{ t('applicantPage.coverAlert') }}
            </p>
            <p v-if="isSubmitted && v$.File.$invalid" class="text-sm text-red font-normal">
              {{ t('requiredField') }}
            </p>
          </div>
        </div>
      </div>
      <div class="flex flex-col md:flex-row justify-between gap-8 mt-12">
        <Input class="w-full" v-model="userData.name" :label="t('name')" placeholder="Иван" />
        <Input class="w-full" v-model="userData.lastName" :label="t('lastName')" placeholder="Иванов" />
      </div>
      <div class="flex flex-col md:flex-row justify-between gap-8 mt-8">
        <Select
          :items="[
            { value: 1, title: t('male') },
            { value: 2, title: t('female') },
          ]"
          v-model="userData.ganderId"
          :label="t('gender')"
          class="w-full"
          :placeholder="t('choose')"
        />
        <div class="w-full">
          <label class="block text-[15px] text-black font-normal mb-1.5"> {{ t('birthDate') }} </label>
          <DatePicker auto-apply format="dd.MM.yyyy" placeholder="05.05.1998" v-model="userData.birthDate" />
        </div>
      </div>
      <div class="flex flex-col md:flex-row justify-between gap-8 mt-8">
        <Input
          class="w-full"
          v-model="userData.address"
          :label="t('address')"
          placeholder="ш. Душанбе, Сино, Алишер Навои 21/2"
        />
      </div>
      <div class="flex flex-col md:flex-row justify-between gap-8 mt-8">
        <Input
          class="w-full"
          :error-text="t('requiredField')"
          :is-error="isSubmitted && v$.Profession.$invalid"
          v-model="profile.Profession"
          :placeholder="t('position')"
          :label="t('position')"
        />
        <Input
          class="w-full"
          :error-text="t('requiredField')"
          :is-error="isSubmitted && v$.Email.$invalid"
          v-model="profile.Email"
          :label="t('emailAddress')"
          :placeholder="t('emailAddress')"
        />
      </div>
      <div class="flex flex-col md:flex-row justify-between gap-8 mt-8">
        <Input
          :error-text="t('requiredField')"
          :is-error="isSubmitted && v$.CurrentSalary.$invalid"
          class="w-full"
          v-model="profile.CurrentSalary"
          :label="t('applicantPage.currentSalary')"
          placeholder="10 000"
        />
        <Input
          :error-text="t('requiredField')"
          :is-error="isSubmitted && v$.ExpectedSalary.$invalid"
          class="w-full"
          v-model="profile.ExpectedSalary"
          :label="t('applicantPage.desiredSalary')"
          placeholder="10 000"
        />
      </div>

      <div class="flex flex-col md:flex-row justify-between gap-8 mt-8">
        <Select
          :items="EXPERIENCE_YEARS_LIST"
          class="w-full"
          :error-text="t('requiredField')"
          :is-error="isSubmitted && v$.WorkExperience.$invalid"
          v-model="profile.WorkExperience"
          :label="t('workExperience')"
          :placeholder="t('choose')"
        />
        <Select
          :error-text="t('requiredField')"
          :is-error="isSubmitted && v$.EducationLevels.$invalid"
          :items="educations"
          class="w-full"
          v-model="profile.EducationLevels"
          :label="t('educationLevel')"
          :placeholder="t('choose')"
        />
      </div>
      <div>
        <div v-for="(language, index) in languagesIds" :key="index">
          <div
            v-if="languagesIds.length > 0 && language?.languageId && language?.languageLevelId"
            class="flex flex-col md:flex-row justify-start items-center gap-8 mt-8"
          >
            <p>{{ allLanguages.find(item => item.value === language?.languageId)?.title }}</p>
            <p>{{ apps.find(item => item.value === language?.languageLevelId)?.title }}</p>

            <div class="flex gap-4">
              <SvgIcon
                @click.stop="removeLanguage(index, language?.languageId)"
                class="text-red cursor-pointer"
                :class="{ '!text-grey-500': index !== languagesIds.length - 1 }"
                name="trash"
              />
              <SvgIcon
                v-if="index === languagesIds.length - 1 && languagesIds.length < allLanguages.length"
                @click.stop="addLanguage(language.languageId)"
                class="text-primary-500 cursor-pointer"
                name="plus"
              />
            </div>
          </div>
          <div v-else class="flex flex-col md:flex-row justify-between items-center gap-8 mt-8">
            <Select
              :placeholder="t('choose')"
              :items="languages"
              class="w-full"
              v-model="language.languageId"
              :label="t('languages')"
            />
            <Select
              :items="apps"
              :class="[index === languagesIds.length - 1 ? 'w-[82%]' : 'w-[90%]']"
              v-model="language.languageLevelId"
              :label="t('languageLevels')"
              :placeholder="t('choose')"
            />
            <div class="mt-5 flex gap-4">
              <SvgIcon
                @click.stop="removeLanguage(index, null)"
                class="text-red cursor-pointer"
                :class="{ '!text-grey-500': index === 0 }"
                name="trash"
              />
              <SvgIcon
                v-if="index === languagesIds.length - 1 && language.languageId && language.languageLevelId"
                @click.stop="addLanguage(language.languageId)"
                class="text-primary-500 cursor-pointer"
                name="plus"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col md:flex-row justify-between gap-8 mt-8">
        <Select
          :items="specializationCategories"
          class="w-full"
          v-model="categoryId"
          :label="t('applicantPage.specialisationsCategories')"
          :placeholder="t('chooseCategory')"
        />
        <Multiselect
          :error-text="t('requiredField')"
          :is-error="isSubmitted && v$.SpecializationIds.$invalid"
          :items="specializations"
          class="w-full"
          v-model="profile.SpecializationIds"
          :label="t('applicantPage.specialisations')"
        />
      </div>
      <Select
        :items="[
          { title: t('yes'), value: true },
          { title: t('no'), value: false },
        ]"
        class="w-full pt-8"
        v-model="profile.AllowInSearch"
        :label="t('applicantPage.allowInSearchAndListing')"
        :placeholder="t('choose')"
      />
      <div class="mt-8 h-40">
        <p>{{ t('description') }}</p>
        <QuillEditor class="w-full" v-model:content="profile.About" contentType="html" />
      </div>

      <div class="mt-24 flex justify-start">
        <Button :loading="loading" :disabled="loading" @click="saveData" variant="primary">
          {{ t('save') }}
        </Button>
      </div>
    </div>

    <!--     crop-modal-->
    <Modal
      :cancel-button-text="t('globalTs.cancel')"
      :submit-button-text="t('save')"
      @submit="cropImage"
      v-model="cropModalState"
      :title="t('applicantPage.myProfile')"
    >
      <cropper ref="cropperRef" class="cropper" :stencil-component="CircleStencil" :src="image.src" />
    </Modal>
  </section>
</template>
<script setup lang="ts">
  import { ref, reactive, computed, onMounted, watch } from 'vue';
  import blankImage from '@/assets/images/blank-image.png';
  import Image from '@/components/ui/Image.vue';
  import Select from '@/components/ui/Select.vue';
  import Multiselect from '@/components/ui/Multiselect.vue';
  import Spinner from '@/components/ui/Spinner.vue';
  import Input from '@/components/ui/Input.vue';

  import { required, email } from '@vuelidate/validators';
  import { useVuelidate } from '@vuelidate/core';
  import { getSpecializationCategories } from '@/services/company';
  import Button from '@/components/ui/Button.vue';
  import { toast } from 'vue3-toastify';
  import { createApplicantProfile, updateApplicantProfile } from '@/services/applicant';
  import { getEducationsRequest } from '@/services/educations';
  import { getLanguages } from '@/services/languages';
  import { useI18n } from 'vue-i18n';
  import { getApps, updateApplicantUser } from '@/services/auth';
  import { QuillEditor } from '@vueup/vue-quill';
  import SvgIcon from '@/components/ui/SvgIcon.vue';
  import { getSpecializationsRequest } from '@/services/specialization';
  import Modal from '../../../../components/ui/Modal.vue';
  import { Cropper, CircleStencil } from 'vue-advanced-cropper';
  import 'vue-advanced-cropper/dist/style.css';

  const { t, locale } = useI18n();
  const emit = defineEmits(['created']);
  const props = defineProps<{ id: string; data: Record<string, string> | null }>();
  const getLoading = ref(false);
  const isSubmitted = ref(false);
  const loading = ref(false);
  const educations = ref<any[]>([]);
  const languages = ref<any[]>([]);
  const allLanguages = ref<any[]>([]);
  const apps = ref<any[]>([]);
  const EXPERIENCE_YEARS_LIST: { title: string; value: number }[] = [
    { title: '1', value: 1 },
    { title: '2', value: 2 },
    { title: '3', value: 3 },
    { title: '4', value: 4 },
    { title: '5', value: 5 },
    { title: '6', value: 6 },
    { title: '7', value: 7 },
    { title: '8', value: 8 },
    { title: '9', value: 9 },
    { title: '10', value: 10 },
    { title: '11', value: 11 },
    { title: '12', value: 12 },
    { title: '13', value: 13 },
    { title: '14', value: 14 },
    { title: '15', value: 15 },
    { title: '16', value: 16 },
    { title: '17', value: 17 },
    { title: '18', value: 18 },
    { title: '19', value: 19 },
    { title: '20', value: 20 },
    { title: '21', value: 21 },
    { title: '22', value: 22 },
    { title: '23', value: 23 },
    { title: '24', value: 24 },
    { title: '25', value: 25 },
  ];
  const categoryId = ref('');
  const specializationCategories = ref<any[]>([]);
  const specializations = ref<any[]>([]);
  const languagesIds = ref<{ languageId: string; languageLevelId: number | null }[]>([
    {
      languageId: '',
      languageLevelId: null,
    },
  ]);

  const userData = ref({
    name: '',
    lastName: '',
    ganderId: 0,
    birthDate: '',
    address: '',
  });

  const profile = reactive<any>({
    File: null,
    Profession: '',
    Email: '',
    CurrentSalary: '',
    ExpectedSalary: '',
    WorkExperience: '',
    EducationLevels: '',
    Languages: [],
    SpecializationIds: [],
    AllowInSearch: 'Да',
    About: '',
  });

  const cropModalState = ref(false);
  const cropperRef = ref();

  const image = reactive({
    src: '',
    type: '',
  });
  const cropImage = () => {
    const result = cropperRef.value.getResult();
    result.canvas.toBlob((blob: Blob) => {
      profile.File = new File([blob], 'fileName.jpg', { type: 'image/jpeg' });
    }, 'image/jpeg');
    cropModalState.value = false;
  };

  function coverSelectFile(event: Event) {
    if (event.target instanceof HTMLInputElement && event.target?.files?.length) {
      if (image.src) {
        URL.revokeObjectURL(image.src);
      }
      const blob = URL.createObjectURL(event.target?.files[0]);

      image.src = blob;
      image.type = event.target?.files[0].type;
      cropModalState.value = true;
    }
  }

  const rules = computed(() => ({
    File: { required },
    Profession: { required },
    Email: { required, email },
    CurrentSalary: { required },
    ExpectedSalary: { required },
    WorkExperience: { required },
    EducationLevels: { required },
    SpecializationIds: { required },
    About: { required },
  }));

  watch(
    () => props.data,
    () => {
      if (props.data) {
        const dataCopy = JSON.parse(JSON.stringify(props.data));
        profile.File = dataCopy.photo || null;
        profile.Email = dataCopy.email || '';
        profile.CurrentSalary = dataCopy.currentSalary || '';
        profile.AllowInSearch = dataCopy.allowInSearch || '';
        profile.ExpectedSalary = dataCopy.expectedSalary || '';
        profile.WorkExperience = dataCopy.workExperience || '';
        profile.EducationLevels = dataCopy.educationLevels || [];
        if (dataCopy.languages.length) {
          languagesIds.value = [];
          dataCopy.languages?.forEach((item: any) => {
            languagesIds.value.push({
              languageId: item.id,
              languageLevelId: item.languageLevelId,
            });
          });
        }
        categoryId.value = dataCopy.applicantSpecializations[0]?.categoryId || '';
        profile.SpecializationIds = dataCopy.applicantSpecializations?.map((item: any) => item?.id) || [];
        profile.Profession = dataCopy.profession || '';
        profile.About = dataCopy.about || '';
        userData.value.name = dataCopy.userVm.name;
        userData.value.lastName = dataCopy.userVm.lastName;
        userData.value.ganderId = dataCopy.userVm.ganderId;
        userData.value.birthDate = dataCopy.userVm.birthDate;
        userData.value.address = dataCopy.userVm.address;
      }
    },
    { immediate: true, deep: true }
  );

  const getSpecializationsHandler = () => {
    specializations.value = [];
    getLoading.value = true;
    getSpecializationsRequest({ Category: categoryId.value })
      .then((response: any) => {
        response &&
          response.payload.items.forEach((item: any) => {
            specializations.value.push({ value: item?.id, title: item?.nameRu });
          });
      })
      .finally(() => (getLoading.value = false));
  };

  watch(
    () => categoryId.value,
    () => {
      getSpecializationsHandler();
    },
    { immediate: true }
  );

  onMounted(async () => {
    await getLanguagesHandler();
    await getAppsHandler();
    await getEducationsHandler();
    await getSpecializationCategoriesHandler();
    await getSpecializationsHandler();
  });

  const getSpecializationCategoriesHandler = () => {
    getLoading.value = true;
    specializationCategories.value = [];
    getSpecializationCategories()
      .then((response: any) => {
        response.data &&
          response.data.payload.items.forEach((item: any) => {
            specializationCategories.value.push({ value: item?.id, title: item?.nameRu });
          });
      })
      .finally(() => (getLoading.value = false));
  };

  const getLanguagesHandler = () => {
    getLoading.value = true;
    getLanguages()
      .then((response: any) => {
        response.data &&
          response.data.payload.items.forEach((item: any) => {
            languages.value.push({
              value: item?.id,
              title: locale.value === 'ru' ? item?.nameRu : locale.value === 'en' ? item?.nameEn : item?.nameTj,
            });
            allLanguages.value.push({
              value: item?.id,
              title: locale.value === 'ru' ? item?.nameRu : locale.value === 'en' ? item?.nameEn : item?.nameTj,
            });

            if (props.data && props.data?.languages?.length) {
              //@ts-ignore
              props.data?.languages.forEach((item: any) => {
                languages.value = languages.value.filter((lang: any) => {
                  return lang.value !== item.id;
                });
              });
            }
          });
      })
      .finally(() => (getLoading.value = false));
  };

  const getAppsHandler = () => {
    getLoading.value = true;
    getApps()
      .then((response: any) => {
        response.data &&
          response.data.languageLevels.forEach((item: any) => {
            apps.value.push({
              value: item?.id,
              title: locale.value === 'ru' ? item?.nameRu : locale.value === 'en' ? item?.nameEn : item?.nameTj,
            });
          });
      })
      .finally(() => (getLoading.value = false));
  };

  const getEducationsHandler = () => {
    getLoading.value = true;
    getEducationsRequest()
      .then((response: any) => {
        response &&
          response.payload.forEach((item: any) => {
            educations.value.push({ value: item?.id, title: item?.nameTj });
          });
      })
      .finally(() => (getLoading.value = false));
  };

  const updateUserData = () => {
    if (!props.data) return;
    const dataCopy = JSON.parse(JSON.stringify(props.data));
    const data = {
      name: userData.value.name || dataCopy.userVm?.name,
      lastName: userData.value.lastName || dataCopy.userVm?.lastName,
      birthDate: userData.value.birthDate || dataCopy.userVm?.birthDate,
      ganderId: userData.value.ganderId || dataCopy.userVm?.ganderId,
      address: userData.value.address || dataCopy.userVm?.address,
    };
    updateApplicantUser(dataCopy?.userVm.profileId || '', data);
  };

  const saveData = () => {
    isSubmitted.value = true;
    if (v$.value.$invalid) {
      v$.value.$touch();
      return;
    }
    loading.value = true;
    profile.Languages = languagesIds.value;
    const formData = new FormData();
    const profileKeys = Object.keys(profile);
    profileKeys.forEach(key => {
      if (key === 'SpecializationIds') {
        profile[key].forEach((item: string, index: number) => {
          formData.append(`${key}[${index}]`, item);
        });
      } else if (key === 'Languages') {
        profile[key].forEach((item: any, index: number) => {
          Object.keys(item).forEach((it: string) => {
            formData.append(`Languages[${index}].${it}`, item[it]);
          });
        });
      } else {
        //@ts-ignore
        formData.append(key, profile[key]);
      }
    });
    //@ts-ignore
    if (props.data?.id) {
      updateApplicantProfile(props.data?.id, formData)
        .then(() => {
          updateUserData();
          emit('created');
          toast.success(`${t('dataSuccessfullyUpdated')}`);
        })
        .finally(() => {
          loading.value = false;
          isSubmitted.value = false;
        });
    } else {
      createApplicantProfile(props.id, formData)
        .then(() => {
          emit('created');
          toast.success(`${t('dataSuccessfullyUpdated')}`);
        })
        .finally(() => {
          loading.value = false;
          isSubmitted.value = false;
        });
    }
  };

  const addLanguage = (id: string) => {
    languages.value = languages.value.filter((language: any) => {
      return language.value !== id;
    });
    languagesIds.value.push({ languageId: '', languageLevelId: null });
  };
  const removeLanguage = (index: number, id: string | null) => {
    if (index !== languagesIds.value.length - 1) return;
    if (id) {
      allLanguages.value.forEach((item: any) => {
        if (item.value === id && !languages.value.find(item => item.value === id)) {
          languages.value.push(item);
        }
      });
    }
    languagesIds.value.splice(index, 1);
    if (!languagesIds.value.length) {
      languagesIds.value.push({
        languageId: '',
        languageLevelId: null,
      });
    }
  };

  const v$ = useVuelidate(rules, profile);
</script>
