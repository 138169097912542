<template>
  <div
    v-click-outside="
      () => {
        isDropdownOpen = false;
      }
    "
    :id="Math.random().toString()"
    class="relative"
  >
    <label v-if="label" class="block text-[15px] text-black font-normal">{{ label }}</label>
    <div
      :class="[
        'w-full px-3 mt-1.5 flex rounded-md justify-between items-center border-primary-50 bg-primary-50 hover:border focus:border focus:bg-white hover:border-primary-500 focus:border-primary-500 placeholder-grey-200 focus:ring-0 focus:ring-offset-0 text-[15px] text-grey-900 py-2 min-h-[52px] pr-2 cursor-pointer',
        {
          'border-red focus:!border-red': error && !disabled,
          'border-surface-300 bg-surface-400 border rounded-lg': disabled,
        },
        className,
      ]"
      @click="toggleDropdown"
    >
      <div v-if="selectedItems.length === 0" class="text-grey-200">{{ t('choose') }}</div>
      <div v-else>
        <div
          v-for="item in selectedItems"
          :key="item.value"
          class="inline-flex items-center gap-1 bg-primary-500 text-white px-2 py-1 m-1 rounded-full"
        >
          <span class="text-white">
            {{ item.title }}
          </span>
          <button @click="removeItem(item)" class="ml-1 focus:outline-none">
            <svg class="w-4 h-4 fill-white text-white" viewBox="0 0 24 24">
              <path
                d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
              />
            </svg>
          </button>
        </div>
      </div>
      <svg
        class="w-4 h-4 ml-2 text-gray-500"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
      </svg>
    </div>

    <div
      v-if="items.length"
      class="absolute left-0 right-0 z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg focus:outline-none max-h-0 overflow-auto"
      :class="{
        'max-h-[100vh] ring-1 ring-primary-500 ring-opacity-5': isDropdownOpen,
      }"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabindex="-1"
    >
      <div class="p-2">
        <input
          v-model="searchTerm"
          type="text"
          class="w-full p-2 border border-grey-100 rounded focus:border-primary-500 focus:outline-none"
          :placeholder="`${t('search')}...`"
        />
      </div>
      <div class="py-1" role="none">
        <a
          v-for="(item, index) in filteredItems"
          :key="index"
          href="#"
          class="text-gray-700 block px-4 py-2 text-sm"
          :class="{
            'bg-primary-500 text-white': isSelected(item),
            'rounded-b-md': index === filteredItems.length - 1,
          }"
          role="menuitem"
          tabindex="-1"
          @click.prevent="toggleItem(item)"
        >
          {{ item.title }}
        </a>
      </div>
    </div>

    <div v-if="error" class="mt-1 text-sm text-red">{{ error }}</div>
  </div>
</template>

<script setup lang="ts">
  import { computed, ref, watch } from 'vue';
  import { useI18n } from 'vue-i18n';
  const { t } = useI18n();

  type TItem = {
    title: string;
    value: string;
  };

  type TProps = {
    items: TItem[];
    label?: string;
    error?: string;
    modelValue: string[];
    className?: string;
    disabled?: boolean;
  };

  const props = defineProps<TProps>();
  const emit = defineEmits<{
    (e: 'update:modelValue', value: string[]): void;
  }>();

  const isDropdownOpen = ref(false);
  const selectedItems = ref<TItem[]>([]);
  const searchTerm = ref<string>('');

  const filteredItems = computed(() => {
    if (!searchTerm.value) {
      return props.items;
    }
    const normalizedSearchTerm = searchTerm.value.toLowerCase().trim();
    return props.items.filter(item => item.title.toLowerCase().includes(normalizedSearchTerm));
  });

  watch(
    () => props.modelValue,
    () => {
      selectedItems.value = props.items.filter(item => props.modelValue?.includes(item.value));
    },
    { immediate: true, deep: true }
  );
  watch(
    () => props.items,
    () => {
      if (selectedItems.value?.length) return;
      selectedItems.value = props.items.filter(item => props.modelValue?.includes(item.value));
    },
    { immediate: true, deep: true }
  );

  const toggleDropdown = () => {
    if (props.disabled) {
      return;
    }
    isDropdownOpen.value = !isDropdownOpen.value;
  };
  const toggleItem = (item: TItem) => {
    const index = selectedItems.value.findIndex(i => i.value === item.value);
    if (index === -1) {
      selectedItems.value.push(item);
    } else {
      selectedItems.value.splice(index, 1);
    }

    emit(
      'update:modelValue',
      selectedItems.value.map(i => i.value)
    );
  };
  const isSelected = (item: TItem) => {
    return selectedItems.value.some(i => i.value === item.value);
  };
  const removeItem = (item: TItem) => {
    const index = selectedItems.value.findIndex(i => i.value === item.value);
    if (index !== -1) {
      selectedItems.value.splice(index, 1);
    }

    emit(
      'update:modelValue',
      selectedItems.value.map(i => i.value)
    );
  };
</script>
