<template>
  <main class="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
    <h1 class="text-3xl font-medium text-[#202124]">{{ t('employerPage.favoriteResumes') }}</h1>
    <div class="mt-10 rounded-md p-8 bg-white">
      <div class="flex justify-between items-center">
        <p>{{ t('employerPage.favoriteResumes') }}</p>
        <Input v-model="searchParams.Query" :placeholder="t('search')" />
      </div>
      <div v-if="loading" class="mt-8 flex items-center justify-center">
        <Spinner />
      </div>
      <div v-else-if="applicants && applicants.length" class="mt-8">
        <div class="flex flex-col gap-8">
          <div
            v-for="applicant in applicants"
            :key="applicant.applicantId"
            class="border border-grey-100 rounded-md p-7 flex md:flex-row flex-col gap-5 items-end md:justify-between md:items-center"
          >
            <div class="flex md:items-center items-start gap-5">
              <Image
                class="rounded-full !h-[89px] !w-[89px] object-contain"
                :src="applicant.photo || addPhoto"
                alt="news"
              />
              <div class="flex flex-col gap-4">
                <div>
                  <h3 class="text-xl">{{ applicant.fullName }}</h3>
                  <div class="flex flex-col justify-start md:flex-row gap-6">
                    <span class="text-primary-500"> {{ applicant.position }}</span>
                    <span class="text-grey-200 flex items-center gap-2">
                      <SvgIcon name="location" /> {{ applicant.region[`name${localePostfix}`] || t('noData') }}
                    </span>
                    <span class="text-grey-200 flex items-center gap-2">
                      <SvgIcon name="money" /> {{ applicant.currentSalary }}
                    </span>
                  </div>
                </div>
                <div class="flex flex-row gap-2">
                  <Badge v-for="(skill, index) in applicant.professionalSkills" :key="index" variant="light" size="sm">
                    {{ skill[`professionalSkillsName${localePostfix}`] }}
                  </Badge>
                </div>
              </div>
            </div>
            <div class="flex gap-6 items-center">
              <Button size="sm" variant="softPrimary">
                <RouterLink :to="{ name: 'applicants-show', params: { id: applicant.applicantId } }">
                  {{ t('viewProfile') }}
                </RouterLink>
              </Button>
              <Spinner v-if="deleteLoading" />
              <button v-else @click="deleteApplicantFromFavoriteHandler(applicant.applicantId)">
                <SvgIcon name="blue-trash" class="text-[#1967D2]" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="mt-8 flex items-center justify-center p-8 border border-[#ECEDF2] bg-white rounded-lg">
        <p class="text-grey-200">{{ t('noData') }}</p>
      </div>
      <div class="mt-8">
        <Pagination
          v-model:modelValue="searchParams.page"
          v-model:pageSize="searchParams.size"
          :totalCount="meta.totalCount"
          :totalPage="meta.totalPages"
        />
      </div>
    </div>
  </main>
</template>

<script setup lang="ts">
  import { ref, watch, computed } from 'vue';
  import Pagination from '@/components/ui/Pagination.vue';
  import SvgIcon from '@/components/ui/SvgIcon.vue';
  import { useCoreStore } from '@/stores/core';
  import { deleteApplicantFromFavorite, getFavoriteApplicants } from '@/services/company';
  import Spinner from '@/components/ui/Spinner.vue';
  import addPhoto from '@/assets/images/add-photo.png';
  import Button from '@/components/ui/Button.vue';
  import Image from '@/components/ui/Image.vue';
  import Badge from '@/components/ui/Badge.vue';
  import Input from '@/components/ui/Input.vue';
  import { debounce } from 'lodash';
  import { useI18n } from 'vue-i18n';

  const { t, locale } = useI18n();

  const profile = computed(() => {
    return useCoreStore().profile;
  });

  const loading = ref(false);
  const deleteLoading = ref(false);

  const applicants = ref<TApplicant[]>([]);
  const searchParams = ref({
    page: 1,
    size: 6,
    Query: '',
  });
  const meta = ref({
    totalCount: 0,
    totalPages: 0,
  });

  watch(
    searchParams,
    debounce(() => {
      getFavoriteApplicantsHandler();
    }, 500),
    { deep: true }
  );
  getFavoriteApplicantsHandler();
  watch(
    () => profile,
    () => {
      getFavoriteApplicantsHandler();
    },
    { deep: true }
  );

  const localePostfix = computed(() => {
    return locale.value === 'ru' ? 'Ru' : locale.value === 'en' ? 'En' : 'Tj';
  });

  function getFavoriteApplicantsHandler() {
    loading.value = true;
    getFavoriteApplicants(profile.value?.companyId || '', searchParams.value)
      .then(response => {
        applicants.value = response.data.payload.items;
        //@ts-ignore
        meta.value.totalCount = response.data.payload.totalCount;
        //@ts-ignore
        meta.value.totalPages = response.data.payload.totalPages;
      })
      .finally(() => (loading.value = false));
  }
  async function deleteApplicantFromFavoriteHandler(applicantId: string) {
    deleteLoading.value = true;
    await deleteApplicantFromFavorite(profile.value?.companyId || '', applicantId)
      .then(() => {
        getFavoriteApplicantsHandler();
      })
      .finally(() => (deleteLoading.value = false));
  }
  type TApplicant = {
    applicantId: string;
    currentSalary: string;
    fullName: string;
    photo: string | null;
    position: string;
    professionalSkills: {
      createdAt: string | null;
      id: string;
      professionalSkillsNameEn: string;
      professionalSkillsNameRu: string;
      professionalSkillsNameTj: string;
    }[];
    region: {
      id: string | null;
      nameTj: string | null;
      nameRu: string | null;
      nameEn: string | null;
      parentId: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      sort: number | null;
      userId: string | null;
      parent: string | null;
    };
  };
</script>
