<template>
  <section class="bg-white px-4 md:px-8 py-10 mt-8 rounded">
    <div class="flex justify-center h-44 items-center" v-if="getLoading">
      <Spinner />
    </div>
    <div v-else>
      <div class="mt-10 flex gap-8 items-center">
        <div class="flex flex-col md:flex-row md:items-center gap-8">
          <div class="w-full text-red !max-w-[150px] !max-h-[150px]">
            <Image class="rounded !max-w-[150px] !max-h-[150px]" :src="profile.logo || blankImage" alt="logo" />
          </div>
          <div>
            <label
              for="logo-input"
              class="relative inline-block px-3.5 py-4 bg-primary-500 bg-opacity-5 cursor-pointer"
            >
              <span class="text-primary-500 text-[15px] font-normal"> {{ t('employerPage.chooseLogotype') }} </span>
              <input type="file" id="logo-input" hidden :multiple="false" @change="logoSelectHandler" />
            </label>
            <p class="mt-4 font-normal text-sm text-grey-400">
              {{ t('employerPage.chooseLogotypeDescription') }}
            </p>
            <p v-if="isSubmitted && v$.logo.$invalid" class="text-sm text-red font-normal">{{ t('requiredField') }}</p>
          </div>
        </div>
      </div>
      <div class="flex flex-col md:flex-row justify-between gap-8 mt-8">
        <Input
          class="w-full"
          :error-text="t('requiredField')"
          :is-error="isSubmitted && v$.companyName.$invalid"
          v-model="profile.companyName"
          :placeholder="t('employerPage.companyName')"
          :label="t('employerPage.companyName')"
        />
        <Input
          class="w-full"
          :error-text="t('requiredField')"
          :is-error="isSubmitted && v$.companyEmail.$invalid"
          v-model="profile.companyEmail"
          :placeholder="t('emailAddress')"
          :label="t('emailAddress')"
        />
      </div>
      <div class="flex flex-col md:flex-row justify-between gap-8 mt-8">
        <Input
          class="w-full"
          :error-text="`${t('requiredField')} (${t('format')} +992ххххххххх)`"
          :is-error="isSubmitted && v$.companyPhone.$invalid"
          v-model="profile.companyPhone"
          :label="t('phone')"
          :placeholder="t('phone')"
        />
        <Input class="w-full" v-model="profile.companyWebSite" :label="t('site')" :placeholder="t('site')" />
      </div>
      <div class="flex flex-col md:flex-row justify-between gap-8 mt-8">
        <div class="w-full">
          <label class="block text-[15px] text-black font-normal mb-1.5">{{ t('dateOfFoundation') }}</label>
          <VueDatePicker
            auto-apply
            class="bg-primary-50"
            v-model="profile.companyInsertDate"
            format="dd.MM.yyyy"
            :name="t('dateOfFoundation')"
            input-class-name="!bg-primary-50 !border-0"
            :max-date="new Date()"
          />
          <p class="text-red" v-if="isSubmitted && v$.companyInsertDate.$invalid">{{ t('requiredField') }}</p>
        </div>
        <Select
          :placeholder="t('choose')"
          :items="[
            { title: '10-20', value: '10-20' },
            { title: '21-50', value: '21-50' },
            { title: '51-100', value: '51-100' },
            { title: '101-1000', value: '101-1000' },
            { title: '1000+', value: '1000+' },
          ]"
          class="w-full"
          :error-text="t('requiredField')"
          :is-error="isSubmitted && v$.СompanyEmployeers.$invalid"
          v-model="profile.СompanyEmployeers"
          :label="t('employerPage.employeesCount')"
        />
      </div>
      <div class="flex flex-col md:flex-row justify-between gap-8 mt-8">
        <Select
          :placeholder="t('choose')"
          :error-text="t('requiredField')"
          :is-error="isSubmitted && v$.companyTypeId.$invalid"
          :items="companyTypes"
          class="w-full"
          v-model="profile.companyTypeId"
          :label="t('employerPage.typeOfEnterprise')"
        />
        <Select
          :placeholder="t('choose')"
          :error-text="t('requiredField')"
          :is-error="isSubmitted && v$.CompanyTypeItemId.$invalid"
          :items="companyTypeItems"
          class="w-full"
          v-model="profile.CompanyTypeItemId"
          :label="t('employerPage.typeOfEnterpriseItem')"
        />
      </div>
      <div class="flex flex-col md:flex-row justify-between gap-8 mt-8">
        <Select
          :placeholder="t('choose')"
          :error-text="t('requiredField')"
          :is-error="isSubmitted && v$.industryId.$invalid"
          :items="companyIndustries"
          class="w-full"
          v-model="profile.industryId"
          :label="t('employerPage.companyIndustry')"
        />
        <Multiselect
          :disabled="!profile.industryId.length"
          :error-text="t('requiredField')"
          :is-error="isSubmitted && v$.companySphereId.$invalid"
          :items="companyScopes"
          class="w-full"
          v-model="profile.companySphereId"
          :label="t('employerPage.companyScope')"
        />
      </div>
      <div class="mt-8">
        <Textarea
          :error-text="t('requiredField')"
          :is-error="isSubmitted && v$.description.$invalid"
          :placeholder="`${t('companiesPage.aboutCompany')}...`"
          :label="t('companiesPage.aboutCompany')"
          class="w-full"
          v-model="profile.description"
        />
      </div>

      <div class="mt-8 flex justify-start">
        <Button :loading="loading" :disabled="loading" @click="saveData" variant="primary">{{ t('save') }}</Button>
      </div>
    </div>
    <!--     crop-modal-->
    <Modal
      :cancel-button-text="t('globalTs.cancel')"
      :submit-button-text="t('save')"
      @submit="cropImage"
      v-model="cropModalState"
      :title="t('applicantPage.myProfile')"
    >
      <cropper ref="cropperRef" class="cropper" :stencil-component="CircleStencil" :src="image.src" />
    </Modal>
  </section>
</template>
<script setup lang="ts">
  import { ref, reactive, computed, onMounted, watch } from 'vue';
  import blankImage from '@/assets/images/blank-image.png';
  import Image from '@/components/ui/Image.vue';
  import Select from '@/components/ui/Select.vue';
  import Multiselect from '@/components/ui/Multiselect.vue';
  import VueDatePicker from '@vuepic/vue-datepicker';
  import Textarea from '@/components/ui/Textarea.vue';
  import Spinner from '@/components/ui/Spinner.vue';
  import Input from '@/components/ui/Input.vue';

  import { required } from '@vuelidate/validators';
  import { useVuelidate } from '@vuelidate/core';
  import {
    getCompanyTypes,
    getCompanyScopes,
    getCompanyIndustries,
    createCompanyProfile,
    updateCompanyProfile,
    getCompanyTypeItems,
  } from '@/services/company';
  import Button from '@/components/ui/Button.vue';
  import { toast } from 'vue3-toastify';
  import { useI18n } from 'vue-i18n';
  import { CircleStencil, Cropper } from 'vue-advanced-cropper';
  import Modal from '../../../../components/ui/Modal.vue';

  const { t, locale } = useI18n();

  const props = defineProps<{ companyId: string; data: any | null }>();

  const getLoading = ref(false);
  const isSubmitted = ref(false);
  const loading = ref(false);
  const companyTypes = ref<any[]>([]);
  const companyTypeItems = ref<any[]>([]);
  const companyScopes = ref<any[]>([]);
  const companyIndustries = ref<any[]>([]);

  const profile = reactive<any>({
    logo: null,
    companyName: '',
    companyEmail: '',
    companyPhone: '+992',
    companyWebSite: '',
    companyInsertDate: '',
    СompanyEmployeers: '',
    companySphereId: [],
    companyTypeId: '',
    CompanyTypeItemId: '',
    industryId: '',
    description: '',
  });

  const cropModalState = ref(false);
  const cropperRef = ref();

  const image = reactive({
    src: '',
    type: '',
  });

  const cropImage = () => {
    const result = cropperRef.value.getResult();
    result.canvas.toBlob((blob: Blob) => {
      profile.logo = new File([blob], 'fileName.jpg', { type: 'image/jpeg' });
    }, 'image/jpeg');
    cropModalState.value = false;
  };
  const mustBePhoneFormat = (value: string) => {
    return value.match('(\\+992)([0-9]{9}$)');
  };
  const rules = computed(() => ({
    logo: { required },
    companyEmail: { required },
    companyName: { required },
    companyPhone: { required, mustBePhoneFormat },
    companyInsertDate: { required },
    СompanyEmployeers: { required },
    companySphereId: { required },
    companyTypeId: { required },
    CompanyTypeItemId: { required },
    industryId: { required },
    description: { required },
  }));

  onMounted(() => {
    const dataCopy = JSON.parse(JSON.stringify(props.data));
    profile.logo = dataCopy?.logo || null;
    profile.companyEmail = dataCopy?.email || '';
    profile.companyName = dataCopy?.name || '';
    profile.companyPhone = dataCopy?.phone || '+992';
    profile.companyWebSite = dataCopy?.webSite || '';
    profile.companyInsertDate = dataCopy?.companyInsertDate || '';
    profile.СompanyEmployeers = dataCopy?.сompanyEmployeers || '';
    dataCopy?.companySphere?.forEach((item: any) => {
      profile.companySphereId.push(item.id);
    });
    profile.companyTypeId = dataCopy?.companyType.id || '';
    profile.CompanyTypeItemId = dataCopy?.companyTypeItem?.id || '';
    profile.industryId = dataCopy?.industry.id || '';
    profile.description = dataCopy?.description || '';
    getCompanyScopesHandler();
    getCompanyTypesHandler();
    getCompanyTypeItemsHandler();
    getCompanyIndustriesHandler();
  });

  function logoSelectHandler(event: Event) {
    if (event.target instanceof HTMLInputElement && event.target?.files?.length) {
      if (image.src) {
        URL.revokeObjectURL(image.src);
      }
      const blob = URL.createObjectURL(event.target?.files[0]);

      image.src = blob;
      image.type = event.target?.files[0].type;
      cropModalState.value = true;
    }
  }

  const getCompanyScopesHandler = () => {
    getCompanyScopes({ Industry: profile.industryId }).then((response: any) => {
      companyScopes.value = [];
      response.data &&
        response.data.payload.items.forEach((item: any) => {
          if (item.industry.id === profile.industryId) {
            companyScopes.value.push({
              value: item?.id,
              title: locale.value === 'ru' ? item?.nameRu : locale.value === 'tj' ? item?.nameTj : item?.nameEn,
            });
          }
        });
    });
  };
  const getCompanyTypesHandler = () => {
    getLoading.value = true;
    getCompanyTypes()
      .then((response: any) => {
        response.data &&
          response.data.payload.forEach((item: any) => {
            companyTypes.value.push({
              value: item?.id,
              title: locale.value === 'ru' ? item?.nameRu : locale.value === 'tj' ? item?.nameTj : item?.nameEn,
            });
          });
      })
      .finally(() => (getLoading.value = false));
  };

  const getCompanyTypeItemsHandler = () => {
    getLoading.value = true;
    getCompanyTypeItems()
      .then((response: any) => {
        response.data &&
          response.data.payload.forEach((item: any) => {
            companyTypeItems.value.push({
              value: item?.id,
              title: locale.value === 'ru' ? item?.nameRu : locale.value === 'tj' ? item?.nameTj : item?.nameEn,
            });
          });
      })
      .finally(() => (getLoading.value = false));
  };
  const getCompanyIndustriesHandler = () => {
    getLoading.value = true;
    getCompanyIndustries()
      .then((response: any) => {
        response.data &&
          response.data.payload.items.forEach((item: any) => {
            companyIndustries.value.push({
              value: item?.id,
              title: locale.value === 'ru' ? item?.nameRu : locale.value === 'tj' ? item?.nameTj : item?.nameEn,
            });
          });
      })
      .finally(() => (getLoading.value = false));
  };

  const saveData = () => {
    isSubmitted.value = true;
    if (v$.value.$invalid) {
      v$.value.$touch();
      return;
    }
    loading.value = true;

    const formData = new FormData();
    if (props.data && props.data.companyInsertDate !== profile.companyInsertDate) {
      profile.companyInsertDate = profile.companyInsertDate.toISOString();
    }
    const profileKeys = Object.keys(profile);
    profileKeys.forEach(key => {
      //@ts-ignore
      if (key === 'companySphereId') {
        profile[key].forEach((item: string, index: number) => {
          formData.append(`companySphereId[${index}]`, item);
        });
      } else {
        formData.append(key, profile[key]);
      }
    });
    //@ts-ignore
    if (props.data.companyProfileId) {
      updateCompanyProfile(props.companyId, formData)
        .then(() => {
          toast.success('Данные успешно обновлены');
        })
        .finally(() => {
          loading.value = false;
          isSubmitted.value = false;
        });
    } else {
      createCompanyProfile(props.companyId, formData)
        .then(() => {
          toast.success('Данные успешно сохранены');
        })
        .finally(() => {
          loading.value = false;
          isSubmitted.value = false;
        });
    }
  };

  const v$ = useVuelidate(rules, profile);

  watch(
    () => profile.industryId,
    () => {
      if (profile.industryId) getCompanyScopesHandler();
    }
  );
</script>

<style scoped></style>
