<template>
  <div class="w-[650px] bg-white my-24 p-10 rounded">
    <div v-if="step !== 4" class="flex justify-between">
      <h3 class="text-2xl">{{ t('authPage.employerRegistration') }}</h3>
      <SvgIcon name="briefcase" />
    </div>
    <hr v-if="step !== 4" class="text-grey-50 my-12" />
    <div v-if="step === 1">
      <div class="flex justify-between">
        <p class="font-extra-medium">{{ t('email') }}</p>
        <p class="text-grey-100 text-sm">{{ t('authPage.stepFromTo', { from: 1, to: 3 }) }}</p>
      </div>
      <div class="mb-4 mt-8">
        <Input
          :disabled="loading"
          :isError="step === 1 && isSubmitted && emailInvalid"
          :error-text="`${t('requiredField')} (${t('authPage.emailFormat')})`"
          v-model="email"
          type="email"
          :label="t('e-mail')"
          :placeholder="t('authPage.enterEmail')"
        />
      </div>
      <hr class="text-grey-50 my-12" />
      <div class="flex gap-6">
        <Button :disabled="loading" @click="$emit('toSelectType')" size="full" variant="light">{{ t('back') }}</Button>
        <Button :loading="loading" :disabled="loading" @click="submitStepOne" size="full" variant="primary">
          {{ t('continue') }}
        </Button>
      </div>
    </div>
    <div v-if="step === 2">
      <div class="flex justify-between">
        <p class="font-extra-medium">{{ t('authPage.enterThePasswordFromEmail') }}</p>
        <p class="text-grey-100 text-sm">{{ t('authPage.stepFromTo', { from: 2, to: 3 }) }}</p>
      </div>
      <div class="mb-8 mt-8">
        <Input
          :disabled="loading"
          :isError="step === 2 && isSubmitted && otpInvalid"
          :error-text="t('requiredField')"
          v-model="otp"
          :label="t('authPage.sentPasswordToEmail')"
          :placeholder="t('authPage.passwordFromEmail')"
        />
      </div>
      <div>
        <p v-if="timer !== 0">{{ t('authPage.codeExpiresIn') }} {{ formattedTime }}</p>
        <div class="flex justify-between" v-else>
          <p class="text-red">{{ t('authPage.codeExpired') }}</p>
          <p class="cursor-pointer text-primary-500 hover:text-primary-700" @click="sendOtpHandler">
            {{ t('authPage.resendTheCode') }}
          </p>
        </div>
      </div>
      <hr class="text-grey-50 my-12" />
      <div class="flex gap-6">
        <Button :disabled="loading" @click="step = 1" size="full" variant="light">{{ t('back') }}</Button>
        <Button :loading="loading" :disabled="loading" @click="submitStepTwo" size="full" variant="primary">
          {{ t('continue') }}
        </Button>
      </div>
    </div>
    <div v-if="step === 3">
      <div class="flex justify-between">
        <p class="font-extra-medium">{{ t('authPage.yourCompany') }}</p>
        <p class="text-grey-100 text-sm">{{ t('authPage.stepFromTo', { from: 3, to: 3 }) }}</p>
      </div>
      <div class="mt-8">
        <div class="mb-4">
          <Input
            :disabled="loading"
            :isError="step === 3 && isSubmitted && v$.name.$invalid"
            :error-text="t('requireField')"
            v-model="userData.name"
            :placeholder="t('employerPage.companyName')"
            :label="t('employerPage.companyName')"
          />
        </div>
        <div class="mb-4">
          <Select
            :items="regions"
            :disabled="loading"
            v-model="userData.regionId"
            :label="t('region')"
            :placeholder="t('selectRegion')"
            @update:model-value="getCitiesHandler"
          />
        </div>
        <div class="mb-4">
          <Select
            :disabled="!userData.regionId"
            class="w-full"
            :error-text="t('requiredField')"
            :is-error="isSubmitted && v$.city.$invalid"
            :items="cities"
            v-model="userData.city"
            :label="t('regionCity')"
            :placeholder="t('regionCityPlaceholder')"
          />
        </div>
      </div>
      <hr class="text-grey-50 my-12" />
      <div class="flex gap-6">
        <Button @click="step = 2" size="full" variant="light">{{ t('global.back') }}</Button>
        <Button :disabled="loading" @click="submitStepThree" size="full" variant="primary">{{
          t('global.continue')
        }}</Button>
      </div>
    </div>
    <div v-if="step === 4">
      <div class="flex items-center justify-center">
        <div class="p-10 rounded-full bg-grey-50">
          <SvgIcon class="text-primary-500" name="check" />
        </div>
      </div>
      <div class="mt-8 text-center text-2xl mb-3">{{ t('authPage.accountSuccessfullyCreated') }}</div>
      <p class="text-grey-200 mb-2">{{ t('authPage.nowYouCanLookForApplicants') }}</p>
      <p class="text-grey-200" v-for="item in t('authPage.gitFlow').split('\n')" :key="item">{{ item }}</p>
      <hr class="text-grey-50 my-12" />
      <div class="flex gap-6">
        <Button @click="router.push({ name: 'home' })" size="full" variant="softPrimary"
          >{{ t('global.great') }}
        </Button>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import SvgIcon from '@/components/ui/SvgIcon.vue';
  import Input from '@/components/ui/Input.vue';
  import Select from '@/components/ui/Select.vue';
  import { computed, onMounted, ref, watch } from 'vue';
  import { required } from '@vuelidate/validators';
  import { useVuelidate } from '@vuelidate/core';
  import Button from '@/components/ui/Button.vue';
  import { verifyEmployer, registrationEmployer, createEmployerUser, sendEmailOtp } from '@/services/auth';
  import { useCookies } from 'vue3-cookies';
  import { getRegions } from '@/services/regions';
  import { useRouter } from 'vue-router';
  import { useI18n } from 'vue-i18n';
  import { useCoreStore } from '@/stores/core';
  const router = useRouter();
  defineEmits(['toSelectType']);

  const { t, locale } = useI18n();
  const { cookies } = useCookies();

  const loading = ref(false);
  const isSubmitted = ref(false);
  const otp = ref('');
  const timer = ref(240);
  const email = ref('');
  const emailInvalid = ref(false);
  const otpInvalid = ref(false);
  const userId = ref('');
  const regions = ref<any[]>([]);
  const allRegions = ref<any[]>([]);
  const cities = ref<any[]>([]);
  const userData = ref({
    name: '',
    regionId: '',
    city: '',
  });
  const coreStore = useCoreStore();

  const step = ref(1);

  const rules = computed(() => ({
    name: { required },
    city: { required },
  }));

  const sendOtpHandler = () => {
    isSubmitted.value = true;
    loading.value = true;
    sendEmailOtp({ email: email.value })
      .then(() => {
        timer.value = 60;
        startOtpTimer();
      })
      .finally(() => {
        isSubmitted.value = false;
        loading.value = false;
      });
  };
  const formattedTime = computed(() => {
    const minutes = Math.floor(timer.value / 60);
    const seconds = timer.value % 60;
    //@ts-ignore
    const formattedMinutes = String(minutes).padStart(2, '0');
    //@ts-ignore
    const formattedSeconds = String(seconds).padStart(2, '0');

    return `${formattedMinutes}:${formattedSeconds}`;
  });
  const submitStepOne = () => {
    isSubmitted.value = true;
    if (!email.value.match('([a-zA-Z]*)[@]([a-zA-Z]*)[.](\\w)')) {
      emailInvalid.value = true;
      return;
    }

    loading.value = true;
    registrationEmployer({ email: email.value })
      .then(() => {
        step.value = 2;
      })
      .finally(() => {
        isSubmitted.value = false;
        loading.value = false;
      });
  };
  const submitStepTwo = () => {
    isSubmitted.value = true;
    if (!otp.value) {
      otpInvalid.value = true;
      return;
    }

    loading.value = true;
    verifyEmployer({ email: email.value, code: otp.value })
      .then(response => {
        userId.value = response.data.payload.id || '';
        const token = response.data.payload.token || '';
        cookies.set('token', token);
        // window.location.reload();
        step.value = 3;
      })
      .finally(() => {
        isSubmitted.value = false;
        loading.value = false;
      });
  };
  const submitStepThree = () => {
    isSubmitted.value = true;
    if (v$.value.$invalid) {
      v$.value.$touch();
      return;
    }

    loading.value = true;
    createEmployerUser(userId.value, userData.value)
      .then(() => {
        step.value = 4;
        coreStore.getUser();
      })
      .finally(() => {
        isSubmitted.value = false;
        loading.value = false;
      });
  };
  const getCitiesHandler = () => {
    if (!userData.value.regionId) return;
    cities.value = [];
    allRegions.value.forEach(region => {
      if (region.parentId === userData.value.regionId) {
        cities.value.push({
          value: region.id,
          title: locale.value === 'ru' ? region.nameRu : locale.value === 'tj' ? region.nameTj : region.nameEn,
          viewTitle: region.nameRu,
        });
      }
    });
  };

  watch(
    () => step.value,
    () => {
      if (step.value === 2) {
        startOtpTimer();
      }
    }
  );

  const startOtpTimer = () => {
    const start = setInterval(function () {
      timer.value--;
      if (timer.value === 0) {
        clearInterval(start);
      }
    }, 1000);
    return start;
  };

  const getRegionsHandler = () => {
    getRegions().then((response: any) => {
      allRegions.value = response.data.payload.items || [];
      response.data.payload.items?.forEach((item: any) => {
        if (!item.parentId) {
          regions.value.push({
            value: item.id,
            title: locale.value === 'ru' ? item.nameRu : locale.value === 'tj' ? item.nameTj : item.nameEn,
            viewTitle: item.nameRu,
          });
        }
      });
      getCitiesHandler();
    });
  };

  onMounted(() => {
    getRegionsHandler();
  });
  const v$ = useVuelidate(rules, userData);
</script>

<style scoped></style>
