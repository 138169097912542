import { format } from 'date-fns';
import { ru, enAU } from 'date-fns/locale';
export function readableDate(date: string, lang: string) {
  if (!date) return '';
  const parsedDate = format(new Date(date), 'dd MMM yyyy', { locale: lang === 'en' ? enAU : ru });
  return parsedDate;
}

export function readableDateShort(date: string, lang: string) {
  if (!date) return '';
  const parsedDate = format(new Date(date), 'dd MMMM', { locale: lang === 'en' ? enAU : ru });
  return parsedDate;
}

export const timeFilter = (date: string, locale = 'ru'): string => {
  if (!date) return '';
  return new Date(date).toLocaleTimeString(locale, {
    hour: '2-digit',
    minute: '2-digit',
  });
};
