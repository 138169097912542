<template>
  <div class="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
    <div class="flex flex-col items-center justify-center -mx-8 p-4">
      <h3 class="text-3xl font-medium">{{ t('changePassword') }}</h3>
      <div class="p-5 mt-16 w-[650px] h-auto border-1 rounded-4 bg-white">
        <div class="flex flex-col gap-2">
          <div class="mb-4">
            <UIInput
              id="password12"
              type="password"
              :disabled="loading"
              :isError="isSubmitted && v$.oldPassword.$invalid"
              :error-text="t('requiredField')"
              v-model="user.oldPassword"
              :label="t('oldPassword')"
              placeholder="**********"
            />
          </div>
          <div class="mb-4">
            <UIInput
              id="password21"
              type="password"
              :disabled="loading"
              :isError="isSubmitted && v$.newPassword.$invalid"
              :error-text="t('requiredField')"
              v-model="user.newPassword"
              :label="t('newPassword')"
              placeholder="**********"
            />
          </div>
          <div class="mb-4">
            <UIInput
              id="password1231"
              type="password"
              :disabled="loading"
              :isError="isSubmitted && (v$.repeatNewPassword.$invalid || user.newPassword !== user.repeatNewPassword)"
              :error-text="t('requiredField')"
              v-model="user.repeatNewPassword"
              :label="t('newPasswordRepeat')"
              placeholder="**********"
            />
          </div>
        </div>
        <div class="flex items-center justify-center mt-4">
          <UIButton size="full" variant="primary" @click="changePasswordUser" :disabled="loading" :loading="loading">
            {{ t('change') }}
          </UIButton>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { ref, reactive, computed } from 'vue';
  import { useVuelidate } from '@vuelidate/core';
  import { required } from '@vuelidate/validators';
  import UIInput from '@/components/ui/Input.vue';
  import UIButton from '@/components/ui/Button.vue';
  import { changePassword } from '@/services/auth';
  import { useCoreStore } from '@/stores/core';
  import { toast } from 'vue3-toastify';
  import { useRouter } from 'vue-router';
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n();

  const router = useRouter();
  const user = reactive<{ oldPassword: string; newPassword: string; repeatNewPassword: string }>({
    oldPassword: '',
    newPassword: '',
    repeatNewPassword: '',
  });
  const profile = computed(() => {
    return useCoreStore().profile;
  });
  const loading = ref(false);
  const isSubmitted = ref(false);

  function changePasswordUser() {
    isSubmitted.value = true;
    if (v$.value.$invalid || user.repeatNewPassword !== user.newPassword) {
      v$.value.$touch();
      return;
    }
    loading.value = true;
    changePassword((profile.value?.id as string) || profile.value?.userId || '', user)
      .then(() => {
        toast.success(t('passwordSuccessfullyChanged'));
        setTimeout(() => {
          router.push('/');
        }, 500);
      })
      .finally(() => {
        loading.value = false;
        isSubmitted.value = false;
      });
  }
  const rules = computed(() => ({
    newPassword: { required },
    oldPassword: { required },
    repeatNewPassword: { required },
  }));

  const v$ = useVuelidate(rules, user);
</script>
