import { type TResponse } from '@/types/global';
import { apiClient } from './apiClient';
import type { ISpecialization } from '@/types/specialization';

type TGetSpecializationsResponse = {
  page: number;
  size: number;
  totalCount: number;
  totalPages: number;
  items: ISpecialization[];
};

export const getSpecializationsRequest = async (params: any) => {
  const { data } = await apiClient.get<TResponse<TGetSpecializationsResponse>>('specializations', { params });
  return data;
};
