<template>
  <div class="bg-white">
    <div class="mx-auto max-w-7xl py-10 md:py-[120px] px-4 sm:px-6 lg:px-8">
      <div class="lg:flex justify-center lg:space-x-[100px] items-center">
        <img class="h-[595px]" src="@/assets/images/apps.png" alt="download-app" />
        <div class="max-w-[500px] mt-10 lg:mt-0 mx-auto lg:mx-0">
          <p class="text-primary-500 mb-4">{{ t('startHome.downloadAndEnjoy') }}</p>
          <h2 class="text-3xl">{{ t('startHome.downloadAppText') }}</h2>
          <p class="text-sm mt-2 text-grey-200">
            {{ t('startHome.downloadAppSubText') }}
          </p>
          <div class="flex space-x-4 mb-10 mt-4">
            <a target="_blank" href="https://apps.apple.com/tj/app/kor-tj/id6478083890">
              <img src="@/assets/images/app-store.png" class="h-[45px] md:h-[60px]" alt="logo" />
            </a>
            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.kor.tj">
              <img src="@/assets/images/google-play.png" class="h-[45px] md:h-[60px]" alt="logo" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n();
</script>
