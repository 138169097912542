<template>
  <div class="bg-white">
    <div class="mx-auto max-w-7xl py-6 px-4 sm:px-6 md:flex md:space-x-10 items-start">
      <div
        :class="{ 'hidden': !showFilters }"
        class="absolute z-10 top-0 right-0 bottom-0 overflow-y-scroll md:overflow-y-auto left-0 md:relative md:block md:w-[300px] lg:w-[390px] bg-primary-150 p-6 rounded-md h-[90vh] md:h-auto"
      >
        <div>
          <p>{{ t('vacanciesPage.keywordSearch') }}</p>
          <Input
            :placeholder="`${t('vacanciesPage.position')} ${t('vacanciesPage.keywords')} ...`"
            iswhite
            class-name="rounded-md w-full mt-2"
            v-model="query.query"
          />
        </div>
        <div class="mt-6">
          <p>{{ t('region') }}</p>
          <Select
            :placeholder="t('selectRegion')"
            is-white
            :items="[{ title: t('all'), value: null }, ...regions]"
            class="w-full mt-1"
            v-model="regionId"
            @update:model-value="getCitiesHandler"
          />
        </div>
        <div class="mt-6">
          <p>{{ t('regionCity') }}</p>
          <Select
            :placeholder="t('regionCityPlaceholder')"
            is-white
            :items="[{ title: t('all'), value: null }, ...cities]"
            class="w-full mt-1"
            v-model="query.cityId"
          />
        </div>
        <div class="mt-6">
          <p>{{ t('categories') }}</p>
          <Select
            :placeholder="t('vacanciesPage.selectCategory')"
            is-white
            :items="[{ title: t('all'), value: null }, ...categories]"
            class="w-full mt-1"
            v-model="query.category"
          />
        </div>
        <div class="mt-6">
          <p>{{ t('vacanciesPage.specializations') }}</p>
          <Select
            :placeholder="t('vacanciesPage.selectSpecialization')"
            is-white
            :items="[{ title: t('all'), value: null }, ...specializations]"
            class="w-full mt-1"
            v-model="query.specializationId"
          />
        </div>
        <div class="mt-6">
          <p>
            {{ t('vacanciesPage.postDate') }}
          </p>
          <div class="flex flex-col gap-5 mt-2">
            <div class="flex items-center gap-2 justify-start">
              <input
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio6"
                class="right-0 w-5 h-5"
                :value="0"
                v-model="query.date"
              />
              <label class="text-sm text-grey-200">
                {{ t('all') }}
              </label>
            </div>
            <div class="flex items-center gap-2 justify-start">
              <input
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio1"
                class="right-0 w-5 h-5"
                :value="1"
                v-model="query.date"
              />
              <label class="text-sm text-grey-200">{{ t('global.lastHour') }}</label>
            </div>
            <div class="flex items-center gap-2 justify-start">
              <input
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio2"
                class="right-0 w-5 h-5"
                :value="24"
                v-model="query.date"
              />
              <label class="text-sm text-grey-200">{{ t('global.last24Hour') }}</label>
            </div>
            <div class="flex items-center gap-2 justify-start">
              <input
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio3"
                class="right-0 w-5 h-5"
                :value="7"
                v-model="query.date"
              />
              <label class="text-sm text-grey-200">{{ t('global.last7Day') }}</label>
            </div>
            <div class="flex items-center gap-2 justify-start">
              <input
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio4"
                class="right-0 w-5 h-5"
                :value="14"
                v-model="query.date"
              />
              <label class="text-sm text-grey-200">{{ t('global.last14Day') }}</label>
            </div>
            <div class="flex items-center gap-2 justify-start">
              <input
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio5"
                class="right-0 w-5 h-5"
                :value="30"
                v-model="query.date"
              />
              <label class="text-sm text-grey-200">{{ t('global.last30Day') }}</label>
            </div>
          </div>
        </div>
        <div class="mt-6">
          <p>{{ t('workSchedule') }}</p>
          <Select
            :placeholder="t('choose')"
            is-white
            :items="[{ title: t('all'), value: null }, ...workSchedules]"
            class="w-full mt-1"
            v-model="query.workScheduleId"
          />
        </div>
        <div class="mt-6">
          <p>{{ t('vacanciesPage.desiredSalary') }}</p>
          <Input
            :placeholder="t('vacanciesPage.typeDesiredSalary')"
            type="number"
            iswhite
            class-name="rounded-md w-full mt-2"
            v-model="query.salaryCurrency"
          />
        </div>
        <div class="mt-6">
          <p>{{ t('vacanciesPage.levelOfEducation') }}</p>
          <Select
            :placeholder="t('vacanciesPage.levelOfEducation')"
            is-white
            :items="[{ title: t('all'), value: null }, ...educations]"
            class="w-full mt-1"
            v-model="query.educationId"
          />
        </div>
        <div class="mt-6">
          <p>{{ t('age') }}</p>
          <div class="flex gap-4">
            <Input
              type="number"
              :placeholder="t('vacanciesPage.from')"
              iswhite
              class-name="rounded-md w-full mt-2"
              v-model="query.ageFrom"
            />
            <Input
              type="number"
              :placeholder="t('vacanciesPage.to')"
              iswhite
              class-name="rounded-md w-full mt-2"
              v-model="query.ageTo"
            />
          </div>
        </div>
        <div class="mt-6">
          <p>{{ t('workExperience') }}</p>
          <Select
            is-white
            :items="[
              { title: t('all'), value: null },
              { title: `${t('withoutExperience')}`, value: 0 },
              { title: '1 год', value: 1 },
              { title: '2 года', value: 2 },
              { title: '3 года', value: 3 },
              { title: '4 года', value: 4 },
              { title: 'более 5 лет', value: 5 },
            ]"
            class="w-full mt-1"
            v-model="query.WorkExperience"
            :placeholder="t('choose')"
          />
        </div>
        <div class="mt-6">
          <p>
            {{ t('vacanciesPage.suitableFor') }}
          </p>
          <Checkbox v-model="query.IsDisabledPerson" :label="t('vacanciesPage.disabledPersons')" />
        </div>
        <div
          @click="showFilters = false"
          class="text-center mt-6 left-4 right-4 bg-primary-500 text-white rounded-md mb-4 py-2 md:hidden"
        >
          {{ t('accept') }}
        </div>
      </div>
      <div :class="{ 'hidden': showFilters }" class="w-full">
        <div class="flex justify-end md:justify-between items-center">
          <p class="text-grey-300 text-lg hidden md:block">
            {{ t('shown') }} {{ vacancies?.items?.length || 0 }} {{ t('from') }} {{ vacancies?.totalCount || 0 }}
            {{ t('vacanciesPage.openings') }}
          </p>
          <div class="hidden md:block">
            <Select
              is-white
              :items="[
                { value: true, title: t('vacanciesPage.newVacancies') },
                { value: false, title: t('vacanciesPage.oldVacancies') },
              ]"
              class="w-full mt-1"
              v-model="query.isNew"
              :placeholder="t('choose')"
            />
          </div>
          <div
            @click="showFilters = true"
            class="flex md:hidden space-x-2 items-center px-4 py-1 rounded-md bg-primary-400 cursor-pointer hover:bg-primary-500 hover:text-white"
          >
            <SvgIcon name="setting" />
            <span>{{ t('filters') }}</span>
          </div>
        </div>
        <div v-if="loading" class="flex items-center justify-center mt-24">
          <Spinner />
        </div>
        <div v-else class="mt-6 w-full">
          <VacancyCard
            @updateData="getVacancies"
            v-for="vacancy in vacancies?.items"
            :key="vacancy.id"
            :vacancy="vacancy"
          />
          <div v-if="!vacancies?.items?.length" class="text-center text-grey-500">{{ t('nothingFound') }}</div>
        </div>
        <Pagination
          v-if="vacancies?.items?.length"
          v-model:modelValue="query.page"
          v-model:pageSize="query.size"
          :totalCount="vacancies?.totalCount"
          :totalPage="vacancies?.totalPages || 1"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { computed, onMounted, ref, watch } from 'vue';
  import { getAllVacanciesRequest } from '@/services/vacancy';
  import type { TGetVacanciesResponse } from '@/services/vacancy';
  import SvgIcon from '@/components/ui/SvgIcon.vue';
  import VacancyCard from '@/components/vacancies/VacancyCard.vue';
  import Input from '@/components/ui/Input.vue';
  import Select from '@/components/ui/Select.vue';
  import { getSpecializationCategoriesRequest } from '@/services/specializationCategory';
  import { getRegions } from '@/services/regions';
  import { getEducationsRequest } from '@/services/educations';
  import { debounce } from '@/utils/debounce';
  import Spinner from '@/components/ui/Spinner.vue';
  import Pagination from '@/components/ui/Pagination.vue';
  import { useRoute } from 'vue-router';
  import { useI18n } from 'vue-i18n';
  import { getSpecializationsRequest } from '@/services/specialization';
  import { getWorkSchedulesRequest } from '@/services/workSchedules';
  import { setSearchData } from '@/services/home';
  import Checkbox from '@/components/ui/Checkbox.vue';

  const { t, locale } = useI18n();

  const route = useRoute();

  const loading = ref(false);
  const getLoading = ref(false);
  const showFilters = ref(false);
  const regionId = ref('');

  const query = ref({
    query: (route.query?.searchText as string) || '',
    cityId: null,
    ageFrom: '',
    ageTo: '',
    date: 0,
    workScheduleId: null,
    specializationId: null,
    WorkExperience: null,
    category: route.query?.categoryId?.toString() || null,
    salaryCurrency: '',
    educationId: null,
    isNew: true,
    page: 1,
    size: 10,
    IsDisabledPerson: (route.query?.IsDisabledPerson as string) ? true : false,
  });

  const categories = ref<{ value: string; title: string }[]>([]);
  const workSchedules = ref<{ value: string; title: string }[]>([]);
  const regions = ref<{ value: string; title: string }[]>([]);
  const cities = ref<any[]>([]);
  const allRegions = ref<any[]>([]);
  const count = ref(0);
  const specializations = ref<{ value: string; title: string }[]>([]);
  const educations = ref<{ value: string; title: string }[]>([]);
  const promises = [
    getSpecializationCategoriesRequest(),
    getRegions(),
    getEducationsRequest(),
    getWorkSchedulesRequest(),
  ];

  const localePostfix = computed(() => {
    return locale.value === 'ru' ? 'Ru' : locale.value === 'en' ? 'En' : 'Tj';
  });
  const getCitiesHandler = () => {
    if (!regionId.value) return;
    cities.value = [];
    allRegions.value.forEach(region => {
      if (region.parentId === regionId.value) {
        cities.value.push({
          value: region.id,
          title: region[`name${localePostfix.value}`],
          viewTitle: region[`name${localePostfix.value}`],
        });
      }
    });
  };

  function getAllDataHandler() {
    getLoading.value = true;
    //@ts-ignore
    Promise.allSettled(promises)
      .then(responses => {
        if (responses[0].status === 'fulfilled') {
          //@ts-ignore
          responses[0]?.value?.payload?.items?.forEach(item => {
            categories.value.push({ value: item.id, title: item[`name${localePostfix.value}`] });
          });
        }
        if (responses[1].status === 'fulfilled') {
          //@ts-ignore
          allRegions.value = responses[1].value?.data?.payload?.items || [];
          getCitiesHandler();
          //@ts-ignore
          responses[1].value?.data?.payload?.items?.forEach((item: any) => {
            if (!item.parentId) {
              regions.value.push({
                value: item.id,
                title: item[`name${localePostfix.value}`],
              });
            }
          });
        }
        if (responses[2].status === 'fulfilled') {
          //@ts-ignore
          responses[2].value.payload.forEach(item => {
            educations.value.push({ value: item.id, title: item[`name${localePostfix.value}`] });
          });
        }
        if (responses[3].status === 'fulfilled') {
          //@ts-ignore
          responses[3].value.payload?.items?.forEach(item => {
            workSchedules.value.push({ value: item.id, title: item[`name${localePostfix.value}`] });
          });
        }
      })
      .finally(() => (getLoading.value = false));
  }

  const getSpecializations = async () => {
    try {
      getLoading.value = true;
      specializations.value = [];
      const response = await getSpecializationsRequest({ Category: query.value.category });
      response.payload?.items?.forEach(item => {
        specializations.value.push({ value: item.id, title: item[`name${localePostfix.value}`] });
      });
    } catch (e) {
      console.error(e);
    } finally {
      getLoading.value = false;
    }
  };

  onMounted(() => {
    getVacancies();

    getAllDataHandler();
  });

  watch(
    () => query.value,
    debounce(() => {
      getVacancies();
    }, 500),
    { deep: true }
  );

  watch(
    () => route.query?.categoryId,
    value => {
      query.value.category = value?.toString() || '';
    },
    { immediate: true }
  );

  watch(
    () => query.value.category,
    () => {
      getSpecializations();
    },
    { immediate: true }
  );

  const vacancies = ref<TGetVacanciesResponse>();
  const getVacancies = () => {
    loading.value = true;
    const payload = JSON.parse(JSON.stringify(query.value));
    if (payload.IsDisabledPerson === false) {
      payload.IsDisabledPerson = null;
    }
    getAllVacanciesRequest(payload)
      .then(res => {
        vacancies.value = res?.payload;
        if (route.query?.searchText && count.value === 0) {
          const searchData = {
            searchText: (route.query?.searchText as string) || '',
            //@ts-ignore
            link: import.meta.env.VITE_BASE_URL + '/api' + route.fullPath,
            result: vacancies.value.items.length ? 'Удачно' : 'Неудачно',
            IpAddress: '101',
          };
          setSearchData(searchData).then(() => {
            count.value = 1;
          });
        }
      })
      .catch(() => {
        if (route.query?.searchText && count.value === 0) {
          const searchData = {
            searchText: (route.query?.searchText as string) || '',
            //@ts-ignore
            link: import.meta.env.VITE_BASE_URL + '/api' + route.fullPath,
            result: 'Ошибка',
            IpAddress: '101',
          };
          setSearchData(searchData).then(() => {
            count.value = 1;
          });
        }
      })
      .finally(() => {
        loading.value = false;
      });
  };
</script>
