export const globalTsTj = {
  logIn: 'Войти',
  entry: 'Вход',
  registration: 'Регистрация',
  keywordSearch: 'Поиск по ключевым словам',
  location: 'Локация',
  categories: 'Категории',
  postDate: 'Дата поста',
  lastHour: 'Последний час',
  last24Hour: 'Последние 24 часа',
  last7Day: 'Последние 7 дней',
  last14Day: 'Последние 14 часа',
  last30Day: 'Последние 30 часа',
  all: 'Все',
  educationLevel: 'Уровень образования',
  region: 'Регион',
  notFound: 'Ничего не найдено',
  searchWork: 'Найти работу',
  home: 'Главная',
  vacancies: 'Вакансии',
  newVacations: 'Новые вакансии',
  oldVacations: 'Старые вакансии',
  aboutUs: 'О нас',
  news: 'Новости',
  candidates: 'Новости',
  companies: 'Компании',
  recentPublications: 'Последние публикации',
  candidateGender: 'Пол кандидата',
  workExperience: 'Опыт работы',
  clearFilters: 'Очистить фильтры',
  filters: 'Фильтры',
  companySize: 'Размер компании',
  createdDate: 'Дата основания',
  aboutCompany: 'Дата основания',
  subscribeForUpdates: 'Подписаться на обновлении',
  sendMessage: 'Отправить сообщение',
  scopeOfWork: 'Сфера деятельности',
  employeesNumber: 'Число сотрудников',
  FoundedIn: 'Основана в',
  phone: 'Телефон',
  login: 'Логин',
  email: 'Почта',
  socialMedia: 'Соц сети',
  userAgreement: 'Пользовательское соглашение',
  allRightsReserved: 'Все права защищены',
  authorization: 'Вход в аккаунт',
  employee: 'Соискатель',
  employer: 'Работадатель',
  phoneNumber: 'Номер телефона',
  password: 'Пароль',
  forgotPassword: 'Забыли пароль',
  rememberMe: 'Запомнить меня',
  chooseWhoAreYou: 'Выберите кто вы',
  accountRegistration: 'Регистрация аккаунта',
  employerRegistration: 'Регистрация работодателя',
  employeeRegistration: 'Регистрация соискателя',
  yourPhoneNumber: 'Ваш номер телефона',
  smsCode: 'Введите отправленный SMS-код подтверждения',
  confirmPhoneNumber: 'Подтвердите номер телефона',
  codeExpired: 'Срок действия кода истек',
  sendCodeAgain: 'Отправить заново код',
  continue: 'Продолжить',
  back: 'Назад',
  mobileApp: 'Мобильное приложение',
  myProfile: 'Мой профиль',
  allApplications: 'Все заявки',
  statistics: 'Статистика',
  addVacancy: 'Добавить вакансию',
  selectedResumes: 'Отобранные резюме',
  selectedVacancies: 'Избранные вакансии',
  notification: 'Оповещения',
  messages: 'Сообщения',
  changePassword: 'Изменить пароль',
  exit: 'Выйти',
  resume: 'Резюме',
  manegerCV: 'Менджер CV',
  jobApplications: 'Мои заявки на работу',
  contacts: 'Тамос бо мо',
  active: 'Фаъол',
  closed: 'Бандшуда',
  cancel: 'Бекор кардан',
  negotiable: 'Шартномавӣ',
  undp: 'Бо дастгирии БРСММ таҳия гардидааст',
};
