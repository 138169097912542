<template>
  <div>
    <div class="bg-primary-150">
      <div class="mx-auto md:flex items-center md:space-x-6 justify-between max-w-7xl py-[50px] px-4 sm:px-6 lg:px-8">
        <div class="flex space-x-6 p-5 md:p-7 rounded-md mb-6 w-full">
          <img
            class="w-[80px] h-[80px] md:w-[100px] md:h-[100px] border border-[#ECEDF2] rounded-md object-contain"
            :src="vacancy?.company?.logo || addPhoto"
            alt=""
          />
          <div>
            <h3 class="text-2xl mb-1">{{ vacancy?.[`position${localePostfix}`] }}</h3>
            <div class="flex flex-wrap items-center gap-y-2 gap-x-6 w-full">
              <span class="flex items-center text-grey-500 text-lg">
                <SvgIcon class="mr-1" name="portfolio" />
                {{ vacancy?.[`position${localePostfix}`] }}
              </span>
              <span v-if="vacancy?.address" class="flex items-center text-grey-500 text-lg">
                <SvgIcon class="mr-1 h-[18px] w-[18px]" name="location" />
                {{ vacancy?.address }}
              </span>
              <span v-if="vacancy?.createdAt" class="flex items-center text-grey-500 text-lg">
                <SvgIcon class="mr-1" name="time" />
                {{ readableDate(vacancy?.createdAt, locale || '') }}
              </span>
              <span class="flex items-center text-grey-500 text-lg">
                <SvgIcon class="mr-1" name="money" />
                {{ vacancy?.salaryCurrency || t('globalTs.negotiable') }}
              </span>
            </div>
            <div class="flex flex-wrap gap-y-2 gap-x-4 mt-4 overflow-x-auto">
              <div
                v-if="vacancy?.employmentType"
                class="rounded-full px-4 py-0.5 whitespace-nowrap bg-primary-300 text-primary-500"
              >
                {{ vacancy?.employmentType?.[`name${localePostfix}`] }}
              </div>
              <div
                v-for="(vacancySchedule, index) in vacancy?.workSchedule"
                :key="index"
                :class="{
                  'bg-primary-300 text-primary-500': !index,
                  'bg-green-pale text-green': index === 1,
                }"
                class="rounded-full px-4 py-0.5 whitespace-nowrap"
              >
                {{ vacancySchedule?.[`name${localePostfix}`] }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="vacancy?.id && !profile?.companyId">
          <div class="flex items-center gap-4">
            <button
              v-if="!isApplied && profile"
              :disabled="applyLoading"
              @click="applyVacancy(vacancy?.id)"
              class="cursor-pointer rounded-md bg-primary-500 text-white px-6 py-2 whitespace-nowrap text-center flex justify-center space-x-4"
            >
              <Spinner v-if="applyLoading" />
              <span>{{ t('reply') }}</span>
            </button>
            <button
              v-else-if="isApplied && profile"
              class="rounded-md bg-grey-100 px-6 py-2 whitespace-nowrap text-grey-500"
              disabled
            >
              {{ t("you'veAlreadyResponded") }}
            </button>
            <Spinner v-if="loading" />
            <button
              v-else-if="profile"
              :disabled="vacancy?.isFavorite"
              :class="{ 'bg-grey-200/70': vacancy?.isFavorite }"
              class="p-2 px-4 h-[40px] rounded-md hover:bg-grey-200/70 bg-primary-400 flex justify-center items-center"
              @click.prevent="addVacancyToFavorite(vacancy.id)"
            >
              <SvgIcon name="bookmark" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white py-6">
      <div class="mx-auto max-w-7xl py-6 px-4 sm:px-6 md:flex md:space-x-10 items-start">
        <div class="w-full">
          <div v-if="vacancy?.[`description${localePostfix}`]" class="p-4 md:p-0 w-full">
            <p class="text-lg">{{ t('vacanciesPage.vacancyDescription') }}</p>
            <p class="text-grey-500 mt-4 min-h-20 mb-6 md:pr-16" v-html="vacancy?.[`description${localePostfix}`]" />
          </div>
          <div v-if="vacancy?.[`commitment${localePostfix}`]" class="p-4 md:p-0 w-full">
            <p class="text-lg">{{ t('vacanciesPage.mainResponsibilities') }}</p>
            <p class="text-grey-500 mt-4 min-h-20 md:pr-16 mb-6" v-html="vacancy?.[`commitment${localePostfix}`]" />
          </div>
          <div v-if="vacancy?.[`requirements${localePostfix}`]" class="p-4 md:p-0 w-full">
            <p class="text-lg">{{ t('vacanciesPage.vacancyRequirement') }}</p>
            <p class="text-grey-500 mt-4 min-h-20 md:pr-16" v-html="vacancy?.[`requirements${localePostfix}`]" />
          </div>
        </div>
        <div class="md:w-[300px] lg:w-[390px]">
          <div class="bg-primary-150 rounded-md p-6">
            <p class="text-lg mb-6">{{ t('vacanciesPage.jobOverview') }}</p>
            <div class="flex space-x-3 mb-6">
              <SvgIcon class="text-primary-500" name="calendar" />
              <div>
                <p>{{ t('vacanciesPage.publicationDate') }}</p>
                <p class="text-grey-500 text-[15px]">
                  {{ vacancy?.createdAt ? readableDate(vacancy?.createdAt, locale) : t('notSpecified') }}
                </p>
              </div>
            </div>
            <div class="flex space-x-3 mb-6">
              <SvgIcon class="text-primary-500" name="clock" />
              <div>
                <p>{{ t('expirationDate') }}</p>
                <p class="text-grey-500 text-[15px]">
                  {{ vacancy?.durationDate ? readableDate(vacancy?.durationDate, locale) : t('notSpecified') }}
                </p>
              </div>
            </div>
            <div class="flex space-x-3 mb-6">
              <SvgIcon class="text-primary-500" name="location" />
              <div>
                <p>{{ t('city') }} / {{ t('region') }}</p>
                <p class="text-grey-500 text-[15px]">
                  <span>{{ vacancy?.city ? vacancy?.city?.[`name${localePostfix}`] : t('notSpecified') }}</span
                  >,
                  <span>{{ vacancy?.region ? vacancy?.region?.[`name${localePostfix}`] : t('notSpecified') }}</span>
                </p>
              </div>
            </div>
            <div class="flex space-x-3 mb-6">
              <SvgIcon class="text-primary-500" name="user" />
              <div>
                <p>{{ t('vacanciesPage.position') }}</p>
                <p class="text-grey-500 text-[15px]">
                  {{ vacancy?.[`position${localePostfix}`] || t('notSpecified') }}
                </p>
              </div>
            </div>
            <div class="flex space-x-3 mb-6">
              <SvgIcon class="text-primary-500" name="money" />
              <div>
                <p>{{ t('vacanciesPage.salary') }}</p>
                <p class="text-grey-500 text-[15px]">
                  {{ vacancy?.salaryCurrency ? vacancy?.salaryCurrency + ' c' : t('globalTs.negotiable') }}
                </p>
              </div>
            </div>
            <div v-if="vacancy?.languages?.length" class="flex space-x-3 mb-6">
              <SvgIcon class="text-primary-500" name="translation" />
              <div>
                <p>{{ t('vacanciesPage.languages') }}</p>
                <p v-for="(language, index) in vacancy.languages" :key="index" class="text-grey-500 text-[15px]">
                  {{ language?.[`languageName${localePostfix}`] + ' - ' + getLanguageLevel(language) }}
                </p>
              </div>
            </div>
          </div>
          <div class="bg-primary-150 rounded-md p-6 mt-6">
            <div class="flex space-x-4 mb-5">
              <img
                class="w-[60px] h-[60px] border border-[#ECEDF2] rounded-md object-cover"
                :src="vacancy?.company?.logo || addPhoto"
                alt=""
              />
              <div>
                <p class="text-lg">{{ vacancy?.company?.name }}</p>
                <router-link
                  class="text-primary-500 text-sm whitespace-nowrap"
                  :to="{ name: 'company-detail', params: { id: vacancy?.company?.id } }"
                >
                  {{ t('vacanciesPage.viewProfile') }}
                </router-link>
              </div>
            </div>
            <div class="flex space-x-4 mb-3 justify-between">
              <span class="text-xs">{{ t('scopeOfActivity') }}: </span>
              <span class="text-xs text-grey-500">
                {{ vacancy?.company?.industry?.[`name${localePostfix}`] || t('notSpecified') }}
              </span>
            </div>
            <div class="flex space-x-4 mb-3 justify-between">
              <span class="text-xs">{{ t('numberOfEmployees') }}: </span>
              <span class="text-xs text-grey-500">
                {{ vacancy?.company?.сompanyEmployeers || t('notSpecified') }}
              </span>
            </div>
            <div class="flex space-x-4 mb-3 justify-between">
              <span class="text-xs">{{ t('dateOfFoundation') }}: </span>
              <span class="text-xs text-grey-500">
                {{
                  vacancy?.company?.companyInsertDate
                    ? readableDate(vacancy?.company?.companyInsertDate, locale)
                    : t('notSpecified')
                }}
              </span>
            </div>
            <div class="flex space-x-4 mb-3 justify-between">
              <span class="text-xs">{{ t('phone') }}: </span>
              <span class="text-xs text-grey-500"> {{ vacancy?.company?.phone || t('notSpecified') }} </span>
            </div>
            <div class="flex space-x-4 mb-3 justify-between">
              <span class="text-xs">{{ t('mail') }}: </span>
              <span class="text-xs text-grey-500"> {{ vacancy?.company?.email || t('notSpecified') }} </span>
            </div>
            <div class="flex space-x-4 mb-3 justify-between">
              <span class="text-xs">{{ t('regionCity') }}: </span>
              <span class="text-xs text-grey-500">
                {{ vacancy?.company?.region?.[`name${localePostfix}`] || t('notSpecified') }}
              </span>
            </div>
          </div>
          <div v-if="vacancy?.company?.responsiblePerson" class="bg-primary-150 rounded-md p-6 mt-6">
            <div class="flex space-x-4 mb-5">
              <p class="text-lg">{{ t('employerPage.responsiblePerson') }}</p>
            </div>
            <div class="flex space-x-4 mb-3 justify-between">
              <span class="text-xs">{{ t('name') }}: </span>
              <span class="text-xs text-grey-500">
                {{ vacancy?.company?.responsiblePerson?.name || t('notSpecified') }}
              </span>
            </div>
            <div class="flex space-x-4 mb-3 justify-between">
              <span class="text-xs">{{ t('phone') }}: </span>
              <span class="text-xs text-grey-500">
                {{ vacancy?.company?.responsiblePerson?.phone || t('notSpecified') }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { readableDate } from '@/utils/filters';
  import SvgIcon from '@/components/ui/SvgIcon.vue';
  import { computed, nextTick, ref } from 'vue';
  import type { IVacancy } from '@/types/vacancy';
  import {
    addVacancyToFavoriteRequest,
    applyToVacancy,
    checkVacancyForApply,
    getVacancyByIdRequest,
  } from '@/services/vacancy';
  import { useRoute, useRouter } from 'vue-router';
  import addPhoto from '@/assets/images/add-photo.png';
  import { useCookies } from 'vue3-cookies';
  import { useCoreStore } from '@/stores/core';
  import { toast } from 'vue3-toastify';
  import Spinner from '@/components/ui/Spinner.vue';
  import { useI18n } from 'vue-i18n';
  import { getApps } from '@/services/auth';
  const { cookies } = useCookies();

  const route = useRoute();
  const router = useRouter();
  const { locale, t } = useI18n();

  const id = route.params.id;

  const isApplied = ref<boolean>(false);
  const applyLoading = ref<boolean>(false);
  const loading = ref<boolean>(false);
  const vacancy = ref<IVacancy | null>(null);
  const apps = ref<any[]>([]);

  const token = computed(() => {
    return cookies.get('token');
  });
  const profile = computed(() => {
    return useCoreStore().profile;
  });
  const profileType = computed(() => {
    return cookies.get('profile-type');
  });

  const localePostfix = computed(() => {
    return locale.value === 'ru' ? 'Ru' : locale.value === 'en' ? 'En' : 'Tj';
  });

  const getVacancy = () => {
    getVacancyByIdRequest(id as string).then(res => {
      vacancy.value = res.payload || null;
      nextTick(() => {
        if (profile.value?.companyId) return;
        checkVacancyForApply(vacancy.value?.id || '', profile.value?.applicantId || '').then(response => {
          isApplied.value = !!response?.data?.payload;
        });
      });
    });
  };

  const applyVacancy = (id: string) => {
    if (token.value && profileType.value === 'user') {
      if (!profile.value?.applicantId) {
        router.push({ name: 'resume' });
        toast.warning(t('vacanciesPage.fillOutResumeToRespondToJobOpenings'), { autoClose: 4000 });
      }
      applyLoading.value = true;
      applyToVacancy(id, profile.value?.applicantId || '')
        .then(() => {
          isApplied.value = true;
        })
        .finally(() => {
          applyLoading.value = false;
        });
    } else if (token.value && profileType.value === 'company') {
      toast.warning(t('vacanciesPage.logInAsJobSeekerToRespondToJobOpenings'), { autoClose: 4000 });
    } else router.push({ name: 'auth' });
  };

  async function addVacancyToFavorite(vacancyId: string) {
    loading.value = true;
    await addVacancyToFavoriteRequest(profile.value?.id || '', vacancyId)
      .then(() => {
        toast.success('Успешно добавлено в избранные');
      })
      .finally(() => (loading.value = false));
  }

  const getAppsHandler = () => {
    getApps().then((response: any) => {
      apps.value = response?.data?.languageLevels;
    });
  };

  const getLanguageLevel = (language: any) => {
    return apps.value.filter((app: any) => app.id == language.languageLevelId)[0][`name${localePostfix.value}`];
  };
  getAppsHandler();
  getVacancy();
</script>
