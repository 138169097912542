<template>
  <section class="bg-white px-4 md:px-8 py-10 mt-8 rounded">
    <p>{{ t('employerPage.contactInformation') }}</p>
    <div class="flex flex-col md:flex-row justify-between gap-8 mt-8">
      <Select
        :error-text="t('requiredField')"
        :is-error="isSubmitted && v$.regionId.$invalid"
        :items="regions"
        class="w-full"
        v-model="contact.regionId"
        :label="t('region')"
        :placeholder="t('selectRegion')"
        @update:model-value="getCitiesHandler"
      />
      <Select
        :disabled="!contact.regionId"
        class="w-full"
        :error-text="t('requiredField')"
        :is-error="isSubmitted && v$.city.$invalid"
        :items="cities"
        v-model="contact.city"
        :label="t('regionCity')"
        :placeholder="t('regionCityPlaceholder')"
      />
    </div>
    <div class="flex flex-col md:flex-row justify-between gap-8 mt-8">
      <Input
        class="w-full"
        :error-text="t('requiredField')"
        :is-error="isSubmitted && v$.completeAddress.$invalid"
        v-model="contact.completeAddress"
        :label="t('employerPage.fullAddress')"
        placeholder="Душанбе, Сино 44"
      />
    </div>
    <div class="flex flex-col md:flex-row justify-between gap-8 mt-8">
      <Input class="w-full" v-model="contact.latitude" :label="t('latitude')" :placeholder="t('latitude')" />
      <Input class="w-full" v-model="contact.longitude" :label="t('longitude')" :placeholder="t('longitude')" />
    </div>

    <p class="mt-8">{{ t('employerPage.selectPointOnTheMap') }}</p>
    <GoogleMap
      class="mt-2"
      api-key="AIzaSyB8bFrSy2jA0gZkmf9SUycVL26Lc00_UOI"
      style="width: 100%; height: 500px"
      :center="center"
      :zoom="15"
      @click="getData"
    >
      <Marker id="marker" :options="{ position: center }" />
    </GoogleMap>
    <div class="mt-8 flex justify-start">
      <Button :loading="loading" :disabled="loading" @click="saveData" variant="primary">{{ t('save') }}</Button>
    </div>
  </section>
</template>
<script setup lang="ts">
  import { onMounted, reactive, ref } from 'vue';
  import { computed } from 'vue';
  import { required } from '@vuelidate/validators';
  import Input from '@/components/ui/Input.vue';
  import { useVuelidate } from '@vuelidate/core';
  import Button from '@/components/ui/Button.vue';
  import { createCompanyContactInformation, updateCompanyContactInformation } from '@/services/company';
  import { getRegions } from '@/services/regions';
  import Select from '@/components/ui/Select.vue';
  import { toast } from 'vue3-toastify';
  import { GoogleMap, Marker } from 'vue3-google-map';
  import { useI18n } from 'vue-i18n';
  import { loginApplicant } from '@/services/auth';

  const { t, locale } = useI18n();

  const props = defineProps<{
    companyId: string;
    data: object | null;
    regionData: { regionId: string; cityId: string };
  }>();
  const lat = ref<number>(38.552011);
  const lng = ref<number>(68.765051);
  const center = computed(() => {
    return { lat: lat.value, lng: lng.value };
  });

  const isSubmitted = ref(false);
  const loading = ref(false);
  const regions = ref<any[]>([]);
  const cities = ref<any[]>([]);
  const allRegions = ref<any[]>([]);
  const contact = reactive({
    regionId: null,
    completeAddress: '',
    city: '',
    findOnMap: '',
    latitude: '',
    longitude: '',
  });

  const localePostfix = computed(() => {
    return locale.value === 'ru' ? 'Ru' : locale.value === 'en' ? 'En' : 'Tj';
  });

  const rules = computed(() => ({
    completeAddress: { required },
    regionId: { required },
    city: { required },
  }));

  const getData = (data: any) => {
    contact.latitude = data.latLng.lat().toString();
    contact.longitude = data.latLng.lng().toString();
    lat.value = +data.latLng.lat();
    lng.value = +data.latLng.lng();
  };

  const saveData = () => {
    isSubmitted.value = true;
    if (v$.value.$invalid) {
      v$.value.$touch();
      return;
    }
    loading.value = true;
    //@ts-ignore
    if (props.data) {
      updateCompanyContactInformation(props.companyId, contact)
        .then(() => {
          toast.success(`${t('dataSuccessfullyUpdated')}`);
        })
        .finally(() => {
          isSubmitted.value = false;
          loading.value = false;
        });
    } else {
      createCompanyContactInformation(props.companyId, contact)
        .then(() => {
          toast.success(`${t('dataSuccessfullySaved')}`);
        })
        .finally(() => {
          isSubmitted.value = false;
          loading.value = false;
        });
    }
  };

  const getRegionsHandler = () => {
    getRegions().then((response: any) => {
      allRegions.value = response.data.payload.items || [];
      getCitiesHandler();
      response.data.payload.items?.forEach((item: any) => {
        if (!item.parentId) {
          regions.value.push({
            value: item.id,
            title: item[`name${localePostfix.value}`],
            viewTitle: item[`name${localePostfix.value}`],
          });
        }
      });
    });
  };
  const getCitiesHandler = () => {
    if (!contact.regionId) return;
    cities.value = [];
    allRegions.value.forEach(region => {
      if (region.parentId === contact.regionId) {
        cities.value.push({
          value: region.id,
          title: region[`name${localePostfix.value}`],
          viewTitle: region[`name${localePostfix.value}`],
        });
      }
    });
  };

  onMounted(async () => {
    console.log(props.data);
    const dataCopy = JSON.parse(JSON.stringify(props.data));
    const regionDataCopy = JSON.parse(JSON.stringify(props.regionData));
    contact.regionId = regionDataCopy?.regionId || '';
    contact.completeAddress = dataCopy?.completeAddress || '';
    contact.city = regionDataCopy?.cityId || '';
    contact.findOnMap = dataCopy?.findOnMap || '';
    contact.latitude = dataCopy?.latitude || '38.5690591';
    contact.longitude = dataCopy?.longitude || '68.7609854';
    lat.value = +contact.latitude;
    lng.value = +contact.longitude;
    await getRegionsHandler();
  });

  const v$ = useVuelidate(rules, contact);
</script>

<style scoped></style>
