<template>
  <main class="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
    <h1 class="text-3xl font-medium text-[#202124]">{{ t('applicantPage.favoriteVacancies') }}</h1>
    <div class="h-44 flex justify-center items-center" v-if="loading">
      <Spinner />
    </div>
    <div v-else-if="vacancies && vacancies.length" class="grid grid-cols-1 md:grid-cols-2 mt-14 gap-4">
      <div v-for="vacancy in vacancies" :key="vacancy.id" class="flex justify-between bg-white p-8 rounded-xl">
        <div class="flex items-center gap-6">
          <div>
            <img :src="vacancy.companyLogo" :alt="vacancy.companyName" class="w-14 h-14 object-contain" />
          </div>
          <div>
            <p class="text-lg font-medium text-[#202124]">
              {{ vacancy[`vacancyName${localePostfix}`] }}
            </p>
            <div class="mt-3 flex items-center gap-8">
              <div class="flex items-center gap-2.5">
                <SvgIcon name="briefcase-outline" class="text-grey-400" />
                <span class="text-sm font-normal text-grey-400">
                  {{ vacancy.companyName }}
                </span>
              </div>
              <div class="flex items-center gap-2.5">
                <SvgIcon name="location-marker" class="text-grey-400" />
                <span class="text-sm font-normal text-grey-400">
                  {{ vacancy?.region[`name${localePostfix}`] }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center gap-2">
          <router-link
            :to="{ name: 'vacancy-detail', params: { id: vacancy.vacancyId } }"
            class="p-2 rounded-lg bg-[#1967D2] bg-opacity-10"
          >
            <SvgIcon name="eye" class="text-[#1967D2]" />
          </router-link>
          <button class="p-2 rounded-lg bg-[#1967D2] bg-opacity-10" @click="removeVacancyHandler(vacancy.id)">
            <SvgIcon name="blue-trash" class="text-[#1967D2]" />
          </button>
        </div>
      </div>
    </div>
    <div v-else class="mt-8 flex items-center justify-center p-8 border border-[#ECEDF2] bg-white rounded-lg">
      <p class="text-grey-200">{{ t('notFound') }}</p>
    </div>
    <div class="mt-8">
      <Pagination
        v-model:modelValue="searchParams.page"
        v-model:pageSize="searchParams.size"
        :totalCount="meta.totalCount"
        :totalPage="meta.totalPages"
      />
    </div>
  </main>
</template>

<script setup lang="ts">
  import { computed, ref, watch } from 'vue';
  import Pagination from '@/components/ui/Pagination.vue';
  import SvgIcon from '@/components/ui/SvgIcon.vue';
  import { getFavoriteVacanciesRequest, removeFavoriteVacancyRequest } from '@/services/vacancy';
  import { useCoreStore } from '@/stores/core';
  import { type IFavoriteVacancy } from '@/types/vacancy';
  import { useI18n } from 'vue-i18n';
  import Spinner from '@/components/ui/Spinner.vue';

  const { t, locale } = useI18n();

  const coreStore = useCoreStore();

  const loading = ref(true);

  const vacancies = ref<IFavoriteVacancy[]>([]);
  const searchParams = ref({
    page: 1,
    size: 6,
  });
  const meta = ref({
    totalCount: 0,
    totalPages: 0,
  });

  const localePostfix = computed(() => {
    return locale.value === 'ru' ? 'Ru' : locale.value === 'en' ? 'En' : 'Tj';
  });

  setTimeout(() => {
    getVacancies();
  }, 500);

  watch(
    searchParams,
    () => {
      getVacancies();
    },
    { deep: true }
  );

  async function getVacancies() {
    loading.value = true;
    const res = await getFavoriteVacanciesRequest(coreStore.profile?.id as string, searchParams.value);
    vacancies.value = res.payload.items;
    meta.value.totalCount = res.payload.totalCount;
    meta.value.totalPages = res.payload.totalPages;
    loading.value = false;
  }
  async function removeVacancyHandler(vacancyId: string) {
    await removeFavoriteVacancyRequest(vacancyId).then(() => {
      getVacancies();
    });
  }
</script>
