<template>
  <div class="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
    <h3 class="text-3xl font-medium pl-4 md:pl-0">{{ t('employerPage.companyProfile') }}</h3>
    <div class="flex justify-center h-44 items-center" v-if="getLoading">
      <Spinner />
    </div>
    <div v-else>
      <div v-if="data">
        <MyProfile :companyId="employer?.companyId" :data="data" />
        <UserInfo :data="data?.responsiblePerson" :companyId="employer?.companyId" class="mt-10" />
        <SocialLinks :data="data?.socialNetwork" :companyId="employer?.companyId" class="mt-10" />
        <ContactInfo
          :regionData="regionData"
          :data="data?.contactInformation"
          :companyId="employer?.companyId"
          class="mt-10"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import MyProfile from './components/MyProfile.vue';
  import UserInfo from './components/UserInfo.vue';
  import SocialLinks from './components/SocialLinks.vue';
  import ContactInfo from './components/ContactInfo.vue';
  import { onMounted, ref } from 'vue';
  import { getCompanyInfo } from '@/services/auth';
  import { getCompanyById } from '@/services/company';
  import Spinner from '@/components/ui/Spinner.vue';
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n();

  const employer = ref<any>(null);
  const getLoading = ref(false);
  const data = ref<any>(null);
  const regionData = ref<{ regionId: string; cityId: string }>({ regionId: '', cityId: '' });

  const getCompanyInfoHandler = () => {
    getLoading.value = false;
    getCompanyInfo()
      .then((response: any) => {
        employer.value = response?.data;
        getCompanyByIdHandler(employer.value.companyId);
      })
      .finally(() => (getLoading.value = false));
  };

  function getCompanyByIdHandler(id: string) {
    getLoading.value = false;
    getCompanyById(id)
      .then((response: any) => {
        data.value = response.data.payload;
        regionData.value.regionId = data.value?.region?.id || '';
        regionData.value.cityId = data.value?.city || '';
      })
      .finally(() => (getLoading.value = false));
  }

  onMounted(() => {
    getCompanyInfoHandler();
  });
</script>
