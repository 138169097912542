<template>
  <div
    id="drop_zone"
    class="p-4 border border-dashed border-grey-200 w-full h-[350px] flex items-center justify-center"
  >
    <div class="flex flex-col items-center">
      <p class="mt-4 font-normal text-sm text-primary-500">Перетащите файл для загрузки</p>

      <p class="mt-4 font-normal text-sm text-grey-400">
        To upload file size is (Max 5Mb) and allowed file types are (.doc, .docx, .pdf)
      </p>
      <label
        for="file1-input"
        class="mt-4 relative inline-block px-3.5 py-4 bg-primary-500 bg-opacity-5 cursor-pointer"
      >
        <span class="text-primary-500 text-[15px] font-normal"> Выбрать файл </span>
        <input
          type="file"
          accept=".doc,.pdf,.docx"
          id="file1-input"
          hidden
          :multiple="false"
          @change="coverSelectFile"
        />
      </label>
      <div v-for="file in displayValue" :key="file.name">
        {{ file.name }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { PropType } from 'vue';
  import { ref, watch } from 'vue';
  const emit = defineEmits<{
    (e: 'update:modelValue', value: File[]): void;
  }>();

  const props = defineProps({
    accept: { type: String, default: '.doc,.pdf,.docx' },
    modelValue: {
      type: Array as PropType<File[]>,
      default: () => [],
    },
  });

  const displayValue = ref<File[]>([]);

  watch(
    () => props.modelValue,
    () => {
      displayValue.value = props.modelValue;
    },
    {
      immediate: true,
    }
  );
  function coverSelectFile(event: Event) {
    if (event.target instanceof HTMLInputElement && event.target?.files?.length) {
      displayValue.value = event.target.files as any;
      emit('update:modelValue', displayValue.value);
    }
  }

  const dropZone = document.body;
  if (dropZone) {
    const hoverClassName = 'hover';

    dropZone.addEventListener('dragenter', function (e) {
      e.preventDefault();
      dropZone.classList.add(hoverClassName);
    });

    dropZone.addEventListener('dragover', function (e) {
      e.preventDefault();
      dropZone.classList.add(hoverClassName);
    });

    dropZone.addEventListener('dragleave', function (e) {
      e.preventDefault();
      dropZone.classList.remove(hoverClassName);
    });

    // Это самое важное событие, событие, которое дает доступ к файлам
    dropZone.addEventListener('drop', function (e) {
      e.preventDefault();
      dropZone.classList.remove(hoverClassName);

      const files = Array.from(e?.dataTransfer?.files || []);
      displayValue.value = [...files];
      emit('update:modelValue', displayValue.value);
    });
  }
</script>

<style scoped></style>
