// import type { TQueryParams } from '@/types/global';

export function clearQueryParams(obj: any) {
  const object: any = { ...obj };
  for (const item in object) {
    //@ts-ignore
    if (
      object[item] === '' ||
      object[item] === null ||
      //@ts-ignore
      (typeof object[item] === 'object' && !object?.[item]?.length)
    ) {
      delete object[item];
    }
  }
  return object;
}
