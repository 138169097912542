<template>
  <div :class="{ 'md:py-[120px]': !isStartPage }" class="mx-auto max-w-7xl py-10 px-4 sm:px-6 lg:px-8">
    <h2 v-if="!isStartPage" class="text-2xl mb-5 text-center md:mb-10">{{ t('homePage.recommendedVacancies') }}</h2>
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 md:gap-6">
      <router-link
        :to="{ name: 'vacancy-detail', params: { id: vacancy.id } }"
        v-for="vacancy in vacancies"
        :key="vacancy.id"
        class="flex space-x-4 bg-white p-4 md:p-6 rounded-md"
        :class="{ 'border border-grey-100/50 hover:bg-grey-100/10': isStartPage }"
      >
        <img class="w-[50px] h-[50px] rounded-md" :src="vacancy.logo || addPhoto" alt="" />
        <div>
          <h3 class="text-xl mb-1">{{ vacancy[`position${localePostfix}`] }}</h3>
          <div class="flex flex-wrap items-center gap-y-2 gap-x-4 w-full">
            <span class="flex text-grey-500 text-sm">
              <SvgIcon class="mr-1" name="portfolio" />
              {{ vacancy.companyName }}
            </span>
            <span class="flex text-grey-500 text-sm">
              <SvgIcon class="mr-1" name="location" />
              {{ vacancy.region }}
            </span>
            <span class="flex text-grey-500 text-sm">
              <SvgIcon class="mr-1" name="time" />
              {{ readableDate(vacancy.createdAt, locale) }}
            </span>
            <span class="flex text-grey-500 text-sm">
              <SvgIcon class="mr-1" name="money" />
              {{ vacancy.salaryCurrency }}c
            </span>
          </div>
          <div class="flex flex-wrap gap-y-2 gap-x-4 mt-4 overflow-x-auto">
            <div
              v-for="(vacancySchedule, index) in vacancy.vacancySchdule"
              :key="index"
              :class="{
                'bg-primary-300 text-primary-500': !index,
                'bg-green-pale text-green': index,
              }"
              class="rounded-full px-4 py-0.5 whitespace-nowrap"
            >
              {{ vacancySchedule[`name${localePostfix}`] }}
            </div>
          </div>
        </div>
      </router-link>
    </div>
    <div class="flex justify-center mt-8 md:mt-14">
      <router-link :to="{ name: 'search-vacancies' }">
        <Button class="w-full md:w-auto" variant="primary"> {{ t('startHome.buttonLoadMore') }} </Button>
      </router-link>
    </div>
  </div>
</template>
<script setup lang="ts">
  import SvgIcon from '@/components/ui/SvgIcon.vue';
  import Button from '@/components/ui/Button.vue';
  import { getAllVacanciesRequest } from '@/services/vacancy';
  import { ref, computed } from 'vue';
  import type { IVacancy } from '@/types/vacancy';
  import { readableDate } from '@/utils/filters';
  import addPhoto from '@/assets/images/add-photo.png';
  import { useI18n } from 'vue-i18n';
  import { useRoute } from 'vue-router';

  const { locale } = useI18n({ useScope: 'global' });
  const localePostfix = computed(() => {
    return locale.value === 'ru' ? 'Ru' : locale.value === 'en' ? 'En' : 'Tj';
  });
  const loading = ref(false);
  const vacancies = ref<IVacancy[]>([]);
  type TProps = {
    isStartPage?: boolean;
  };

  defineProps<TProps>();

  const route = useRoute();

  const { t } = useI18n();

  const getVacancies = () => {
    loading.value = true;

    getAllVacanciesRequest({ size: 6, WorkExperience: route.path.includes('start') ? 0 : null })
      .then(res => {
        vacancies.value = res?.payload?.items || [];
      })
      .finally(() => {
        loading.value = false;
      });
  };

  getVacancies();
</script>
