<template>
  <div>
    <div :class="['flex items-center justify-between']">
      <label v-if="label" :for="id" class="block text-[15px] text-black font-normal mb-1.5">
        {{ label }}
      </label>
    </div>
    <div v-if="!nativeSelector" v-click-outside="hideDropdown" class="relative text-grey-900" :class="[sizes[size]]">
      <button
        class="flex items-center justify-between space-x-2 w-full bg-primary-50 px-4 rounded-md"
        :class="{
          'rounded-md border-primary-500 border px-3': withBorder,
          'border-red focus:!border-red': isError && !disabled,
          'bg-white': (withBorder && !disabled) || isWhite,
          'cursor-not-allowed border-grey-100 focus:border-grey-100 text-grey-300 bg-grey-50': disabled,
          'py-4': size === 'sm',
          'py-1.5': size === 'md',
        }"
        type="button"
        :disabled="disabled"
        @click.stop="isOpen = !isOpen"
      >
        <span
          v-if="showSelectedValue"
          class="text-md"
          :class="[
            !buttonTitle && placeholder ? 'text-grey-200' : 'md:text-grey-600 text-grey-900 font-medium md:font-normal',
          ]"
        >
          {{ buttonTitle || placeholder }}
        </span>
        <span :class="{ 'rotate-180 -mt-1': isOpen }">
          <SvgIcon
            name="chevron-down"
            class="transition-all text-grey-300 mb-0 text-xl w-6 h-6"
            :class="{ ' pt-0.5 pl-1': !isOpen }"
          />
        </span>
      </button>

      <ul
        class="absolute -bottom-1.5 z-50 transition-all ease-linear duration-300 translate-y-full rounded-lg divide-grey-100 bg-white divide-y border-primary-300"
        :class="{
          'max-h-80 overflow-auto shadow-md border': isOpen,
          'max-h-0 overflow-hidden': !isOpen,
          'left-0': position === 'left',
          'right-0': position === 'right',
          'w-full': position === 'full',
        }"
      >
        <li
          v-for="(item, index) in items"
          :key="index"
          :class="selectedOption?.value === item?.value ? 'bg-grey-100/50' : ''"
          class="p-3 text-grey-900 font-normal hover:bg-grey-50 cursor-pointer flex items-center space-x-2"
          @click="handleChange(item)"
        >
          <p class="flex items-center">
            <span>
              {{ item.title }}
            </span>
          </p>
        </li>
      </ul>
    </div>
    <select
      v-else
      :id="id"
      ref="nativeSelect"
      hidden
      name="nativeSelect"
      class="flex items-center justify-between space-x-2 w-full cursor-pointer"
      :class="[
        {
          'rounded-md border-grey-100 border-2 bg-white px-3 py-2.5': withBorder,
          'border-red focus:!border-red': isError && !disabled,
          'cursor-not-allowed border-grey-100 focus:border-grey-100 text-grey-300 bg-grey-50': disabled,
        },
        variants.select[variant],
      ]"
      :placeholder="placeholder"
      :modelValue="modelValue"
      :disabled="disabled"
      @change="changeHandler"
    >
      <option value="">{{ placeholder }}</option>
      <option v-for="(item, index) in items" :key="index" :value="item.value" :selected="item.value === modelValue">
        {{ item.title }}
      </option>
    </select>
    <p v-if="isError && !disabled" class="text-sm text-red mt-1">
      {{ errorText }}
    </p>
  </div>
</template>

<script setup lang="ts">
  import { ref, toRefs, computed, watch } from 'vue';
  import SvgIcon from '@/components/ui/SvgIcon.vue';

  type TItem = {
    value: string | number | boolean | null;
    title: string;
    viewTitle?: string;
  };

  const sizes = {
    md: 'text-md font-medium',
    sm: 'text-sm font-normal',
  };

  type TProps = {
    withBorder?: boolean;
    position?: 'left' | 'right' | 'full';
    modelValue: string | number | boolean | null;
    items: TItem[];
    size?: keyof typeof sizes;
    placeholder?: string;
    disabled?: boolean;
    isWhite?: boolean;
    isError?: boolean;
    errorText?: string;
    label?: string;
    icon?: string;
    nativeSelector?: boolean;
    showSelectedValue?: boolean;
    variant?: 'primary' | 'green';
    id?: string;
  };

  const variants = {
    select: {
      primary: 'focus:border-primary-500 focus:ring-0',
      green: 'focus:border-green-500 focus:ring-0',
    },
  };

  const props = withDefaults(defineProps<TProps>(), {
    withBorder: false,
    position: 'full',
    size: 'sm',
    placeholder: 'Выберите значение',
    showSelectedValue: true,
    icon: 'chevron-down',
    variant: 'primary',
  });
  const emit = defineEmits<{
    (e: 'update:modelValue', value: string | number | boolean | null): void;
  }>();

  const { items } = toRefs(props);
  const isOpen = ref<boolean>(false);
  const selectedOption = ref<TItem | undefined>(items.value.find(item => item.value === props.modelValue));
  const nativeSelect = ref(null);

  const buttonTitle = computed<string>(() => {
    return selectedOption.value?.viewTitle || selectedOption.value?.title || '';
  });

  watch(
    () => props.items,
    () => {
      selectedOption.value = props.items.find(item => item.value === props.modelValue);
    },
    { deep: true }
  );

  watch(
    () => props.modelValue,
    () => {
      if (!props.modelValue) {
        selectedOption.value = undefined;
      } else {
        selectedOption.value = props.items.find(item => item.value === props.modelValue);
      }
    },
    { immediate: true }
  );

  const handleChange = (value: TItem): void => {
    selectedOption.value = value;
    isOpen.value = false;
    emit('update:modelValue', selectedOption.value.value);
  };

  const hideDropdown = () => {
    isOpen.value = false;
  };

  function changeHandler(e: Event) {
    if (e.currentTarget instanceof HTMLSelectElement) {
      emit('update:modelValue', e.currentTarget?.value || '');
    }
  }
</script>
