<template>
  <div v-if="templatesCategories?.length" class="bg-primary-200">
    <div class="mx-auto max-w-7xl py-10 md:py-[120px] px-4 sm:px-6 lg:px-8">
      <h2 class="font-medium text-3xl">{{ t('companyHome.templatesType') }}</h2>
      <p class="mt-14 font-medium">
        {{ isStartPage ? t('companyHome.forApplicant') : t('companyHome.forEmployer') }}
      </p>
      <div class="mt-8">
        <div v-if="loading" class="flex items-center justify-center"><Spinner /></div>
        <div
          v-else-if="templatesCategories && templatesCategories.length"
          class="grid md:grid-cols-4 grid-cols-1 justify-start gap-4"
        >
          <div
            @click="getTemplatesByCategoryHandler(category.id)"
            :class="{ 'text-primary-500 underline': selectedId === category.id }"
            class="text-grey-200 hover:text-primary-500 cursor-pointer"
            v-for="category in templatesCategories"
            :key="category.id"
          >
            <span>{{ category[`name${localePostfix}`] }}</span>
          </div>
        </div>
        <div v-else class="text-center text-grey-200"></div>
      </div>
      <div class="mt-12">
        <div v-if="templateLoading" class="flex items-center justify-center"><Spinner /></div>
        <div v-else-if="templates && templates.length" class="grid md:grid-cols-3 grid-cols-1 justify-start gap-4">
          <a
            :href="template.filePath"
            :download="`${template[`name${localePostfix}`]}.pdf`"
            target="_blank"
            v-for="template in templates"
            :key="template.id"
            class="rounded-md cursor-pointer px-7 py-6 bg-primary-200"
          >
            <div class="flex gap-2 items-start">
              <SvgIcon name="file-download" class="text-primary-500 mt-0.5" />
              <div>
                <time class="text-sm text-grey-200">{{ readableDate(template.createdAt, locale) }}</time>
                <p class="text-primary-500">{{ template[`name${localePostfix}`] }}</p>
              </div>
            </div>
          </a>
        </div>
        <div v-else class="text-center text-grey-200">{{ t('companyHome.chooseCategory') }}</div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { computed, onMounted, ref } from 'vue';
  import { getTemplatesByCategoryId, getTemplatesCategories } from '@/services/templates';
  import Spinner from '@/components/ui/Spinner.vue';
  import SvgIcon from '@/components/ui/SvgIcon.vue';
  import { readableDate } from '@/utils/filters';
  import { useI18n } from 'vue-i18n';

  const { t, locale } = useI18n();

  const localePostfix = computed(() => {
    return locale.value === 'ru' ? 'Ru' : locale.value === 'en' ? 'En' : 'Tj';
  });

  const props = defineProps<{ isStartPage?: boolean }>();

  type TCategory = {
    id: string;
    rootCategoryId: string;
    nameRu: string;
    nameTj: string;
    nameEn: string;
    order: number;
  };

  type TTemplate = {
    categoryId: string;
    categoryNameEn: string;
    categoryNameRu: string;
    categoryNameTj: string;
    createdAt: string;
    filePath: string;
    id: string;
    nameEn: string;
    nameRu: string;
    nameTj: string;
    order: number;
    rootCategoryId: string;
    updatedAt: string | null;
  };

  const templateLoading = ref(false);
  const loading = ref(false);
  const templatesCategories = ref<TCategory[]>();
  const templates = ref<TTemplate[]>();
  const rootId = ref(
    props.isStartPage ? '6f29d998-b3b6-4788-a089-b68274161e4e' : '60205776-ad59-475f-80e8-712c35ed7496'
  );
  const selectedId = ref('');

  function getTemplateCategoriesHandler() {
    loading.value = true;
    getTemplatesCategories(rootId.value)
      .then(response => {
        templatesCategories.value = response.data.payload;
        if (templatesCategories.value?.length) {
          getTemplatesByCategoryHandler(templatesCategories.value[0].id);
        }
      })
      .finally(() => (loading.value = false));
  }
  function getTemplatesByCategoryHandler(id: string) {
    selectedId.value = id;
    templateLoading.value = true;
    getTemplatesByCategoryId(id)
      .then(response => {
        templates.value = response.data.payload;
      })
      .finally(() => (templateLoading.value = false));
  }

  onMounted(() => {
    getTemplateCategoriesHandler();
  });
</script>
