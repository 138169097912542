<template>
  <section class="bg-white px-4 md:px-8 py-10 mt-8 rounded">
    <p>{{ t('applicantPage.contactInformation') }}</p>
    <div class="flex flex-col md:flex-row justify-between gap-8 mt-8">
      <Select
        :items="regions"
        class="w-full"
        v-model="contact.regionId"
        :label="t('region')"
        @update:model-value="getCitiesHandler"
        :placeholder="t('choose')"
      />
      <Select
        :disabled="!contact.regionId"
        class="w-full"
        :error-text="t('requiredField')"
        :is-error="isSubmitted && v$.city.$invalid"
        :items="cities"
        v-model="contact.city"
        :label="t('regionCity')"
        placeholder="Душанбе"
      />
    </div>
    <div class="flex flex-col md:flex-row justify-between gap-8 mt-8">
      <Input
        class="w-full"
        :error-text="t('requiredField')"
        :is-error="isSubmitted && v$.completeAddress.$invalid"
        v-model="contact.completeAddress"
        :label="t('applicantPage.fullAddress')"
        placeholder="Душанбе, Сино 44"
      />
    </div>
    <div class="mt-8 flex justify-start">
      <Button :loading="loading" :disabled="loading" @click="saveData" variant="primary">{{ t('save') }}</Button>
    </div>
  </section>
</template>
<script setup lang="ts">
  import { onMounted, reactive, ref } from 'vue';
  import { computed } from 'vue';
  import { required } from '@vuelidate/validators';
  import Input from '@/components/ui/Input.vue';
  import { useVuelidate } from '@vuelidate/core';
  import Button from '@/components/ui/Button.vue';
  import { updateApplicantContactInformation, createApplicantContactInformation } from '@/services/applicant';
  import { getRegions } from '@/services/regions';
  import Select from '@/components/ui/Select.vue';
  import { toast } from 'vue3-toastify';
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n();

  const props = defineProps<{ id: string; data: object | null }>();

  const isSubmitted = ref(false);
  const loading = ref(false);
  const regions = ref<any[]>([]);
  const cities = ref<any[]>([]);
  const allRegions = ref<any[]>([]);
  const contact = reactive({
    regionId: null,
    completeAddress: '',
    city: '',
    latitude: '',
    longitude: '',
  });

  const rules = computed(() => ({
    completeAddress: { required },
    city: { required },
  }));

  const saveData = () => {
    isSubmitted.value = true;
    if (v$.value.$invalid) {
      v$.value.$touch();
      return;
    }
    loading.value = true;
    //@ts-ignore
    if (props.data?.id) {
      updateApplicantContactInformation(props.id, contact)
        .then(() => {
          toast.success(t('successfullySaved'));
        })
        .finally(() => {
          isSubmitted.value = false;
          loading.value = false;
        });
    } else {
      createApplicantContactInformation(props.id, contact)
        .then(() => {
          toast.success(t('successfullySaved'));
        })
        .finally(() => {
          isSubmitted.value = false;
          loading.value = false;
        });
    }
  };

  const getCitiesHandler = () => {
    if (!contact.regionId) return;
    cities.value = [];
    allRegions.value.forEach(region => {
      if (region.parentId === contact.regionId) {
        cities.value.push({
          value: region.id,
          title: region.nameRu,
          viewTitle: region.nameRu,
        });
      }
    });
  };

  const getRegionsHandler = () => {
    getRegions().then((response: any) => {
      allRegions.value = response.data.payload.items || [];
      response.data.payload.items?.forEach((item: any) => {
        if (!item.parentId) {
          regions.value.push({
            value: item.id,
            title: item.nameRu,
            viewTitle: item.nameRu,
          });
        }
      });
      getCitiesHandler();
    });
  };

  getRegionsHandler();

  onMounted(() => {
    if (props.data) {
      const dataCopy = JSON.parse(JSON.stringify(props.data));
      contact.regionId = dataCopy.region.id || '';
      contact.completeAddress = dataCopy.completeAddress || '';
      contact.city = dataCopy.city || '';
      contact.latitude = dataCopy.latitude || '';
      contact.longitude = dataCopy.longitude || '';
    }
  });

  const v$ = useVuelidate(rules, contact);
</script>
