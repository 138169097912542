<template>
  <section class="bg-white px-4 md:px-8 py-10 mt-8 rounded">
    <p>{{ t('socialMedia') }}</p>
    <div class="flex flex-col md:flex-row justify-between gap-8 mt-8">
      <Input class="w-full" v-model="user.facebook" label="facebook" placeholder="facebook" />
      <Input class="w-full" v-model="user.twitter" placeholder="@chiki" label="twitter" />
    </div>
    <div class="flex flex-col md:flex-row justify-between gap-8 mt-8">
      <Input class="w-full" v-model="user.linkedin" label="linkedin" placeholder="linkedin" />
      <Input class="w-full" v-model="user.googlePlus" label="googlePlus" placeholder="googlePlus" />
    </div>
    <div class="mt-8 flex justify-start">
      <Button :loading="loading" :disabled="loading" @click="saveData" variant="primary">{{ t('save') }}</Button>
    </div>
  </section>
</template>
<script setup lang="ts">
  import { onMounted, reactive, ref } from 'vue';
  import Input from '@/components/ui/Input.vue';
  import Button from '@/components/ui/Button.vue';
  import { toast } from 'vue3-toastify';
  import { createApplicantSocialNetworks, updateApplicantSocialNetworks } from '@/services/applicant';
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n();

  const props = defineProps<{ id: string; data: any | null }>();

  const isSubmitted = ref(false);
  const loading = ref(false);
  const user = reactive({
    twitter: '',
    googlePlus: '',
    linkedin: '',
    facebook: '',
  });

  onMounted(() => {
    if (props.data) {
      const dataCopy = JSON.parse(JSON.stringify(props.data));
      user.twitter = dataCopy.twitter || '';
      user.googlePlus = dataCopy.googlePlus || '';
      user.linkedin = dataCopy.linkedin || '';
      user.facebook = dataCopy.facebook || '';
    }
  });

  const saveData = () => {
    isSubmitted.value = true;
    loading.value = true;
    //@ts-ignore
    if (props.data.id) {
      updateApplicantSocialNetworks(props.data.id, user)
        .then(() => {
          toast.success(t('successfullySaved'));
        })
        .finally(() => {
          isSubmitted.value = false;
          loading.value = false;
        });
    } else {
      createApplicantSocialNetworks(props.id, user)
        .then(() => {
          toast.success(t('successfullySaved'));
        })
        .finally(() => {
          isSubmitted.value = false;
          loading.value = false;
        });
    }
  };
</script>

<style scoped></style>
