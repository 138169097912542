export const homeTj = {
  header: 'Беҳтарин вакансияҳо шуморо дар ин ҷо интизоранд',
  headerSubtitle: 'Дар байни {vacanciesCount} вакансия кор пайдо кунед',
  linkText: 'Ман коргар меҷӯям',
  createResume: 'Эҷоди резюме',
  internship: 'Коромӯзӣ',
  studentWork: 'Кор барои донишҷӯён',
  invalidWork: 'Кор барои маъюбон',
  popularCategories: 'Категорияҳои маъмули ҷойҳои корӣ',
  allVacancies: 'Ҳамаи вакансияҳо',
  recommendedVacancies: 'Ҷойҳои холии тавсияшаванда',
  showMore: 'Бештар бор кунед',
  howItsWork: {
    title: 'Ин чӣ гуна кор мекунад?',
    description: 'Барои пайдо кардани кори мувофиқ қадамҳоро гузаред',
    register: 'Бақайдгирӣ',
    registerDescription: 'Тавассути тасдиқи рақами телефони худ бо тартиби оддии бақайдгирӣ гузаред',
    createProfile: 'Эҷоди профил',
    createProfileDescription: 'Раванди одии бақайдгириро тавассути тасдиқи рақами телефони худ гузаред',
    resume: 'Резюмеи худро бор кунед',
    resumeDescription:
      'Агар шумо резюмеи тайёр дошта бошед, онро бор кунед, агар не, шумо метавонед резюмеи онлайн эҷод кунед',
    applyToVacancy: 'Барои ҷойҳои холии корӣ муроҷиат кунед',
    applyToVacancyDescription: 'Шумо акнун метавонед ба вакансияҳо ба шумо мақбул дархост равон кунед',
  },
  markAsRead: 'Ҳамаро хондашуда қайд кардан',
  find: 'Ҷустуҷӯ',
};
