import { startHomeEn } from '@/languages/startHome/startHomeEn';
import { startHomeRu } from '@/languages/startHome/startHomeRu';
import { startHomeTj } from '@/languages/startHome/startHomeTj';
import globalRu from './globalRu.json';
import globalTj from './globalTj.json';
import globalEn from './globalEn.json';
import { globalTsRu } from './globalRu';
import { globalTsEn } from './globalEn';
import { globalTsTj } from './globalTj';
import vacanciesEn from './vacancies/en.json';
import vacanciesTj from './vacancies/tj.json';
import vacanciesRu from './vacancies/ru.json';
import messagesEn from './messages/en.json';
import messagesTj from './messages/tj.json';
import messagesRu from './messages/ru.json';
import aboutEn from './about/en.json';
import aboutTj from './about/tj.json';
import aboutRu from './about/ru.json';
import applicantsEn from './applicants/en.json';
import applicantsRu from './applicants/ru.json';
import applicantsTj from './applicants/tj.json';
import alertsEn from './alerts/en.json';
import alertsTj from './alerts/tj.json';
import alertsRu from './alerts/ru.json';
import authEn from './auth/en.json';
import authRu from './auth/ru.json';
import authTj from './auth/tj.json';
import companiesEn from './companies/en.json';
import companiesRu from './companies/ru.json';
import companiesTj from './companies/tj.json';
import contactsEn from './contacts/en.json';
import contactsRu from './contacts/ru.json';
import contactsTj from './contacts/tj.json';
import newsEn from './news/en.json';
import newsRu from './news/ru.json';
import newsTj from './news/tj.json';
import vacancyEn from './vacancy/en.json';
import vacancyRu from './vacancy/ru.json';
import vacancyTj from './vacancy/tj.json';
import employerEn from './employer/en.json';
import employerRu from './employer/ru.json';
import employerTj from './employer/tj.json';
import applicantEn from './applicant/en.json';
import applicantTj from './applicant/tj.json';
import applicantRu from './applicant/ru.json';
import { homeRu } from '@/languages/home/homeRu';
import { homeEn } from '@/languages/home/homeEn';
import { homeTj } from '@/languages/home/homeTj';
import { companyHomeRu } from '@/languages/companyHome/companyHomeRu';
import { companyHomeTj } from '@/languages/companyHome/companyHomeTj';
import { companyHomeEn } from '@/languages/companyHome/companyHomeEn';

export const messages: any = {
  en: {
    startHome: startHomeEn,
    vacanciesPage: vacanciesEn,
    messagesPage: messagesEn,
    aboutPage: aboutEn,
    alertsPage: alertsEn,
    applicantPage: applicantEn,
    applicantsPage: applicantsEn,
    authPage: authEn,
    companiesPage: companiesEn,
    contactsPage: contactsEn,
    employerPage: employerEn,
    newsPage: newsEn,
    vacancyPage: vacancyEn,
    ...globalEn,
    homePage: homeEn,
    global: globalEn,
    globalTs: globalTsEn,
    companyHome: companyHomeEn,
  },
  ru: {
    startHome: startHomeRu,
    vacanciesPage: vacanciesRu,
    messagesPage: messagesRu,
    aboutPage: aboutRu,
    alertsPage: alertsRu,
    applicantPage: applicantRu,
    applicantsPage: applicantsRu,
    authPage: authRu,
    companiesPage: companiesRu,
    contactsPage: contactsRu,
    employerPage: employerRu,
    newsPage: newsRu,
    vacancyPage: vacancyRu,
    ...globalRu,
    homePage: homeRu,
    global: globalRu,
    companyHome: companyHomeRu,
    globalTs: globalTsRu,
  },
  tj: {
    startHome: startHomeTj,
    vacanciesPage: vacanciesTj,
    messagesPage: messagesTj,
    aboutPage: aboutTj,
    alertsPage: alertsTj,
    applicantPage: applicantTj,
    applicantsPage: applicantsTj,
    authPage: authTj,
    companiesPage: companiesTj,
    contactsPage: contactsTj,
    employerPage: employerTj,
    newsPage: newsTj,
    vacancyPage: vacancyTj,
    ...globalTj,
    homePage: homeTj,
    global: globalTj,
    companyHome: companyHomeTj,
    globalTs: globalTsTj,
  },
};
