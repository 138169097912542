<template>
  <div class="flex justify-start">
    <div class="flex flex-row justify-start border border-grey-100">
      <button
        @click="selectButton(item)"
        v-for="item in props.items"
        :key="item.id"
        :class="{ ' bg-primary-500 text-white': selectedButton.id === item.id }"
        class="bg-grey-50 hover:text-white hover:bg-primary-600 px-8 py-3 border-r border-grey-100"
      >
        {{ item.title }}
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { ref, watch } from 'vue';
  type TProps = {
    items: { title: string; id: number; component?: any }[];
    selectedValue: { title: string; id: number; component?: any };
  };
  const props = defineProps<TProps>();
  const emits = defineEmits(['selectButton']);
  const selectedButton = ref(props.items[0]);
  watch(
    () => props.selectedValue,
    () => {
      selectedButton.value = props.selectedValue;
    },
    {
      immediate: true,
      deep: true,
    }
  );
  function selectButton(item: any) {
    selectedButton.value = item;
    emits('selectButton', item);
  }
</script>
<style scoped></style>
