<template>
  <div class="relative">
    <div class="bg-primary-150">
      <div class="mx-auto max-w-7xl py-[50px] px-4 sm:px-6 lg:px-8">
        <h2 class="text-center font-medium text-[30px]">{{ t('vacanciesPage.getAJob') }}</h2>
        <p class="text-center text-grey-500 mt-5 text-lg">{{ t('home') }} / {{ t('vacanciesPage.vacancies') }}</p>
      </div>
    </div>

    <VacanciesContent />
  </div>
</template>

<script setup lang="ts">
  import VacanciesContent from '@/components/vacancies/VacanciesContent.vue';
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n();
</script>
