export const startHomeTj: Record<string, string> = {
  header: 'ОҒОЗИ КОР ДАР',
  subHeader: 'Кор, кори нопурра, коромӯзӣ барои донишҷӯён ва мутахассисони ҷавон',
  buttonFind: 'Ҷустуҷӯ',
  buttonFindPlaceholder: 'Ҷустуҷӯ аз рӯи вакансияҳо',
  newOffers: 'Пешниҳодҳои нав',
  recommendetVacancies: 'Вакансияҳои тавсияшуда',
  buttonLoadMore: 'Бештар',
  workWithBest: 'Дар беҳтарин ширкатҳо кор кунед',
  workWithBestText: 'Корфармоёни бузургтарин',
  vacancies: 'вакансияҳо',
  downloadAndEnjoy: 'БОРГИРӢ КУНЕД ВА ҲАЛОВАТ БАРЕД',
  downloadAppText: 'Барои ҷустуҷӯи коромӯзӣ ва ҷойҳои корӣ барномаи мобилии KOR.TJ-ро зеркашӣ кунед',
  downloadAppSubText:
    'Ҳазорон ҷойҳои холиро аз назар гузаронед ва ҷои мувофиқро пайдо кунед. Танҳо барномаро зеркашӣ намуда ва сабти ном кунед.',
  yourCompanyTitle: 'Ширкати Шумо намерасад!!!',
  yourCompanySubTitle: 'Саҳифаи ширкати Шуморо илова карда, беҳтарин мутахассисони ҷавонро ҷалб мекунем',
  email: 'email',
  phone: 'Рақами телефон',
  name: 'Номи ширкат',
  buttonOrderCall: 'Дархост равон кардан!',
  validField: 'Майдони ҳатмӣ',
  showPerPage: 'Дар як саҳифа',
};
