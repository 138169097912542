<template>
  <div class="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
    <h3 class="text-3xl font-medium">{{ t('applicantPage.applicantProfile') }}</h3>
    <div class="flex justify-center" v-if="getLoading">
      <Spinner />
    </div>
    <div v-else>
      {{ applicant?.profileId }}
      <MyProfile @created="getApplicantHandler" :id="profile?.profileId || ''" :data="data ?? null" />
      <SocialLinks v-if="data?.id" :data="data?.socialNetworks" :id="data?.id" class="mt-10" />
      <ContactInfo v-if="data?.id" :data="data?.applicantContactInformation" :id="data?.id" class="mt-10" />
    </div>
  </div>
</template>
<script setup lang="ts">
  import MyProfile from './components/MyProfile.vue';
  import SocialLinks from './components/SocialLinks.vue';
  import ContactInfo from './components/ContactInfo.vue';
  import { ref, watch } from 'vue';
  import Spinner from '@/components/ui/Spinner.vue';
  import { getApplicantByProfileId } from '@/services/applicant';
  import { useI18n } from 'vue-i18n';
  import { computed } from 'vue';
  import { useCoreStore } from '@/stores/core';

  const { t } = useI18n();

  const profile = computed(() => {
    return useCoreStore().profile;
  });

  const applicant = ref<any>(null);
  const getLoading = ref(false);
  const data = ref();
  function getApplicantHandler() {
    if (!profile.value?.applicantId) return;
    getLoading.value = true;
    getApplicantByProfileId(profile.value?.profileId!)
      .then(response => {
        data.value = response.data.payload;
      })
      .finally(() => (getLoading.value = false));
  }

  watch(profile, () => {
    if (profile.value) {
      getApplicantHandler();
    }
  });
</script>

<style scoped></style>
