<template>
  <div class="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
    <SearchSection :statistics="statistics!" />
  </div>
  <CategoriesSection :statistics="statistics!" />
  <RecommendedVacanciesSection />
  <HowItWorksSection />
  <LagestEmployersSection />
  <DownloadAppSection class="border-t border-grey-100" />
  <PartnersSection />
</template>
<script setup lang="ts">
  import SearchSection from '@/components/home/SearchSection.vue';
  import CategoriesSection from '@/components/home/CategoriesSection.vue';
  import RecommendedVacanciesSection from '@/components/home/RecommendedVacanciesSection.vue';
  import HowItWorksSection from '@/components/home/HowItWorksSection.vue';
  import LagestEmployersSection from '@/components/home/LagestEmployersSection.vue';
  import DownloadAppSection from '@/components/home/DownloadAppSection.vue';
  import { getHomeStatistics } from '@/services/home';
  import { ref } from 'vue';
  import type { TStatistics } from '@/types/global';
  import PartnersSection from '@/components/home/PartnersSection.vue';

  const statistics = ref<TStatistics>();
  getHomeStatistics().then(res => {
    statistics.value = res.data;
  });
</script>
