<template>
  <div :class="[variants[variant], sizes[size], 'inline-block whitespace-nowrap']">
    <slot></slot>
  </div>
</template>
<script setup lang="ts">
  type TProps = {
    variant: 'error' | 'success' | 'active' | 'light';
    size?: 'sm' | 'md';
  };

  withDefaults(defineProps<TProps>(), {
    variant: 'light',
    size: 'md',
  });

  const variants = {
    error: 'bg-red-pale text-red',
    success: 'bg-green-pale text=green',
    active: 'bg-primary-400 text-primary-500',
    light: 'bg-grey-50 text-grey-500',
  };
  const sizes = {
    sm: 'rounded-full py-1 px-4 text-xs',
    md: 'rounded-xl py-[14px] px-[38px]',
  };
</script>
