import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router';
import { privateRoutes } from './private';
import { useCookies } from 'vue3-cookies';
import { useCoreStore } from '@/stores/core';
const routes: Array<RouteRecordRaw> = [...privateRoutes];
const { cookies } = useCookies();

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const token = cookies.get('token');
  const profileType = cookies.get('profile-type');
  const coreStore = useCoreStore();

  if (token) {
    if (profileType === 'company') {
      await coreStore.getCompanyData();

      if (to.name === 'auth' || to.name === 'registration' || to.name === 'home') {
        next({ name: 'home-company' });
      } else next();
      return;
    } else {
      coreStore.getUser();
      if (to.name === 'auth' || to.name === 'registration') {
        next({ name: 'start-home' });
      } else next();
      return;
    }
  } else if (to.path.includes('profile')) {
    next({
      name: 'home',
    });
  } else {
    next();
  }
});

export default router;
