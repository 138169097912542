export const companyHomeRu = {
  title: 'Разместите вакансию на',
  titleDescription:
    'И находите сотрудников среди тех, кто хочет у вас работать. kor.tj - твоя новая любимая работа здесь',
  addVacancy: 'Разместить вакансию',
  whichEmployee: 'Какие сотрудники есть на',
  whichEmployeeDescription: 'Не ждите откликов - найдите идеального сотрудника сами среди {count} резюме',
  templatesType: 'Типовые шаблоны документов',
  forEmployer: 'Для работодателей',
  forApplicant: 'Для соискателей',
  chooseCategory: 'Выберите категорию',
  toStartTitle: 'С чего начать поиск сотрудников?',
  signUp: 'Зарегистрируйтесь',
  getAccess: 'И получите доступ ко всем услугам kor.tj',
  postJob: 'Разместите вакансию',
  postJobDescription: 'Получите отклики с контактами соискателей',
  selectBest: 'Выберите лучших',
  selectBestDescription: 'Из тех кто уже хочет с вами работать',
  startSelection: 'Начать подбор на kor.tj',
  whyPostVacancy: 'Почему стоит разместить вакансию на kor.tj',
  whyPostVacancyDescription:
    'Вакансия - это обьявление о поиске сотрудника. Опишите, кто нужен, и выбирайте лучших откликнувшихся',
  postVacancyFree: 'Разместить вакансию бесплатно',
  whoWillRespond: 'Кто будет откликаться?',
  candidatesTrustUs: 'Кандидаты нам доверяют',
  comesFirst: 'занимает первое место',
  searchSites: 'среди сайтов по поиску работы и персонала',
  candidateAlreadyHere: 'Ваш кандидат уже здесь',
  candidateDescription: '{count} резюме размещено на сервисе. Тысячи посетителей на kor.tj каждый день',
  vettedCandidates: 'Качественные и проверенные кандидаты',
  vettedCandidatesDescription: '100% пользователей на сервисе с подтвержденными телефонными номерами',
  interestedCandidates: 'Активные и заинтересованные кандидаты',
  interestedCandidatesDescription:
    'На kor.tj пользователи проводят больше времени, чем на других сайтах по поиску работы и персонала',
  buttonFindPlaceholder: 'Поиск по резюме',
};
