import type { IWorkSchedule } from '@/types/workSchedules';
import { apiClient } from './apiClient';
import { type TResponse } from '@/types/global';

type TGetWorkSchedulesResponse = {
  page: number;
  size: number;
  totalCount: number;
  totalPages: number;
  items: IWorkSchedule[];
};

export const getWorkSchedulesRequest = async () => {
  const { data } = await apiClient.get<TResponse<TGetWorkSchedulesResponse>>('work-schedules');
  return data;
};
