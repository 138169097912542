<template>
  <div class="bg-white">
    <div class="mx-auto max-w-7xl py-10 md:py-[120px] px-4 sm:px-6 lg:px-8">
      <div class="text-center">
        <h2 class="text-2xl mb-1">{{ t('homePage.howItsWork.title') }}</h2>
        <p class="text-grey-300 text-sm">{{ t('homePage.howItsWork.description') }}</p>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mt-6 md:mt-10">
        <div>
          <div class="flex justify-center">
            <span
              class="h-[120px] w-[120px] flex justify-center items-center rounded-full shadow-inner hover:bg-primary-500 cursor-pointer text-grey-600"
            >
              <draving />
            </span>
          </div>
          <p class="text-lg mt-5 text-center">{{ t('homePage.howItsWork.register') }}</p>
          <p class="text-sm text-grey-200 text-center mt-2">
            {{ t('homePage.howItsWork.description') }}
          </p>
        </div>
        <div>
          <div class="flex justify-center">
            <span
              class="h-[120px] w-[120px] flex justify-center items-center rounded-full shadow-inner hover:bg-primary-500 cursor-pointer"
            >
              <process />
            </span>
          </div>
          <p class="text-lg mt-5 text-center">{{ t('homePage.howItsWork.createProfile') }}</p>
          <p class="text-sm text-grey-200 text-center mt-2">
            {{ t('homePage.howItsWork.createProfileDescription') }}
          </p>
        </div>
        <div>
          <div class="flex justify-center">
            <span
              class="h-[120px] w-[120px] flex justify-center items-center rounded-full shadow-inner hover:bg-primary-500 cursor-pointer"
            >
              <SvgIcon name="computer" />
            </span>
          </div>
          <p class="text-lg mt-5 text-center">{{ t('homePage.howItsWork.resume') }}</p>
          <p class="text-sm text-grey-200 text-center mt-2">
            {{ t('homePage.howItsWork.resumeDescription') }}
          </p>
        </div>
        <div>
          <div class="flex justify-center">
            <span
              class="h-[120px] w-[120px] flex justify-center items-center rounded-full shadow-inner hover:bg-primary-500 cursor-pointer"
            >
              <SvgIcon name="one-finger-click" />
            </span>
          </div>
          <p class="text-lg mt-5 text-center">{{ t('homePage.howItsWork.applyToVacancy') }}</p>
          <p class="text-sm text-grey-200 text-center mt-2">
            {{ t('homePage.howItsWork.applyToVacancyDescription') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import SvgIcon from '@/components/ui/SvgIcon.vue';
  import { useI18n } from 'vue-i18n';
  import draving from '@/components/icons/draving.vue';
  import process from '@/components/icons/process.vue';
  const { t } = useI18n();
</script>
