<template>
  <div class="relative">
    <div class="flex justify-center">
      <img class="h-[77px] my-6 md:my-14" src="@/assets/images/logo.png" alt="logo" />
    </div>
    <div class="justify-center flex md:pb-[74px] mb-6 md:mb-[145px]">
      <div class="w-full lg:w-2/3">
        <h2 class="text-2xl md:text-3xl font-medium mb-1 md:mb-3">{{ t('homePage.header') }}</h2>
        <p v-if="statistics?.vacancyCount" class="text-grey-300">
          {{ t('homePage.headerSubtitle', { vacanciesCount: statistics?.vacancyCount }) }}
        </p>
        <div
          class="rounded-full bg-white h-[60px] md:h-[80px] mb-4 pl-4 md:pl-7 pr-3 py-3 flex items-center mt-4 md:mt-6"
        >
          <SvgIcon class="text-grey-200" name="search" />
          <input class="border-none focus:outline-none h-full w-full ml-2" v-model="searchData.searchText" />
          <router-link :to="{ name: 'search-vacancies', query: { searchText: searchData.searchText } }">
            <button class="h-10 md:h-[60px] rounded-full bg-primary-500 text-white w-[100px] md:w-[200px]">
              {{ t('homePage.find') }}
            </button>
          </router-link>
        </div>
        <RouterLink :to="{ name: 'home-company' }" class="text-primary-500 underline text-sm cursor-pointer">
          {{ t('homePage.linkText') }}
        </RouterLink>
      </div>
    </div>
    <div class="md:absolute hidden -bottom-[290px] z-10 md:grid grid-cols-1 md:grid-cols-4 w-full justify-center gap-6">
      <div
        @click="router.push({ name: 'registration' })"
        class="h-[290px] cursor-pointer rounded-md bg-white hover:bg-primary-500 hover:!text-white shadow-inner border border-grey-50 flex justify-center items-center"
      >
        <div>
          <div class="h-[133px] w-[133px] rounded-full p-4 bg-primary-200">
            <img class="w-[70px] m-auto" src="@/assets/images/home-block-images/resume.png" alt="" />
          </div>
          <p class="mt-4 w-1/2 text-center mx-auto">{{ t('homePage.createResume') }}</p>
        </div>
      </div>
      <div
        @click="resetTo"
        class="h-[290px] cursor-pointer rounded-md bg-white hover:bg-primary-500 hover:!text-white shadow-inner border border-grey-50 flex justify-center items-center"
      >
        <div>
          <div class="h-[133px] w-[133px] mx-auto rounded-full p-4 bg-primary-200">
            <img class="w-[94px] m-auto" src="@/assets/images/home-block-images/intern.png" alt="" />
          </div>
          <p class="mt-4 text-center mx-auto">{{ t('homePage.internship') }}</p>
        </div>
      </div>
      <div
        @click="resetTo"
        class="h-[290px] cursor-pointer rounded-md bg-white hover:bg-primary-500 hover:!text-white shadow-inner border border-grey-50 flex justify-center items-center"
      >
        <div>
          <div class="h-[133px] w-[133px] mx-auto rounded-full p-4 bg-primary-200">
            <img class="w-[110px] mx-auto my-auto" src="@/assets/images/home-block-images/student.png" alt="" />
          </div>
          <p class="mt-4 w-2/3 text-center mx-auto">{{ t('homePage.studentWork') }}</p>
        </div>
      </div>
      <div
        @click="router.push({ name: 'search-vacancies', query: { IsDisabledPerson: 'true' } })"
        class="h-[290px] cursor-pointer rounded-md bg-white hover:bg-primary-500 hover:!text-white shadow-inner border border-grey-50 flex justify-center items-center"
      >
        <div>
          <div class="h-[133px] w-[133px] rounded-full p-4 bg-primary-200 mx-auto">
            <img class="w-[108px] m-auto" src="@/assets/images/home-block-images/invalid.png" alt="" />
          </div>
          <p class="mt-4 w-1/2 text-center mx-auto">{{ t('homePage.invalidWork') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { onMounted, ref } from 'vue';
  import SvgIcon from '@/components/ui/SvgIcon.vue';
  import { useRouter } from 'vue-router';
  import { useI18n } from 'vue-i18n';
  import type { TStatistics } from '@/types/global';

  onMounted(() => {
    window.scrollTo(0, 0);
  });

  type TProps = {
    statistics: TStatistics;
  };

  const searchData = ref({
    searchText: '',
  });

  defineProps<TProps>();

  const { t } = useI18n();
  const router = useRouter();

  const resetTo = () => {
    router.push({ name: 'start-home' });
  };
</script>
