<template>
  <div :class="containerClass">
    <div :class="['flex items-center justify-between']">
      <label v-if="label" :for="id" class="block text-[15px] text-black font-normal mb-1.5">
        {{ label }}
      </label>
      <span v-if="hint" class="text-grey-300">{{ hint }}</span>
    </div>
    <div
      :class="[
        'relative flex rounded-md',

        {
          'border-red focus:!border-red': isError && !disabled,
          'bg-surface-400 border rounded-lg': disabled,
        },
        className,
      ]"
    >
      <input
        :id="id"
        v-model="displayValue"
        :type="type"
        :inputMode="inputMode"
        :disabled="disabled"
        class="focus:outline-none border-primary-500 bg-primary-50 hover:border focus:border focus:bg-white hover:border-primary-500 focus:border-primary-500 rounded-md placeholder-grey-200 focus:ring-0 focus:ring-offset-0 text-[15px] block w-full text-grey-900 py-2 h-[60px] px-4"
        :class="[
          {
            'focus:cursor-not-allowed border-surface-300 !text-grey-300 border-none': disabled,
            'bg-white': iswhite,
            'shadow-sm': withShadow,
            '!border-red': isError,
            'px-0': prefix,
          },
        ]"
        :placeholder="placeholder"
        v-bind="$attrs"
        @input="handleInput"
      />
      <slot />
    </div>
    <p v-if="isError && !disabled" class="text-sm text-red mt-1">
      {{ errorText }}
    </p>
  </div>
</template>

<script setup lang="ts">
  import { ref, watch } from 'vue';
  import { generatorId } from '@/utils/generators';

  type TInputMode = 'text' | 'search' | 'email' | 'tel' | 'url' | 'none' | 'numeric' | 'decimal';

  type TProps = {
    id?: string;
    label?: string;
    hint?: string;
    modelValue: string | number;
    type?: string;
    inputMode?: TInputMode;
    prefix?: boolean;
    errorText?: string;
    isError?: boolean;
    mask?: string;
    masked?: boolean;
    iswhite?: boolean;
    placeholder?: string;
    disabled?: boolean;
    className?: string;
    withShadow?: boolean;
    containerClass?: string;
  };

  const props = withDefaults(defineProps<TProps>(), {
    id: generatorId('input'),
    type: 'text',
    inputMode: 'text',
  });
  const emit = defineEmits<{
    (e: 'update:modelValue', value: string): void;
  }>();

  const displayValue = ref('');

  watch(
    () => props.modelValue,
    () => {
      displayValue.value = props.modelValue.toString();
    },
    {
      immediate: true,
      deep: true,
    }
  );

  const handleInput = (event: Event) => {
    if (!(event?.currentTarget instanceof HTMLInputElement)) {
      return;
    }
    displayValue.value = event.currentTarget.value;
    emit('update:modelValue', event.currentTarget.value);
  };
</script>
