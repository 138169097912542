<template>
  <section class="bg-white px-4 md:px-8 py-10 mt-8 rounded">
    <p>{{ t('employerPage.responsiblePerson') }}</p>
    <div class="flex flex-col md:flex-row justify-between gap-8 mt-8">
      <Input
        class="w-full"
        :error-text="t('requiredField')"
        :is-error="isSubmitted && v$.name.$invalid"
        v-model="user.name"
        :placeholder="t('name')"
        :label="t('name')"
      />
      <Input
        class="w-full"
        :error-text="`${t('requiredField')} (${t('format')} +992ххххххххх)`"
        :is-error="isSubmitted && v$.phone.$invalid"
        v-model="user.phone"
        :label="t('phone')"
        :placeholder="t('phone')"
      />
    </div>
    <div class="mt-8 flex justify-start">
      <Button :loading="loading" :disabled="loading" @click="saveData" variant="primary">{{ t('save') }}</Button>
    </div>
  </section>
</template>
<script setup lang="ts">
  import { onMounted, reactive, ref } from 'vue';
  import { computed } from 'vue';
  import { required } from '@vuelidate/validators';
  import Input from '@/components/ui/Input.vue';
  import { useVuelidate } from '@vuelidate/core';
  import Button from '@/components/ui/Button.vue';
  import { createCompanyPerson, updateCompanyPerson } from '@/services/company';
  import { toast } from 'vue3-toastify';
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n();

  const props = defineProps<{ companyId: string; data: object | null }>();

  const isSubmitted = ref(false);
  const loading = ref(false);

  const user = reactive({
    name: '',
    phone: '',
  });
  const mustBePhoneFormat = (value: string) => {
    return value.match('(\\+992)([0-9]{9}$)');
  };
  const rules = computed(() => ({
    name: { required },
    phone: { required, mustBePhoneFormat },
  }));

  onMounted(() => {
    const dataCopy = JSON.parse(JSON.stringify(props.data));
    user.name = dataCopy?.name || '';
    user.phone = dataCopy?.phone || '';
  });

  const saveData = () => {
    isSubmitted.value = true;
    if (v$.value.$invalid) {
      v$.value.$touch();
      return;
    }
    loading.value = true;
    //@ts-ignore
    if (props.data) {
      updateCompanyPerson(props.companyId, user)
        .then(() => {
          toast.success(t('dataSuccessfullyUpdated'));
        })
        .finally(() => {
          isSubmitted.value = false;
          loading.value = false;
        });
    } else {
      createCompanyPerson(props.companyId, user)
        .then(() => {
          toast.success(t('dataSuccessfullySaved'));
        })
        .finally(() => {
          isSubmitted.value = false;
          loading.value = false;
        });
    }
  };

  const v$ = useVuelidate(rules, user);
</script>

<style scoped></style>
